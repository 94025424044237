<template>
	<div class="container">
		<div class="title-container">

			<div class="sideLine" style="height: 1px"/>

			<app-text class="mx-4" size="medium-bold">{{ title }}</app-text>

			<div class="sideLine" style="height: 1px"/>

		</div>

		<app-text class="mt-4" color="grey9" size="small">{{ description }}</app-text>

	</div>
</template>

<script>
export default {

	name: "TitledPageBreak",

	props: ['description', 'title'],

}
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title-container {
	display: flex;
	align-items: center;
	height: 1px;
	width: 100%;
}
.sideLine {
	display: flex;
	flex-grow: 1;
	background: var(--v-greyD-base);
}

</style>
