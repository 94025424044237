<template>
	<div class="pa-4">

		<!--Carousel Images-->
		<div class="d-flex justify-center">
			<v-img :src="require('@/assets/images/logo-call-2.svg')"
				   width="100%"
				   max-width="512"/>
		</div>

		<!--Icon | Title-->
		<div class="d-flex align-center mt-8">

			<!--Icon-->
			<app-icon class="mr-4" color="orange" icon="mentalHealth" size="48"/>

			<!--Title-->
			<div>
				<app-text size="medium-bold">C.A.L.L</app-text>
				<app-text size="small">Community Advice & Listening Line</app-text>
			</div>

		</div>

		<v-divider class="mt-4"/>

		<div class="d-flex flex-column align-center">
			<app-text class="mt-4" size="normal-bold">Mental Health Helpline For Wales</app-text>
			<app-text size="small">Offering a confidential listening and support service</app-text>
		</div>

		<div class="d-flex justify-center mt-4">
			<div class="d-flex">

				<contact-icon-link @click.native="MIX_handleTelephoneLink('0800132737')"
								   class="mr-4"
								   icon="phone"/>
				<contact-icon-link @click.native="MIX_handleWebsiteLink('https://www.callhelpline.org.uk/')"
								   class="ml-4"
								   icon="website"/>
				<!--<contact-icon-link @click.native="MIX_handleEmailLink(organisation.organisationEmail)"-->
				<!--				   icon="email"/>-->

			</div>
		</div>

		<v-divider class="mt-4"/>

		<!--About Text-->
		<app-text class="mt-4">
			Offers emotional support and information/literature on Mental Health and related matters to the people of
			Wales.
			<br>
			<br>
			The Mental Health Helpline service is available 24 hours a day, 7 days a week, 365 days of the year.
			<br>
			<br>
			Anyone concerned about their own mental health or that of a relative or friend can access the service.
			C.A.L.L. Helpline offers a confidential listening and support service.
		</app-text>

		<div class="d-flex justify-center mt-4">
			<div class="d-flex justify-space-between" style="width: 128px">

				<v-btn depressed fab href="https://www.facebook.com/CALLHelplineWales/" target="_blank" width="48" height="48">
					<v-img :src="require('@/assets/images/logo-facebook.svg')"/>
				</v-btn>

				<v-btn depressed fab href="https://twitter.com/CALL_247" target="_blank" width="48" height="48">
					<v-img :src="require('@/assets/images/logo-twitter.svg')"/>
				</v-btn>

			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "MentalHealth"
}
</script>

<style scoped>

</style>
