<template>
	<v-dialog max-width="300"
			  :disabled="disabled"
			  v-model="dialog">

		<!--Activator-->
		<template v-slot:activator="{ on, attrs }">

			<div v-bind="attrs"
				 v-on="on">

				<!--Label-->
				<app-text class="mb-2" color="grey9" size="normal-bold">{{ label }}</app-text>

				<v-text-field class="rounded-lg"
							  color="pink"
							  :disabled="disabled"
							  :error="error"
							  :error-messages="errorMessage"
							  flat
							  hide-details="auto"
							  :label="fieldLabel"
							  outlined
							  :placeholder="placeholder"
							  prepend-inner-icon="icons8-time"
							  solo
							  :value="time"/>

			</div>
		</template>

		<!--Content-->
		<v-time-picker @change="handleSelection"
					   color="grey6"
					   :disabled="disabled"
					   format="24hr"
					   header-color="appGradient"
					   :max="max"
					   :min="min"
					   scrollable
					   v-model="pickerTime"/>

	</v-dialog>
</template>

<script>
export default {

	name: "TimePicker",

	props: [
		'disabled',
		'error',
		'errorMessage',
		'fieldLabel',
		'label',
		'placeholder',
		'prependInnerIcon',
		'max',
		'min',
		'time'
	],

	data: () => ({
		dialog: false,
		pickerTime: '',
	}),

	methods: {

		/**
		 * Handle Selection
		 *
		 * Take the time selected from the Time Picker and emit it back to the parent.
		 * Close the picker when done.
		 *
		 * @param time the selected time
		 */
		handleSelection(time) {
			const t = this

			t.$emit('emitTime', t.pickerTime)

			t.dialog = false
		},

	}

}
</script>

<style scoped>

</style>
