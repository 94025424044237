<template>
	<div>

		<v-skeleton-loader class="pa-4"
						   light
						   type="list-item-avatar"/>

		<v-skeleton-loader class="pa-4"
						   light
						   type="actions"/>

		<v-skeleton-loader class="pa-4"
						   light
						   type="card"/>

		<v-skeleton-loader class="pa-4"
						   light
						   type="card"/>

	</div>
</template>

<script>
export default {

	name: "LoadingAnimation",

}
</script>

<style scoped>

</style>
