<template>
	<v-expansion-panels class="py-4 pr-4" flat>
		<v-expansion-panel class="rounded-lg categoryPanel-container">

			<v-expansion-panel-header class="pa-2" style="height: 40px">
				<div class="d-flex align-center">
					<app-icon class="mr-4" color="pink" :icon="icon" size="32"/>
					<app-text color="white">{{ name }}</app-text>
				</div>
			</v-expansion-panel-header>

			<v-expansion-panel-content class="rounded-b-lg pt-4" color="white">
				<app-text>
					{{ description }}
				</app-text>
			</v-expansion-panel-content>

		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {

	name: "CategoryDivider",

	props: ['description', 'icon', 'name'],

}
</script>

<style scoped>
.categoryPanel-container {
	background: linear-gradient(90deg, var(--v-orange-base), var(--v-pink-base));
}
</style>
