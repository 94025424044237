<template>
	<div>

		<!--Form-->
		<div class="form-container shadow mt-16 pa-4">
			<v-form>

				<!--Name-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="profile" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userName"
								  :error-messages="errors.userNameErrorMessage"
								  flat
								  hide-details="auto"
								  label="Full Name..."
								  solo
								  v-model.trim="userName"/>
				</div>

				<v-divider class="white my-2"/>

				<!--Email-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="email" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userEmail"
								  :error-messages="errors.userEmailErrorMessage"
								  flat
								  hide-details="auto"
								  label="Email..."
								  type="email"
								  solo
								  v-model.trim="userEmail"/>
				</div>

				<v-divider class="white my-2"/>

				<!--Password-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="password" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userPassword"
								  :error-messages="errors.userPasswordErrorMessage"
								  flat
								  hide-details="auto"
								  label="Password..."
								  hint="Min 8 characters, with at least: 1 uppercase, 1 lowercase, 1 number, and 1 special character from ? # @ ! £ $ % &"
								  type="password"
								  solo
								  v-model.trim="userPassword"/>
				</div>

				<v-divider class="white my-2"/>

				<!--Confirm Password-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="confirmPassword" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userPasswordConfirmation"
								  :error-messages="errors.userPasswordConfirmationErrorMessage"
								  flat
								  hide-details="auto"
								  label="Confirm Password..."
								  type="password"
								  solo
								  v-model.trim="userPasswordConfirmation"/>
				</div>

				<v-divider class="white my-2"/>

				<!--Checkbox-->
				<div>

					<div class="d-flex align-center">

						<!--Checkbox-->
						<div @click="acceptedPrivacyAndTerms = !acceptedPrivacyAndTerms" class="registerCheckbox">
							<app-icon :color="acceptedPrivacyAndTerms ? 'green' : 'greyD'" icon="check" size="32"/>
						</div>

						<!--Text-->
						<app-text class="ml-2 pa-4">
							I accept the
							<span class="font-weight-bold">Privacy Policy</span>
							and
							<span class="font-weight-bold">Terms of Use</span>
						</app-text>

					</div>

					<!--Error Message-->
					<app-text v-if="errors.acceptedPrivacyAndTerms" color="red" size="small">
						You must accept the Privacy Policy and Terms of Use
					</app-text>

				</div>

			</v-form>
		</div>

		<!--Log In Button | Continue as Guest-->
		<div class="d-flex align-start justify-space-between mt-4 pa-4">

			<app-btn @click.native="register" class="shadow mr-4" color="green" label="Register"/>

			<app-text @click.native="openGuestDialog" color="white">...or, continue as a guest</app-text>

		</div>

		<v-dialog max-width="528"
				  v-model="isContinueAsGuestDialogVisible">

			<div class="white rounded-lg pa-4">

				<app-text size="medium-bold">Oh no, you're missing out!</app-text>

				<app-text class="mt-8">Creating an account has some great benefits...</app-text>

				<!--Profile-->
				<app-text class="mt-8">
					<app-text color="orange" size="medium-bold">Create a Profile</app-text>
					Create your Profile to get results better suited to your needs
				</app-text>

				<!--Preferences-->
				<app-text class="mt-8">
					<app-text color="pink" size="medium-bold">Search Preferences</app-text>
					Save your preferences for faster searches, getting what you need, when you need it
				</app-text>

				<!--Favorites-->
				<app-text class="mt-8">
					<app-text color="orange" size="medium-bold">Favourites</app-text>
					Keep what works for you, close at hand
				</app-text>

				<!--Action Buttons-->
				<div>

					<app-btn @click.native="isContinueAsGuestDialogVisible = false"
							 :block="true"
							 class="mt-8"
							 color="green"
							 label="Yes please, Sounds Good!"/>

					<app-text @click.native="handleContinueAsGuest" class="text-center mt-4" color="grey9">No thanks</app-text>

				</div>

			</div>

		</v-dialog>

	</div>
</template>

<script>

export default {

	name: "Register",

	data: () => ({
		acceptedPrivacyAndTerms: false,
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
			userName: false,
			userNameErrorMessage: '',
			userPassword: false,
			userPasswordErrorMessage: '',
			userPasswordConfirmation: false,
			userPasswordConfirmationErrorMessage: '',
		},
		isContinueAsGuestDialogVisible: false,
		userEmail: '',
		userName: '',
		userPassword: '',
		userPasswordConfirmation: '',
	}),

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async handleContinueAsGuest() {
			const t = this

			const RESPONSE = await t.MIX_auth_login_anonymously()

			if (RESPONSE?.errorCode) t.$emit('emitAuthError', {errorCode: RESPONSE.errorCode})
		},

		openGuestDialog() {
			const t = this

			t.isContinueAsGuestDialogVisible = true
		},

		/**
		 * Register
		 *
		 * If the form passes validation,
		 * call a Firebase mixin to register a new account with an email and password.
		 * If there are any Firebase errors, display them in a dialog.
		 *
		 * @returns {Promise<void>}
		 */
		async register() {
			const t = this

			if (!t.validateForm()) return

			const EMAIL = t.userEmail.toLowerCase()
			const PASSWORD = t.userPassword

			const RESPONSE = await t.MIX_auth_register_email(EMAIL, PASSWORD, t.userName)

			if (RESPONSE.hasErrors) {
				console.error('Error registering with email: ', RESPONSE.error)
				t.$emit('emitAuthError', {errorCode: RESPONSE.error.code})
				return
			}

			t.MIX_go('/')
		},

		/**
		 * Validate Form
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this
			/*
            * Password must:
            * - Be at least 8 characters
            * - Contain at least 1 upper case character
            * - Contain at least 1 lower case character
            * - Contain at least 1 number
            * - Contain at least 1 special character from ? # @ ! £ $ % &
            */
			const passwordRegex = /(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[?#@!£$%&]).{8,}/

			t.clearErrorsAndErrorMessages()

			// If there is no email
			if (!t.userEmail.trim()) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email required'
			}
			// If the email is not valid
			else if (!/.+@.+\..+/.test(t.userEmail)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Not a valid email format'
			}

			// If there is no userName
			if (!t.userName.trim()) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'User Name required'
			}

			// If there is no password
			if (!t.userPassword.trim()) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password required'
			}
			// If the password is less than 8 characters
			else if (t.userPassword.trim().length < 8) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password must be at least 8 characters'
			}
			// If the password is not in the expected format (regex)
			else if (!passwordRegex.test(t.userPassword.trim())) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password is not in the expected format'
			}

			// If there is no password confirmation
			if (!t.userPasswordConfirmation.trim()) {
				t.errors.userPasswordConfirmation = true
				t.errors.userPasswordConfirmationErrorMessage = 'Password Confirmation required'
			}

			// If the passwords don't match
			if (t.userPassword.trim() && t.userPasswordConfirmation.trim() && (t.userPassword.trim() !== t.userPasswordConfirmation.trim())) {
				t.errors.userPassword = true
				t.errors.userPasswordConfirmation = true
				t.errors.userPasswordErrorMessage = 'Passwords don\'t match'
				t.errors.userPasswordConfirmationErrorMessage = 'Password don\'t match'
			}

			// If the Privacy Policy and Terms of Use haven't been accepted'
			if (!t.acceptedPrivacyAndTerms) {
				t.errors.acceptedPrivacyAndTerms = true
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
@media screen and (max-width: 959px) {
	.form-container {
		background: #FFFFFF88;
		border-radius: 16px 0 0 16px;
		margin-left: 16px;
	}
}

.registerCheckbox {
	display: flex;
	align-items: center;
	justify-content: center;

	aspect-ratio: 1/1;
	background-color: white;
	border-radius: 8px;
	border: 1px solid grey;
	width: 40px;
}

@media screen and (min-width: 960px) {
	.form-container {
		background: #FFFFFF88;
		border-radius: 16px;
	}
}
</style>
