import organisation from "@/views/organisation/Organisation";

const mixin = {

	methods: {

		/**
		 * Create
		 *
		 * Create a new key using the entity name and the data.
		 * Add the 'Created' metadata before saving.
		 *
		 * @param entity name of entity
		 * @param formData data to save
		 * @returns {Promise<AxiosResponse<any> | void>} server response
		 */
		async MIX_redis_create(entity, formData) {
			const t = this

			formData.createdDateTime = Date.now()
			formData.createdUserId = formData.entityId
			formData.createdUserEmail = formData.userEmail || ''
			formData.createdUserName = formData.userName || ''

			return t.$axios.put(`${process.env.VUE_APP_SERVER}/${entity}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error creating: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Destroy
		 *
		 * Destroy (delete) a key using the entity ID.
		 *
		 * @param entity the name of the collection to delete from
		 * @param id
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_destroy(entity, id) {
			const t = this

			return t.$axios.delete(`${process.env.VUE_APP_SERVER}/${entity}/${id}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					console.error(`Error destroying: `, error)
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get All
		 *
		 * Get all the collection data for the given entity.
		 *
		 * @param entity name of entity
		 * @returns {Promise<AxiosResponse<any> | void>} array of entity object
		 */
		async MIX_redis_getAll(entity) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/${entity}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error fetching: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get By ID
		 *
		 * Get a single key by ID for the given entity.
		 *
		 * @param entity name of entity
		 * @param id ID of key
		 * @returns {Promise<AxiosResponse<any> | void>} array of Organisations
		 */
		async MIX_redis_getById(entity, id) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/${entity}/${id}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error fetching: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update
		 *
		 * Update a key by its entityId.
		 *
		 * @param id ID of key
		 * @param entity name of entity
		 * @param formData data to update
		 * @returns {Promise<AxiosResponse<any> | void>} server response
		 */
		async MIX_redis_update(entity, id, formData) {
			const t = this

			formData.modifiedDateTime = Date.now()
			formData.modifiedUserId = formData.entityId
			formData.modifiedUserEmail = formData.userEmail || ''
			formData.modifiedUserName = formData.userName || ''

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/${entity}/${id}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating: `, error)
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Delete
		 *
		 * Delete a key by its entityId.
		 * This does not delete the key, but marks it as deleted.
		 *
		 * @param entity name of entity
		 * @param id ID of key
		 * @param formData data to update
		 * @returns {Promise<AxiosResponse<any> | void>} server response
		 */
		async MIX_redis_delete(entity, id, formData) {
			const t = this

			formData.isDeleted = true
			formData.deletedDateTime = Date.now()
			formData.deletedUserId = formData.entityId
			formData.deletedUserEmail = formData.userEmail
			formData.deletedUserName = formData.userName || ''

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/${entity}/delete/${id}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error deleting: `, error)
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_getOrganisationByDistance(lng, lat, radius, formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/organisation/byDistance/${lng},${lat}/radius/${radius}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error('Error getting by distance: ', error)
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_updateOrganisationCategories(organisationId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/organisation/updateOrganisationCategories/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error('Error updating Organisation Categories: ', error)
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		MIX_redis_getSearchActivities(lng, lat, radius, formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/activity/searchActivities/${lng},${lat}/radius/${radius}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					console.error('Error getting search activities: ', error)
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_getOrganisationsByDistance(lng, lat, radius) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/organisation/byDistance/${lng},${lat}/radius/${radius}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null,
					}
				})
				.catch(error => {
					console.error('Error getting by distance: ', error)
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_getActivitiesByOrganisationId(organisationId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/activity/byOrganisationId/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					console.error(`Error fetching organisation activities: `, error)
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_getCurrentUserData() {
			const t = this
			const USER_FB_ID = t.currentUser.uid

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/user/getUserByFbId/${USER_FB_ID}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})

		},

		async MIX_redis_getCurrentUserSearchPreference(userId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/searchPreference/getUserSearchPreference/${userId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})

		},

		async MIX_redis_getOrganisationMemberships(organisationId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/organisationMembership/getOrganisationMemberships/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_getMyFavoritesByUserId(userId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/myFavorite/getMyFavoritesByUserId/${userId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		}

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
