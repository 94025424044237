<template>
	<v-list class="d-flex flex-column justify-space-between pa-4"
			height="100vh"
			nav>

		<v-list-item-group>

			<!--Home-->
			<v-list-item @click="MIX_go('/')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="home" size="32"/>
				<app-text color="pink">Home</app-text>
			</v-list-item>

			<!--About Us-->
			<v-list-item @click="MIX_go('/aboutUs')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="info" size="32"/>
				<app-text color="pink">About Us</app-text>
			</v-list-item>

			<!--Challenges-->
			<v-list-item @click="MIX_go('/challenges')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="challenge" size="32"/>
				<app-text color="pink">Challenges</app-text>
			</v-list-item>

			<!--Exclusive Offers-->
			<v-list-item @click="MIX_go('/exclusiveOffers')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="offers" size="32"/>
				<app-text color="pink">Exclusive Offers</app-text>
			</v-list-item>

			<!--Mental Health-->
			<v-list-item @click="MIX_go('/mentalHealth')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="mentalHealth" size="32"/>
				<app-text color="pink">Mental Health</app-text>
			</v-list-item>

			<v-divider class="mb-2"/>

			<!--My Profile-->
			<v-list-item @click="MIX_go('/myProfile')" class="d-flex align-center" :disabled="computedIsAnonymous">
				<app-icon class="mr-4" :color="computedIsAnonymous ? 'grey' : 'pink'" icon="profile" size="32"/>
				<div>
					<app-text :color="computedIsAnonymous ? 'grey' : 'orange'">My Profile</app-text>
					<app-text v-if="computedIsAnonymous" size="small">Log in to create a Profile</app-text>
				</div>
			</v-list-item>

			<!--My Favorites-->
			<v-list-item @click="MIX_go('/myFavorites')" class="d-flex align-center" :disabled="computedIsAnonymous">
				<app-icon class="mr-4" :color="computedIsAnonymous ? 'grey' : 'pink'" icon="favoriteFilled" size="32"/>
				<div>
					<app-text :color="computedIsAnonymous ? 'grey' : 'orange'">My Favourites</app-text>
					<app-text v-if="computedIsAnonymous" size="small">Log in to keep Favourites</app-text>
				</div>
			</v-list-item>

			<v-divider class="mb-2"/>

			<!--Feedback-->
			<v-list-item @click="MIX_go('/feedback')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="feedback" size="32"/>
				<app-text color="pink">Feedback</app-text>
			</v-list-item>

			<!--Settings-->
			<v-list-item @click="MIX_go('/settings')" class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="settings" size="32"/>
				<app-text color="pink">Settings</app-text>
			</v-list-item>

			<v-divider class="mb-2"/>

			<!--Admin-->
			<v-list-item v-if="userLevel === 'Staff-Admin'"
						 @click="MIX_go('/adminConsole')"
						 class="d-flex align-center">
				<app-icon class="mr-4" color="orange" icon="admin" size="32"/>
				<app-text color="pink">Admin Console</app-text>
			</v-list-item>

		</v-list-item-group>

		<!--Social Links | App Version Number -->
		<div>

			<!--Social Links-->
			<div class="d-flex justify-center">

				<!--Facebook-->
				<v-btn color="transparent" depressed
					   href="https://www.facebook.com/FitapOfficial" target="_blank">
					<v-img :src="require('@/assets/images/logo-facebook.svg')" max-width="32"/>
				</v-btn>

				<!--Instagram-->
				<v-btn color="transparent" depressed
					   href="https://www.instagram.com/fitapofficial/?hl=en" target="_blank">
					<v-img :src="require('@/assets/images/logo-instagram.svg')" class="mx-8" max-width="32"/>
				</v-btn>

				<!--Twitter-->
				<v-btn color="transparent" depressed
					   href="https://twitter.com/fitapofficial" target="_blank">
					<v-img :src="require('@/assets/images/logo-twitter.svg')" max-width="32"/>
				</v-btn>

			</div>

			<!--Version Number-->
			<div class="d-flex justify-center mt-4">
				<app-text color="grey9" size="small">App Version: {{ appVersion }}</app-text>
			</div>

		</div>

	</v-list>
</template>

<script>
export default {

	name: "NavigationDrawerItems",

	data: () => ({
		userLevel: '',
	}),

	computed: {

		computedIsAnonymous() {
			const t = this

			return !!t.currentUser?.isAnonymous
		},

	},

	async created() {
		if (this.currentUser) this.userLevel = await this.MIX_getUserLevel()
	}

}
</script>

<style scoped>

</style>
