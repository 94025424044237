<template>
	<v-dialog max-width="300"
			  :disabled="disabled"
			  v-model="dialog">

		<!--Activator-->
		<template v-slot:activator="{ on, attrs }">

			<div v-bind="attrs"
				 v-on="on">

				<!--Label-->
				<app-text class="mb-2" color="grey9" size="normal-bold">{{ label || 'Date' }}</app-text>

				<v-text-field class="rounded-lg"
							  color="pink"
							  :disabled="disabled"
							  :error="error"
							  :error-messages="errorMessage"
							  flat
							  hide-details="auto"
							  outlined
							  :placeholder="placeholder"
							  prepend-inner-icon="icons8-calendar"
							  solo
							  :value="computedFormatDate"/>

			</div>
		</template>

		<!--Content-->
		<v-date-picker @change="handleDateSelection"
					   color="grey6"
					   :disabled="disabled"
					   first-day-of-week="1"
					   header-color="appGradient"
					   :max="computedMaxDate"
					   :min="computedMinDate"
					   show-current
					   selected-items-text="fff"
					   v-model="pickerDate"/>

	</v-dialog>
</template>

<script>
export default {

	name: "DatePicker",

	props: [
		'date',
		'disabled',
		'error',
		'errorMessage',
		'futureOnly',
		'label',
		'pastOnly',
		'prependInnerIcon',
		'placeholder',
		'maxDate',
		'minDate'
	],

	data: () => ({
		dialog: false,
		pickerDate: '',
	}),

	computed: {

		/**
		 * Computed Format Date
		 *
		 * Return a new formatted date from the picker, if there is one.
		 * Otherwise, return a formatted date from the DB.
		 *
		 * @returns {string} formatted date
		 */
		computedFormatDate() {
			const t = this

			if (t.pickerDate) return t.MIX_formattedDate('long', new Date(t.pickerDate).getTime())

			if (t.$props.date) return t.MIX_formattedDate('long', t.$props.date)

		},

		/**
		 * Computed Min Date
		 *
		 * Calculate and return the min allowed date from the min-date array props [value, uom, tense].
		 *
		 * @returns {string} ISO formatted string
		 */
		computedMinDate() {
			const t = this
			let newDate

			let dateNow = new Date()
			let value = t.$props.minDate?.length ? t.$props.minDate[0] : 0
			let uom = t.$props.minDate?.length ? t.$props.minDate[1] : 'years'
			let tense = t.$props.minDate?.length ? t.$props.minDate[2] : 'past'

			if (tense === 'past') {
				if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() - value)
			}

			if (tense === 'future') {
				if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() + value)
			}

			return new Date(newDate).toISOString()
		},

		/**
		 * Computed Max Date
		 *
		 * Calculate and return the max allowed date from the max-date array props [value, uom, tense].
		 *
		 * @returns {string} ISO formatted string
		 */
		computedMaxDate() {
			const t = this
			let newDate

			let dateNow = new Date()
			let value = t.$props.maxDate?.length ? t.$props.maxDate[0] : 0
			let uom = t.$props.maxDate?.length ? t.$props.maxDate[1] : 'years'
			let tense = t.$props.maxDate?.length ? t.$props.maxDate[2] : 'past'

			if (tense === 'past') {
				if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() - value)
			}

			if (tense === 'future') {
				if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() + value)
			}

			return new Date(newDate).toISOString()
		},

	},

	methods: {

		/**
		 * Handle Date Selection
		 *
		 * Take the date selected from the Date Picker and emit it back to the parent (ChallengesForm.vue).
		 * Close the picker when done.
		 *
		 * @param date the ISO date from the picker
		 */
		handleDateSelection(date) {
			const t = this
			let newDate = new Date(date).getTime().toString()

			t.$emit('emitDate', newDate)

			t.dialog = false
		},

	}

}
</script>

<style scoped>

</style>
