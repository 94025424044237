<!--
Expansion Panel

These expansion panel are used for the form fields so they can be closed when required.
-->
<template>
	<v-expansion-panels flat v-model="isOpen">
		<v-expansion-panel class="transparent">

			<!--Header-->
			<v-expansion-panel-header color="transparent" >
				<app-text color="grey" size="normal-bold">
					<slot name="header"/>
				</app-text>
			</v-expansion-panel-header>

			<!--Content-->
			<v-expansion-panel-content>
				<slot name="content"></slot>
			</v-expansion-panel-content>

		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {

	name: "ExpansionPanel",

	data: () => ({
		isOpen: 0,
	})

}
</script>

<style scoped>
.v-expansion-panel-header {
	min-height: 32px;
	padding: 0;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>
