<template>
	<div>

		<!--Form-->
		<div class="form-container shadow mt-16 pa-4">
			<v-form>

				<!--Email-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="email" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userEmail"
								  :error-messages="errors.userEmailErrorMessage"
								  flat
								  hide-details="auto"
								  label="Email..."
								  type="email"
								  solo
								  v-model.trim="userEmail"/>
				</div>

			</v-form>
		</div>

		<!--Reset Button-->
		<div class="d-flex justify-end mt-4 pa-4">

			<app-btn @click.native="resetPassword" class="shadow" color="green" label="Reset"/>

		</div>

		<!--Confirmation Dialog-->
		<v-dialog max-width="512"
				  v-model="isConfirmationVisible">

			<v-card class="pa-4">

				<!--Instructional Text-->
				<app-text class="text-center">
					A link to reset your password has been emailed to you, so please create a new password and return
					here.
					<br>
					<br>
					We know how your inbox loves these types of messages, so please also check your spam folder, just in
					case.
					<br>
					<br>
					Thanks!
				</app-text>

				<app-btn @click.native="closeConfirmationDialog"
						 :block="true"
						 class="mt-4"
						 color="grey"
						 label="Close"/>

			</v-card>

		</v-dialog>

	</div>
</template>

<script>

export default {

	name: "PasswordReset",

	data: () => ({
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
		},
		isConfirmationVisible: false,
		userEmail: '',
	}),

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Confirmation Dialog
		 *
		 * Close the dialog and emit back to the parent (Auth.vue) with the name of the component to render.
		 */
		closeConfirmationDialog() {
			const t = this

			t.$emit('emitHandleSelectedForm', {name: 'Login'})
		},

		async resetPassword() {
			const t = this

			if (!t.validateForm()) return

			const EMAIL = t.userEmail.toLowerCase()

			const RESPONSE = await t.MIX_auth_resetPassword(EMAIL)

			if (RESPONSE?.errorCode) {
				t.$emit('emitAuthError', {errorCode: RESPONSE.errorCode})
				return
			}

			t.isConfirmationVisible = true
		},

		/**
		 * Validate Form
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// If there is no email
			if (!t.userEmail.trim()) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email required'
			}
			// If the email is not valid
			else if (!/.+@.+\..+/.test(t.userEmail)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Not a valid email format'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>
@media screen and (max-width: 959px) {
	.form-container {
		background: #FFFFFF88;
		border-radius: 0 16px 16px 0;
		margin-right: 16px;
	}
}

@media screen and (min-width: 960px) {
	.form-container {
		background: #FFFFFF88;
		border-radius: 16px;
	}
}

</style>
