<template>
	<div>

		<!--HIDDEN - File Upload button-->
		<input @change="handleUploadFile"
			   accept="image/*"
			   ref="fileInput"
			   style="display: none"
			   type="file">

		<!--VISIBLE - File Upload button-->
		<app-btn @click.native="fileClick"
				 :block="block"
				 :color="color"
				 :disabled="disabled"
				 :icon="icon"
				 :icon-color="iconColor"
				 :label="label"
				 :loading="isLoading"/>

	</div>
</template>

<script>
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'

export default {

	name: "ImageUpload",

	props: ['block', 'color', 'disabled', 'folder', 'icon', 'iconColor', 'imageFor', 'label'],

	data: () => ({
		isLoading: false,
	}),

	methods: {

		/**
		 * On Visible button click, auto-click the hidden button
		 */
		fileClick() {
			const t = this

			t.$refs.fileInput.click()
		},


		async handleUploadFile(event) {
			const t = this

			if (t.$props.imageFor === 'Activity') {
				await t.uploadFile(event, 1, 960, 'large')
				await t.uploadFile(event, 1, 600, 'medium')
				await t.uploadFile(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'Organisation') {
				await t.uploadFile(event, 1, 960, 'large')
				await t.uploadFile(event, 1, 600, 'medium')
				await t.uploadFile(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'ExclusiveOffer') {
				await t.uploadFile(event, 1, 960, 'large')
				await t.uploadFile(event, 1, 600, 'medium')
				await t.uploadFile(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'Challenge') {
				await t.uploadFile(event, 1, 960, 'large')
				await t.uploadFile(event, 1, 600, 'medium')
				await t.uploadFile(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'OrganisationIcon') {
				await t.uploadFile(event, 1, 96, 'logo')
			}

		},

		/**
		 * Upload File
		 *
		 * Takes the file from the event, start the loading spinner, and uploads the file.
		 * If the upload is successful, the URL will be downloaded and the loading spinner will stop.
		 *
		 * @param event
		 * @param maxSizeMB
		 * @param maxWidthOrHeight
		 * @param sizeLabel
		 */
		async uploadFile(event, maxSizeMB, maxWidthOrHeight, sizeLabel) {
			const t = this
			const folder = t.$props.folder
			const file = event.target.files[0]
			const storage = getStorage()

			// Rename the file to make it unique
			const fileName = t.renameFile(file, folder, maxWidthOrHeight)

			// Grab a reference to the image
			const imageRef = ref(storage, `${folder}/${fileName}`);

			t.isLoading = true

			// Compress the file
			const compressedFile = await t.MIX_compressImage(file, maxSizeMB, maxWidthOrHeight)

			// Upload image
			uploadBytes(imageRef, compressedFile)
				.then(snapshot => {

					getDownloadURL(imageRef)
						.then(url => {
							t.isLoading = false
							t.$emit('emitImageUrl', {url, sizeLabel})
						})
						.catch(error => {
							console.error('Error downloading image: ', error)
							t.isLoading = false
						})

				})
				.catch(error => {
					console.error('Error uploading image: ', error)
					t.isLoading = false
				})

		},


		/**
		 * Rename File
		 *
		 * Rename and return the file name using the folder and current datetime in millis:
		 *  - folderName1234567890
		 *
		 * @param file the file to rename
		 * @param folder the folder being uploaded to
		 * @param width
		 * @returns {string} new unique file name
		 */
		renameFile(file, folder, width) {
			let fileName

			// Grab the file extension
			const extension = file.name.split('.').pop()

			// Get the current datetime in millis
			const millisNow = new Date().getTime()

			fileName = `${folder}_${width}_${millisNow}.${extension}`

			return fileName
		},

	}

}
</script>

<style scoped>

</style>
