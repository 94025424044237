<template>
	<div>

		<!--Action Buttons | Searches-->
		<div class="d-flex align-center">

			<!--Searches-->
			<div>

				<!--By Name-->
				<app-input input-type="textInput"
						   append-icon="icons8-search"
						   placeholder="Search by name"
						   v-model.trim="searchByName"/>

			</div>

			<v-spacer/>

			<!--New-->
			<app-btn @click.native="openDialog"
					 color="pink"
					 label="New"/>

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="rounded-lg mt-4"
					  :headers="computedTableHeaders"
					  :items="computedTableData"
					  item-key="entityId"
					  no-data-text="Nothing to show, does it exist?"
					  style="border: 1px solid var(--v-greyD-base)">

			<!--Name-->
			<template v-slot:item.challengeName="{item}">
				<app-text size="small">{{ item.challengeName }}</app-text>
			</template>

			<!--Date & Time-->
			<template v-slot:item.challengeDate="{item}">
				<app-text size="small">{{ MIX_formattedDate('full', item.challengeDate) }}</app-text>
			</template>

			<!--Cost-->
			<template v-slot:item.challengeCost="{item}">
				<app-text size="small">{{ item.challengeCost == 0 ? 'Free' : `£${item.challengeCost}` }}</app-text>
			</template>

			<!--Sex-->
			<template v-slot:item.challengeSex="{item}">
				<app-text size="small">{{ item.challengeSex }}</app-text>
			</template>

			<!--Group / Individual-->
			<template v-slot:item.challengeGroupIndividual="{item}">
				<app-text size="small">{{ item.challengeGroupIndividual }}</app-text>
			</template>

			<!--Actions-->
			<template v-slot:item.actions="{item}">
				<app-icon @click.native="openDialog(item)"
						  color="pink" icon="forwardArrow" size="24"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<div v-if="!computedTableData.length"
				 class="d-flex align-center justify-center" style="height: 50vh">
				<app-text>Nothing to show, does it exist?</app-text>
			</div>

			<v-card v-for="item in computedTableData" :key="item.entityId"
					@click="openDialog(item)"
					class="rounded-lg mt-4 pa-4"
					flat
					style="border: 1px solid var(--v-greyD-base)">

				<!--Name-->
				<app-text class="mb-4" size="normal-bold">{{ item.challengeName }}</app-text>

				<!--Town-->
				<div class="d-flex align-center">
					<app-text class="mr-2" color="orange" size="small">Town</app-text>
					<app-text>{{ item.challengeName }}</app-text>
				</div>

			</v-card>

		</div>

		<!--Form Dialog-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isDialogVisible"
				 class="white shadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 75%'"
				 v-model="isDialogVisible">

				<v-toolbar class="appGradient">
					<app-text color="white" size="medium">Challenge</app-text>
					<v-spacer/>
					<edit-icon @click.native="editKey" class="pa-2" :isActive="!isReadOnly"/>
					<delete-icon @emitDeleteItem="deleteKey(selectedItem.entityId)"
								 class="pa-2"
								 itemName="challengeName"
								 :selectedItem="selectedItem"/>
					<close-icon @click.native="closeDialog" class="pa-2"/>
				</v-toolbar>

				<challenges-form class="appBackground pb-4 px-4"
								 :formData="selectedItem"
								 :isReadOnly="isReadOnly"
								 v-on:refreshPage="refreshPage"/>

			</div>
		</transition>

	</div>
</template>

<script>
import ChallengesForm from "@/views/adminConsole/challengesConsole/ChallengesForm";

export default {

	name: "ChallengesConsole",

	components: {ChallengesForm},

	data: () => ({
		entity: 'challenge',
		isDialogVisible: false,
		isReadOnly: false,
		searchByName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hiddenByDefault: true},
			{text: 'Name', value: 'challengeName', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Date', value: 'challengeDate', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Cost', value: 'challengeCost', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Sex', value: 'challengeSex', align: 'start', sortable: false, hiddenByDefault: false},
			{
				text: 'Group/Individual',
				value: 'challengeGroupIndividual',
				align: 'start',
				sortable: false,
				hiddenByDefault: false
			},
			{text: '', value: 'actions', align: 'center', sortable: false, hiddenByDefault: false, width: '24px'},
		],

		// Data
		challengesData: [],
	}),

	computed: {

		computedTableData() {
			const t = this
			let tableData = t.challengesData

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Search by Name
			if (t.searchByName) {
				tableData = tableData.filter(data => data.challengeName.toUpperCase().match(t.searchByName.toUpperCase()))
			}

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.challengeName > b.challengeName ? 1 : -1
			})

			return tableData
		},

		computedTableHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hiddenByDefault)

			return headers
		}

	},

	methods: {

		/**
		 * Get Entity Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getEntityData() {
			const t = this
			let response

			response = await t.MIX_redis_getAll(t.entity)

			if (!response.hasErrors) t.challengesData = response.data
		},

		/**
		 * Delete Key
		 *
		 * Delete the key by its ID, and refresh the page.
		 *
		 * @param id ID of key
		 * @returns {Promise<void>}
		 */
		async deleteKey(id) {
			const t = this
			let response

			response = await t.MIX_redis_delete(t.entity, id, t.selectedItem)

			if (!response.hasErrors) await t.refreshPage()
		},

		editKey() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Close Dialog
		 *
		 * Close the form dialog.
		 */
		closeDialog() {
			const t = this

			t.selectedItem = {}
			t.isDialogVisible = false
		},

		/**
		 * Open Dialog
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openDialog(item) {
			const t = this

			t.isReadOnly = !!item.entityId

			t.selectedItem = item

			t.isDialogVisible = true
		},

		/**
		 * Refresh Page
		 *
		 * Close the open form dialog, and call for the new entity data.
		 *
		 * @returns {Promise<void>}
		 */
		async refreshPage() {
			const t = this

			t.closeDialog()

			await t.getEntityData()
		},

	},

	async created() {
		const t = this

		await t.getEntityData()
	}

}
</script>

<style scoped>

</style>
