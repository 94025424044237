<template>
	<div>

		<!--Text Input-->
		<div v-if="inputType === 'textInput'">

			<!--Label-->
			<app-text class="mb-2" color="grey9" size="normal-bold">{{ label }}</app-text>

			<!--Field-->
			<v-text-field @input="updateValue($event)"
						  @click:append="handleAppendClick"
						  :append-icon="appendIcon"
						  class="rounded-lg"
						  :clearable="clearable"
						  color="pink"
						  :disabled="disabled"
						  :error="error"
						  :error-messages="errorMessage"
						  flat
						  hide-details="auto"
						  outlined
						  :placeholder="placeholder"
						  :prepend-inner-icon="prependInnerIcon"
						  solo
						  :type="type || 'text'"
						  :value="value"/>

		</div>

		<!--Text Area-->
		<div v-if="inputType === 'textArea'">

			<!--Label-->
			<app-text class="mb-2" color="grey9" size="normal-bold">{{ label }}</app-text>

			<!--Field-->
			<v-textarea @input="updateValue($event)"
						class="rounded-lg"
						:clearable="clearable"
						color="pink"
						:disabled="disabled"
						:error="error"
						:error-messages="errorMessage"
						flat
						hide-details="auto"
						outlined
						:placeholder="placeholder"
						:rows="rows || 4"
						solo

						:counter="counter"
						:max-length="counter"

						:type="'text' || type"
						:value="value"/>

		</div>

		<!--Select-->
		<div v-if="inputType === 'select'">

			<!--Label-->
			<app-text class="mb-2" color="grey9" size="normal-bold">{{ label }}</app-text>

			<!--Field-->
			<v-select @input="updateValue($event)"
					  class="rounded-lg"
					  :clearable="clearable"
					  color="pink"
					  :disabled="disabled"
					  :error="error"
					  :error-messages="errorMessage"
					  flat
					  hide-details="auto"
					  item-color="pink"
					  :items="items"
					  :item-text="itemText"
					  :item-value="itemValue"
					  :multiple="multiple"
					  outlined
					  :placeholder="placeholder"
					  :return-object="returnObject"
					  solo
					  :value="value"/>

		</div>

		<!--Autocomplete-->
		<div v-if="inputType === 'autoComplete'">

			<!--Label-->
			<app-text class="mb-2" color="grey9" size="normal-bold">{{ label }}</app-text>

			<!--Field-->
			<v-autocomplete @input="updateValue($event)"
							chips
							class="rounded-lg"
							:clearable="clearable"
							color="pink"
							deletable-chips
							:disable-lookup="disableLookup"
							:disabled="disabled"
							:error="error"
							:error-messages="errorMessage"
							flat
							hide-details="auto"
							item-color="pink"
							:items="items"
							:item-text="itemText"
							:item-value="itemValue"
							multiple
							outlined
							:placeholder="placeholder"
							small-chips
							solo
							:value="value"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "AppInput",

	props: [
		'appendIcon',
		'chips',
		'clearable',
		'counter',
		'deletableChips',
		'disabled',
		'disableLookup',
		'error',
		'errorMessage',
		'inputType',
		'items',
		'itemText',
		'itemValue',
		'label',
		'maxLength',
		'multiple',
		'placeholder',
		'prependInnerIcon',
		'returnObject',
		'rows',
		'type',
		'value'
	],

	methods: {

		/**
		 * Update Value
		 *
		 * Emit the value back to the parent's v-model.
		 *
		 * @param value the entered value to emit back
		 */
		updateValue(value) {
			this.$emit('input', value)
		},

		handleAppendClick() {
			this.$emit('appendClick')
		},

	}

}
</script>

<style scoped>

</style>
