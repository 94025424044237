<template>
	<div>

		<loading-animation v-if="isPageLoading"/>

		<div v-if="!isPageLoading">

			<!--Carousel Images-->
			<v-img
				:src="MIX_renderImageForScreenWidth(activityData.activityCoverImageSmall, activityData.activityCoverImageMedium, activityData.activityCoverImageLarge) || MIX_renderImageForScreenWidth(organisationData.organisationCoverImageSmall, organisationData.organisationCoverImageMedium, organisationData.organisationCoverImageLarge)"
				cover
				max-width="960"
				style="aspect-ratio: 2/1"/>

			<div class="pa-4">

				<!--Header-->
				<div class="d-flex align-center">

					<!--Logo-->
					<div class="mr-4">
						<v-img :src="organisationData.organisationLogo"
							   style="aspect-ratio: 1/1; width: 48px; border-radius: 8px 0 8px 0"/>
					</div>

					<!--Name-->
					<app-text size="medium-bold">{{ activityData.activityName }}</app-text>

					<v-spacer/>

					<!--Favorite Icon-->
					<app-icon v-if="!currentUser.isAnonymous"
							  @click.native="handleFavoriteSelection"
							  :color="isFavorited ? 'red' : 'grey9'"
							  :icon="isFavorited ? 'favoriteFilled' : 'favoriteEmpty'"
							  size="32"/>

				</div>

				<!--Contact Links-->
				<div class="d-flex align-center justify-center mt-8">

					<div class="d-flex flex-grow-1 greyD mr-2" style="height: 1px"/>

					<contact-icon-link @click.native="MIX_handleTelephoneLink(organisationData.organisationTelephone)"
									   class="mx-2"
									   :disabled="!organisationData.organisationTelephone"
									   icon="phone"/>
					<contact-icon-link @click.native="MIX_handleWebsiteLink(organisationData.organisationWebsite)"
									   class="mx-2"
									   :disabled="!organisationData.organisationWebsite"
									   icon="website"/>
					<contact-icon-link @click.native="MIX_handleEmailLink(organisationData.organisationEmail)"
									   class="mx-2"
									   :disabled="!organisationData.organisationEmail"
									   icon="email"/>
					<contact-icon-link @click.native="MIX_handleMapLink(organisationData.organisationAddressCoords)"
									   class="mx-2"
									   :disabled="!organisationData.organisationAddressCoords"
									   icon="map"/>

					<div class="d-flex flex-grow-1 greyD ml-2" style="height: 1px"/>

				</div>

				<!--Intensity Level-->
				<div v-if="activityData.activityIntensityLevel"
					 :class="$vuetify.breakpoint.width < 600 ? 'd-flex justify-center mt-8' : 'mt-8'">
					<v-img :src="computedIntensityLevel" max-width="200"/>
				</div>

				<!--Description-->
				<div class="d-flex justify-center py-8">
					<app-text class="text-center" style="line-height: 1.25em; max-width: 60ch"
							  v-html="activityData.activityDescription"/>
				</div>

				<v-divider/>

				<!--Info Points-->
				<app-text color="orange" size="normal-bold" class="mt-8">Activity Features...</app-text>

				<side-by-side-bullet-points class="mt-4"
											:points="['£' + activityData.activityCost + ' per ' + activityData.activityCostFrequency, activityData.activitySex, activityData.activityGroupIndividual, ...activityData.activityInfoPoints]"/>

				<!--Availability Times-->
				<div class="mt-8">

					<title-page-break title="Availability"
									  description="Swipe by day for activity times"/>

					<v-carousel height="auto"
								hide-delimiters
								show-arrows>

						<v-carousel-item v-for="(dayTimes, i) in computedDayTimes" :key="i">

							<div class="d-flex justify-center text-center">
								<div class="daysTimes-container appGradientOutline shadow">

									<!--Day-->
									<app-text class="mb-3" color="orange" size="normal-bold">
										{{ dayTimes.day }}
									</app-text>

									<!--No Times Message-->
									<app-text v-if="!dayTimes.times.length">
										Sorry, we don't have any times for this day
									</app-text>

									<!--Times-->
									<app-text v-for="(time, i) in dayTimes.times" :key="i" class="mt-1">
										{{ getTime(time) }}
									</app-text>

								</div>
							</div>

						</v-carousel-item>

					</v-carousel>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
export default {

	name: "Activity",

	data: () => ({
		activityData: {},
		isFavorited: false,
		isPageLoading: true,
		myFavoritesData: [],
		organisationData: {},
	}),

	computed: {

		/**
		 * Computed Day Times
		 *
		 * Return an array of sorted (by day) Activity dayTimes, starting on the current day.
		 *
		 * @returns {array} array of Activity dayTime objects, sorted by day (today first)
		 */
		computedDayTimes() {
			const t = this
			const DAY_TODAY = new Date().toLocaleDateString('en-GB', {weekday: 'long'})
			const ACTIVITY = t.activityData
			let dayTimes = []
			let monday = {
				day: 'Monday',
				times: ACTIVITY.activityMondayTimes && JSON.parse(ACTIVITY.activityMondayTimes)
			}
			let tuesday = {
				day: 'Tuesday',
				times: ACTIVITY.activityTuesdayTimes && JSON.parse(ACTIVITY.activityTuesdayTimes)
			}
			let wednesday = {
				day: 'Wednesday',
				times: ACTIVITY.activityWednesdayTimes && JSON.parse(ACTIVITY.activityWednesdayTimes)
			}
			let thursday = {
				day: 'Thursday',
				times: ACTIVITY.activityThursdayTimes && JSON.parse(ACTIVITY.activityThursdayTimes)
			}
			let friday = {
				day: 'Friday',
				times: ACTIVITY.activityFridayTimes && JSON.parse(ACTIVITY.activityFridayTimes)
			}
			let saturday = {
				day: 'Saturday',
				times: ACTIVITY.activitySaturdayTimes && JSON.parse(ACTIVITY.activitySaturdayTimes)
			}
			let sunday = {
				day: 'Sunday',
				times: ACTIVITY.activitySundayTimes && JSON.parse(ACTIVITY.activitySundayTimes)
			}

			if (DAY_TODAY === 'Monday') dayTimes = [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
			if (DAY_TODAY === 'Tuesday') dayTimes = [tuesday, wednesday, thursday, friday, saturday, sunday, monday]
			if (DAY_TODAY === 'Wednesday') dayTimes = [wednesday, thursday, friday, saturday, sunday, monday, tuesday]
			if (DAY_TODAY === 'Thursday') dayTimes = [thursday, friday, saturday, sunday, monday, tuesday, wednesday]
			if (DAY_TODAY === 'Friday') dayTimes = [friday, saturday, sunday, monday, tuesday, wednesday, thursday]
			if (DAY_TODAY === 'Saturday') dayTimes = [saturday, sunday, monday, tuesday, wednesday, thursday, friday]
			if (DAY_TODAY === 'Sunday') dayTimes = [sunday, monday, tuesday, wednesday, thursday, friday, saturday]

			return dayTimes
		},

		computedIntensityLevel() {
			const t = this
			const ACTIVITY = t.activityData
			let intensityLevel

			if (ACTIVITY.activityIntensityLevel === 'Beginner') intensityLevel = require('@/assets/images/intensity-indicator-beginner.svg')
			if (ACTIVITY.activityIntensityLevel === 'Intermediate') intensityLevel = require('@/assets/images/intensity-indicator-intermediate.svg')
			if (ACTIVITY.activityIntensityLevel === 'Advanced') intensityLevel = require('@/assets/images/intensity-indicator-advanced.svg')

			return intensityLevel
		},

	},

	methods: {

		/**
		 * Get My Favorites Data
		 *
		 * Get the data from the DB, and assign to state.
		 *
		 * @returns {Promise<*[]>}
		 */
		async getMyFavoritesData(userId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getMyFavoritesByUserId(userId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting My Favorites data: ', RESPONSE.error)
				return
			}

			return RESPONSE.data
		},

		/**
		 * Get Time
		 *
		 * Return either the formatted time, or a 24hour message.
		 *
		 * @param time the current day's time object
		 * @returns {{string}} the time or message
		 */
		getTime(time) {
			if (time.startTime === '00:00' && time.finishTime === '00:00') time = 'Open 24 hours'
			if (time.startTime && time.finishTime) time = `${time.startTime} - ${time.finishTime}`

			return time
		},

		/**
		 * Handle Favorites Selection
		 *
		 * Check to see if this Activity has been favorited.
		 * If it has, delete it.
		 * If it hasn't, add it.
		 *
		 * @returns {Promise<void>}
		 */
		async handleFavoriteSelection() {
			const t = this
			const MY_FAVORITES_DATA = t.myFavoritesData || []
			const ACTIVITY_DATA = t.activityData

			// Check if this entity has been favorited
			let favoritesData = MY_FAVORITES_DATA.filter(f => f.myFavoriteEntityId === ACTIVITY_DATA.entityId)

			if (favoritesData.length) {
				await t.deleteFromFavorites(favoritesData[0].entityId)
			} else {
				await t.addToFavorites()
			}
		},

		/**
		 * Add to Favorites
		 *
		 * Create a MyFavorite entry in the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async addToFavorites() {
			const t = this
			const ACTIVITY_DATA = t.activityData
			const USER_DATA = t.currentUserData

			const RESPONSE = await t.MIX_redis_create('myFavorite', {
				myFavoriteType: 'Activity',
				myFavoriteEntityId: ACTIVITY_DATA.entityId,
				userId: USER_DATA.entityId
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating My Favorite: ', RESPONSE.error)
				return
			}

			await t.loadData()
		},

		/**
		 * Delete From Favorites
		 *
		 * Remove the MyFavorite entry by its ID.
		 *
		 * @param favoriteId
		 * @returns {Promise<void>}
		 */
		async deleteFromFavorites(favoriteId) {
			const t = this

			t.isFavorited = !t.isFavorited

			const RESPONSE = await t.MIX_redis_destroy('myFavorite', favoriteId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error destroying My Favorite: ', RESPONSE.error)
				return
			}

			await t.loadData()
		},

		async loadData() {
			const t = this
			t.currentUserData = t.MIX_getFromLocalStorage('currentUserData')

			// Load data from local storage
			t.organisationData = t.MIX_getFromLocalStorage('selectedOrganisation')
			t.activityData = t.MIX_getFromLocalStorage('selectedActivity')

			// Load My Favorites data
			t.myFavoritesData = await t.getMyFavoritesData(t.currentUserData.entityId)

			// If this Activity is favorited, set it
			if (t.myFavoritesData.length) t.isFavorited = t.myFavoritesData.find(f => f.myFavoriteEntityId === t.activityData.entityId)

			t.isPageLoading = false
		},

	},

	created() {
		const t = this
		t.isPageLoading = true

		// Scroll to top on load
		window.scroll({top: 0})

		t.loadData()
	}

}
</script>

<style scoped>
.daysTimes-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 8px;
	margin-top: 16px;
	padding: 16px;
	width: 64%;


}
</style>
