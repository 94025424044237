import {getAuth} from "firebase/auth";
import {version} from '../package.json';
import imageCompression from 'browser-image-compression';

const mixin = {

	data: () => ({
		appVersion: version,
		currentUser: getAuth().currentUser,

		activitySexOptions: [
			'Female Only',
			'Male Only',
			'Mixed',
		],
		daysOptions: [
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
			'Sunday',
		],
		disabilityImpairmentOptions: [
			'No impairment or disability',
			'Deaf / hard of hearing',
			'Blind / partially sighted',
			'Intellectual impairment / Learning disability',
			'Physical impairment: Ambulant',
			'Physical impairment: Power Chair User',
			'Physical impairment: Permanent Manual Wheelchair User',
			'Physical impairment: Ambulant and use sticks/crutches/prosthetic',
			'Physical impairment: Use a wheelchair for sport',
			'Other (please identify)',
		],
		feedbackTypeOptions: ['Comment', 'Problem', 'Question', 'Suggestion'],
		infoPointsOptions: [
			'24/7',
			'Adults Only',
			'Buggies',
			'Cafe',
			'Cardio Machines',
			'Changing Rooms',
			'Disabled Access',
			'Disabled Parking',
			'Equipment provided',
			'Female Only',
			'Free Parking',
			'Free Wifi',
			'Kids Only',
			'Lockers',
			'Male Only',
			'No Contract',
			'Online',
			'Outdoors',
			'Showers',
			'Swimming Pool',
			'Taster Session',
			'Trolleys',
			'Weights Area',
		],
		isIncludedInMembershipOptions: [
			'Yes',
			'No'
		],
		groupIndividualOptions: [
			'Group',
			'Individual',
			'Either',
		],
		richTextEditorOptions: [
			['bold', 'italic', 'underline', 'strike'],
			[{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
			[{list: 'ordered'}, {list: 'bullet'}],
			[{indent: '-1'}, {indent: '+1'}],
			[{color: []}, {background: []}],
			['clean'],
		],
		userLevelOptions: [
			'Staff-Admin',
			'User-Standard'
		],
		userStatusOptions: [
			'Approved',
			'Suspended',
		],
		activityIntensityLevelOptions: [
			'Beginner',
			'Intermediate',
			'Advanced',
		]
	}),

	methods: {

		/**
		 * Compress Image
		 *
		 * Compress and return image ready for uploading.
		 *
		 * @param image the file to compress
		 * @param maxSizeMB maximum image size in MB (defaults to 1)
		 * @param maxWidthOrHeight maximum image size in px (defaults to 960)
		 * @returns {File} the compressed file if successful, or the original image if not
		 */
		async MIX_compressImage(image, maxSizeMB, maxWidthOrHeight) {

			// Set options for compression
			const options = {
				maxSizeMB: maxSizeMB || 1,
				maxWidthOrHeight: maxWidthOrHeight || 960,
				useWebWorker: true,
			}

			try {
				return await imageCompression(image, options)
			} catch (error) {
				console.error('compression error: ', error)
				return image
			}

		},

		/**
		 * Formatted Date
		 *
		 * Returns the requested date in the specified format.
		 *
		 * @param format the format of the date (short, long, full)
		 * @param date the date in millis
		 * @returns {string} a formatted date string
		 */
		MIX_formattedDate(format, date) {
			if (!date) return ''

			return new Intl.DateTimeFormat('en-GB', {dateStyle: format}).format(date)
		},

		/**
		 * Get User Level
		 *
		 * Use the current user's FB ID Token, and return the string-based userLevel from FB claims.
		 *
		 * @returns {Promise<string|*>} the current user's user level
		 */
		async MIX_getUserLevel() {
			const t = this
			let userLevel

			const idTokenResult = await t.currentUser.getIdTokenResult(true)
			userLevel = await idTokenResult.claims.userLevel

			return userLevel
		},

		/**
		 * MIX Go
		 *
		 * Navigate to given path
		 *
		 * @param path the path to navigate to
		 */
		MIX_go(path) {
			const t = this

			t.$router.push(path)
				.catch(error => console.error('Routing error: ', error))
		},

		MIX_handleTelephoneLink(telephone) {
			if (!telephone) return
			window.open('tel:' + telephone)
		},

		MIX_handleWebsiteLink(website) {
			if (!website) return
			window.location.href = website
		},

		MIX_handleEmailLink(email) {
			if (!email) return
			window.open('mailto:' + email)
		},

		MIX_handleMapLink(coords) {
			if (!coords.latitude || !coords.longitude) return
			window.open(`https://www.google.com/maps/search/?api=1&query=${coords.latitude},${coords.longitude}`)
		},

		/**
		 * Render Image For Screen Width
		 *
		 * Takes a small, medium, and large image, and returns the correct size image for the current viewport width.
		 *
		 * @param small path for small image
		 * @param medium path for medium image
		 * @param large path for large image
		 * @returns {string} the path to the correct image
		 */
		MIX_renderImageForScreenWidth(small, medium, large) {
			const t = this
			let selectedImage

			if (t.$vuetify.breakpoint.width < 400) selectedImage = small

			else if (t.$vuetify.breakpoint.width < 600) selectedImage = medium

			else selectedImage = large

			return selectedImage
		},

		// Local Storage -----------------------------------------------------------------------------------------------

		MIX_addToLocalStorage(key, value) {
			localStorage.setItem(key, JSON.stringify(value))
		},

		MIX_getFromLocalStorage(key) {
			return JSON.parse(localStorage.getItem(key))
		},

		MIX_deleteOneFromLocalStorage(key) {
			localStorage.removeItem(key)
		},

		MIX_deleteAllFromLocalStorage() {
			localStorage.clear()
		},

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
