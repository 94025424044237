<template>
	<div class="d-flex flex-column align-center">

		<app-icon :color="color" :icon="icon" size="48"/>

		<app-text class="text-center" size="small">{{ label }}</app-text>

	</div>
</template>

<script>
export default {

	name: "IconWithLabel",

	props: ['color', 'icon', 'label'],

}
</script>

<style scoped>

</style>
