<template>
	<div>

		<loading-animation v-if="isLoading"/>

		<div v-if="!isLoading">

			<!--Action Buttons | Searches-->
			<div class="d-flex align-center">

				<!--Searches-->
				<div>

					<!--By Name-->
					<app-input input-type="textInput"
							   append-icon="icons8-search"
							   placeholder="Search by name"
							   v-model.trim="searchByName"/>

				</div>

				<v-spacer/>

				<!--New-->
				<app-btn @click.native="openDialog"
						 color="pink"
						 label="New"/>

			</div>

			<!--Table-->
			<v-data-table v-if="$vuetify.breakpoint.width >= 600"
						  class="rounded-lg mt-4"
						  :headers="computedTableHeaders"
						  :items="computedTableData"
						  item-key="entityId"
						  no-data-text="Nothing to show, does it exist?"
						  style="border: 1px solid var(--v-greyD-base)">

				<!--Name-->
				<template v-slot:item.activityName="{item}">
					<app-text size="small">{{ item.activityName }}</app-text>
				</template>

				<!--Category-->
				<template v-slot:item.activityCategory="{item}">
					<div class="mb-2">
						<app-text v-for="category in item.activityCategory" :key="category" class="mt-2" size="small">
							{{ category }}
						</app-text>
					</div>
				</template>

				<!--Sex-->
				<template v-slot:item.activitySex="{item}">
					<app-text size="small">{{ item.activitySex }}</app-text>
				</template>

				<!--Group / Individual-->
				<template v-slot:item.activityGroupIndividual="{item}">
					<app-text size="small">{{ item.activityGroupIndividual }}</app-text>
				</template>

				<!--Organisation-->
				<template v-slot:item.organisation="{item}">
					<app-text size="small">{{ getOrganisationByID(item.organisation) }}</app-text>
				</template>

				<!--Actions-->
				<template v-slot:item.actions="{item}">
					<app-icon @click.native="openDialog(item)"
							  color="pink" icon="forwardArrow" size="24"/>
				</template>

			</v-data-table>

			<!--Mobile Cards-->
			<div v-if="$vuetify.breakpoint.width < 600">

				<!--No data message-->
				<div v-if="!computedTableData.length"
					 class="d-flex align-center justify-center" style="height: 50vh">
					<app-text>Nothing to show, does it exist?</app-text>
				</div>

				<v-card v-for="item in computedTableData" :key="item.entityId"
						@click="openDialog(item)"
						class="rounded-lg mt-4 pa-4"
						flat
						style="border: 1px solid var(--v-greyD-base)">

					<!--Name-->
					<app-text class="mb-2" size="normal-bold">{{ item.activityName }}</app-text>

					<!--Organisation-->
					<div class="d-flex align-center mt-2">
						<app-text class="mr-2" color="orange" size="small">Organisation</app-text>
						<app-text>{{ getOrganisationByID(item.organisation) }}</app-text>
					</div>

					<!--Category-->
					<div class="d-flex align-center mt-2">
						<app-text class="mr-2" color="orange" size="small">Category</app-text>
						<app-text v-for="category in item.activityCategory" :key="category">
							{{ category }}
						</app-text>
					</div>

					<!--Sex-->
					<div class="d-flex align-center mt-2">
						<app-text class="mr-2" color="orange" size="small">Sex</app-text>
						<app-text>{{ item.activitySex }}</app-text>
					</div>

					<!--Group or Individual-->
					<div class="d-flex align-center mt-2">
						<app-text class="mr-2" color="orange" size="small">Group/Individual</app-text>
						<app-text>{{ item.activityGroupIndividual }}</app-text>
					</div>

				</v-card>

			</div>

			<!--Form Dialog-->
			<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
						leave-active-class="animate__animated animate__fadeOutRight animate__faster"
						mode="out-in">
				<div v-if="isDialogVisible"
					 class="white shadow"
					 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
					 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 75%'"
					 v-model="isDialogVisible">

					<v-toolbar class="appGradient">
						<app-text color="white" size="medium">Activity</app-text>
						<v-spacer/>
						<new-copy-icon @emitCopyItem="duplicateItem"
									   itemName="activityName"
									   :selectedItem="selectedItem"/>
						<edit-icon @click.native="editKey" class="pa-2" :isActive="!isReadOnly"/>
						<delete-icon @emitDeleteItem="deleteKey(selectedItem.entityId)"
									 class="pa-2"
									 itemName="activityName"
									 :selectedItem="selectedItem"/>
						<close-icon @click.native="closeDialog" class="pa-2"/>
					</v-toolbar>

					<activities-form class="appBackground pb-4 px-4"
									 :formData="selectedItem"
									 :categoriesData="computedCategoriesData"
									 :infoPointsData="infoPointsData"
									 :isReadOnly="isReadOnly"
									 :organisationsData="computedOrganisationsData"
									 v-on:refreshPage="refreshPage"/>

				</div>
			</transition>

		</div>

	</div>
</template>

<script>
import ActivitiesForm from "@/views/adminConsole/activitiesConsole/ActivitiesForm";

export default {

	name: "ActivitiesConsole",

	components: {ActivitiesForm},

	data: () => ({
		entity: 'activity',
		isDialogVisible: false,
		isReadOnly: false,
		searchByName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hiddenByDefault: true},
			{text: 'Name', value: 'activityName', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Category', value: 'activityCategory', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Sex', value: 'activitySex', align: 'start', sortable: false, hiddenByDefault: false},
			{
				text: 'Group or Individual',
				value: 'activityGroupIndividual',
				align: 'start',
				sortable: false,
				hiddenByDefault: false
			},
			{text: 'Organisation', value: 'organisation', align: 'start', sortable: false, hiddenByDefault: false},
			{text: '', value: 'actions', align: 'center', sortable: false, hiddenByDefault: false, width: '24px'},
		],

		// Data
		activitiesData: [],
		categoriesData: [],
		infoPointsData: [],
		organisationsData: [],
		isLoading: true,
	}),

	computed: {

		computedTableData() {
			const t = this
			let tableData = t.activitiesData

			t.activitiesData.forEach(activity => {
				if (activity.organisation) {
					t.tempOrganisation = t.organisationsData.find(org => org.entityId === activity.organisation)?.organisationName
				}
			})

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Search by Name
			if (t.searchByName) {
				tableData = tableData.filter(data =>
					data.activityName.toUpperCase().match(t.searchByName.toUpperCase()))
			}

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.activityName > b.activityName ? 1 : -1
			})

			return tableData
		},

		computedCategoriesData() {
			const t = this
			let tableData = t.categoriesData

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.categoryName > b.categoryName ? 1 : -1
			})

			return tableData
		},

		computedOrganisationsData() {
			const t = this
			let tableData = t.organisationsData

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.organisationName > b.organisationName ? 1 : -1
			})

			return tableData
		},

		computedTableHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hiddenByDefault)

			return headers
		}

	},

	methods: {

		/**
		 * Get Entity Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getEntityData() {
			const t = this
			let activitiesResponse
			let categoriesResponse
			let organisationsResponse
			let infoPointsResponse

			activitiesResponse = await t.MIX_redis_getAll(t.entity)
			if (!activitiesResponse.hasErrors) t.activitiesData = activitiesResponse.data

			categoriesResponse = await t.MIX_redis_getAll('category')
			if (!categoriesResponse.hasErrors) t.categoriesData = categoriesResponse.data

			await t.getInfoPointsData()

			organisationsResponse = await t.MIX_redis_getAll('organisation')
			if (!organisationsResponse.hasErrors) t.organisationsData = organisationsResponse.data

			t.isLoading = false
		},

		/**
		 * Get Info Points Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getInfoPointsData() {
			const t = this

			const INFO_POINTS_RESPONSE = await t.MIX_redis_getAll('infoPoint')

			// Handle any errors
			if (INFO_POINTS_RESPONSE.hasErrors) {
				console.error('Error getting Info Points data: ', INFO_POINTS_RESPONSE.error)
				return
			}

			t.infoPointsData = INFO_POINTS_RESPONSE.data
		},

		/**
		 * Delete Key
		 *
		 * Delete the key by its ID, and refresh the page.
		 *
		 * @param id ID of key
		 * @returns {Promise<void>}
		 */
		async deleteKey(id) {
			const t = this
			let response

			// Convert the Yes/No text to a boolean
			t.selectedItem.activityIsIncludedInMembership = t.selectedItem.activityIsIncludedInMembership === 'Yes'

			// Delete the Activity
			response = await t.MIX_redis_delete(t.entity, id, t.selectedItem)
			if (response.hasErrors) {
				console.error('Error deleting Activity: ', response.error)
				return
			}

			// Update the parent Organisation's categories
			response = await t.MIX_redis_updateOrganisationCategories(t.selectedItem.organisation)
			if (response.hasErrors) {
				console.error('Error updating Organisation Categories: ', response.error)
				return
			}

			if (!response.hasErrors) await t.refreshPage()
		},

		editKey() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Close Dialog
		 *
		 * Close the form dialog.
		 */
		closeDialog() {
			const t = this

			t.selectedItem = {}
			t.isDialogVisible = false
		},

		/**
		 * Get Organisation by ID
		 *
		 * Search the OrganisationsData and return the Organisation Name for the current ID.
		 *
		 * @param ID organisation ID to search on
		 * @returns {string} organisation name
		 */
		getOrganisationByID(ID) {
			const t = this

			return t.organisationsData.find(org => org.entityId === ID)?.organisationName
		},

		async duplicateItem(activity) {
			const t = this

			// Modify the name to make the file obvious
			activity.activityName = `! COPY OF - ${activity.activityName}`

			// Convert Yes/No in a boolean for storage
			activity.activityIsIncludedInMembership = activity.activityIsIncludedInMembership === 'Yes'

			const response = await t.MIX_redis_create('activity', activity)
			if (!response.hasErrors) await t.refreshPage()
		},

		/**
		 * Open Dialog
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openDialog(item) {
			const t = this

			t.isReadOnly = !!item.entityId

			t.selectedItem = item

			t.isDialogVisible = true
		},

		/**
		 * Refresh Page
		 *
		 * Close the open form dialog, and call for the new entity data.
		 *
		 * @returns {Promise<void>}
		 */
		async refreshPage() {
			const t = this

			t.closeDialog()

			await t.getEntityData()
		},

	},

	async created() {
		const t = this
		t.isLoading = true

		await t.getEntityData()
	}

}
</script>

<style scoped>

</style>
