<template>
	<v-hover v-slot="{hover}">
		<div class="container shadow white">

			<app-icon color="orange"
					  :disabled="disabled"
					  :icon="icon"
					  size="32"
			/>

		</div>
	</v-hover>
</template>

<script>
export default {

	name: "ContactIconLink",

	props: ['disabled', 'icon']

}
</script>

<style scoped>
.container {
	display: flex;
	align-items: center;
	justify-content: center;

	/*outline: 1px solid var(--v-pink-base);*/
	border-radius: 50%;
	height: 48px;
	width: 48px;
}
</style>
