<template>
	<div>

		<loading-animation v-if="isPageLoading"/>

		<div v-if="!isPageLoading">

			<!--Icon | Title-->
			<div class="d-flex align-center pt-4 px-4">

				<!--Icon-->
				<app-icon color="pink" icon="favoriteFilled" size="48"/>

				<!--Title-->
				<app-text class="ml-4" color="orange" size="large-bold">My Favourites</app-text>

			</div>

			<!--About Text-->
			<app-text class="pa-4">
				Our ‘Favourites’ section allows you to bookmark what you like doing best, so that it’s stored for easy
				access.
			</app-text>

			<!--Tabs (Organisations | Activities)-->
			<v-tabs centered grow slider-color="pink" v-model="tabs">

				<v-tab href="#organisations">
					<app-text size="small">Organisations</app-text>
				</v-tab>

				<v-tab href="#activities">
					<app-text size="small">Activities</app-text>
				</v-tab>

			</v-tabs>

			<!--Tabs Content (Organisations | Activities)-->
			<v-tabs-items class="transparent" v-model="tabs">

				<!--Organisations-->
				<v-tab-item value="organisations">

					<!--Nothing to show message-->
					<app-text v-if="!favoriteOrganisations.length"
							  class="text-center pa-4" color="grey9">
						You haven't saved any favourite Organisations
					</app-text>

					<!--Cards-->
					<div class="myFav-grid pa-4">
						<my-favorites-card v-for="item in favoriteOrganisations"
										   @click.native="handleOrganisationSelection(item)"
										   :entity="item"/>
					</div>

				</v-tab-item>

				<!--Activities-->
				<v-tab-item value="activities">

					<!--Nothing to show message-->
					<app-text v-if="!favoriteActivities.length"
							  class="text-center pa-4" color="grey9">
						You haven't saved any favourite Activities
					</app-text>

					<!--Cards-->
					<div class="pa-4">
						<my-favorites-card v-for="item in favoriteActivities"
										   @click.native="handleActivitySelection(item)"
										   :entity="item"/>
					</div>

				</v-tab-item>

			</v-tabs-items>

		</div>

	</div>
</template>

<script>
import CenterCard from "@/views/home/centerCard/CenterCard";
import MyFavoritesCard from "@/views/myFavorites/myFavoritesCard/MyFavoritesCard";

export default {

	name: "MyFavorites",

	components: {MyFavoritesCard, CenterCard},

	data: () => ({
		favoriteActivities: [],
		favoriteOrganisations: [],
		isPageLoading: true,
		myFavoritesData: [],
		tabs: '',
	}),

	methods: {

		async getMyFavoritesData() {
			const t = this
			const USER = t.MIX_getFromLocalStorage('currentUserData')

			const RESPONSE = await t.MIX_redis_getMyFavoritesByUserId(USER.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting My Favorites data: ', RESPONSE.error)
				return
			}

			t.myFavoritesData = RESPONSE.data
		},

		async getMyFavoritesEntityData() {
			const t = this
			const MY_FAVORITES_DATA = t.myFavoritesData

			for (const f of MY_FAVORITES_DATA) {

				// Get and set any Organisations
				if (f.myFavoriteType === 'Organisation') {

					t.favoriteOrganisations.push(await t.getOrganisationData(f.myFavoriteEntityId))
				}

				// Get and set any Activities
				if (f.myFavoriteType === 'Activity') {

					const ACTIVITY = await t.getActivityData(f.myFavoriteEntityId)
					const ORGANISATION = await t.getOrganisationData(ACTIVITY.organisation)
					ACTIVITY.organisation = ORGANISATION

					t.favoriteActivities.push(ACTIVITY)
				}

			}

		},

		async getActivityData(activityId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getById('activity', activityId)

			if (RESPONSE.hasErrors) {
				console.error('Error getting Favorite Activity: ', RESPONSE.error)
				return
			}

			return RESPONSE.data
		},

		async getOrganisationData(organisationId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getById('organisation', organisationId)

			if (RESPONSE.hasErrors) {
				console.error('Error getting Favorite Organisation: ', RESPONSE.error)
				return
			}

			return RESPONSE.data
		},

		/**
		 * Handle Organisation Selection
		 *
		 * On Organisation card selection, assign the data so the correct Activities are shown for the Organisation,
		 * and in the correct sections.
		 *
		 * @param organisation
		 */
		async handleOrganisationSelection(organisation) {
			const t = this

			// Add selected Organisation data to local storage
			t.MIX_addToLocalStorage('selectedOrganisation', organisation)

			t.MIX_deleteOneFromLocalStorage('selectedCategory')

			t.MIX_go('organisation')
		},

		/**
		 * Handle Activity Selection
		 *
		 * On Activity card selection, assign the data so the correct Activities are shown.
		 *
		 * @param activity
		 */
		async handleActivitySelection(activity) {
			const t = this

			// Add selected Organisation data to local storage
			t.MIX_addToLocalStorage('selectedActivity', activity)
			t.MIX_addToLocalStorage('selectedOrganisation', activity.organisation)

			t.MIX_deleteOneFromLocalStorage('selectedCategory')

			t.MIX_go('activity')
		},

		async loadData() {
			const t = this

			await t.getMyFavoritesData()
			await t.getMyFavoritesEntityData()

			t.isPageLoading = false
		},

	},

	created() {
		const t = this

		t.isPageLoading = true

		t.loadData()
	}

}
</script>

<style scoped>
.myFav-grid {
	/*display: grid;*/
	/*grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));*/
	/*gap: 16px;*/
}
</style>
