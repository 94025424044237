<template>
	<v-form class="appBackground">

		{{ computedInit }}

		<!--Image-->
		<div class="d-flex justify-center">
			<app-icon :color="formData.categoryIcon ? 'green' : 'grey9'"
					  :icon="formData.categoryIcon ? formData.categoryIcon : 'image'"
					  size="256"/>
		</div>

		<!--Name-->
		<app-input input-type="textInput"
				   class="pt-8"
				   :disabled="isReadOnly"
				   :error="errors.categoryName"
				   :error-message="errors.categoryNameErrorMessage"
				   label="Name"
				   v-model.trim="form.categoryName"/>

		<!--Description-->
		<app-input input-type="textArea"
				   class="mt-8"
				   counter="360"
				   max-length="360"
				   :disabled="isReadOnly"
				   :error="errors.categoryDescription"
				   :error-message="errors.categoryDescriptionErrorMessage"
				   label="Description"
				   v-model.trim="form.categoryDescription"/>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block="true"
				 class="mt-8"
				 color="green"
				 :disabled="isReadOnly"
				 label="Save"/>

	</v-form>
</template>

<script>
export default {

	name: "CategoriesForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		entity: 'category',
		errors: {
			categoryName: false,
			categoryNameErrorMessage: '',
			categoryDescription: false,
			categoryDescriptionErrorMessage: '',
		},
		form: {
			entityId: '',
			categoryName: '',
			categoryDescription: '',
			categoryIcon: '',

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		}
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async save() {
			const t = this
			let response

			if (!t.validateForm()) return

			// Create
			if (!t.form?.entityId) {
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			if (!response.hasErrors) t.$emit('refreshPage')

		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Category Name
			if (!t.form.categoryName.trim()) {
				t.errors.categoryName = true
				t.errors.categoryNameErrorMessage = 'Name is required'
			}

			// Category Description
			if (!t.form.categoryDescription.trim()) {
				t.errors.categoryDescription = true
				t.errors.categoryDescriptionErrorMessage = 'Description is required'
			} else if (t.form.categoryDescription.trim().length > 360) {
				t.errors.categoryDescription = true
				t.errors.categoryDescriptionErrorMessage = 'Description cannot be more than 360 characters'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
