<template>
	<v-app-bar app class="appBar" height="64">

		<!--Back Icon-->
		<div style="width: 32px">
			<app-icon v-if="!['Home'].includes($route.name)"
					  @click.native="$router.go(-1)"
					  color="white" icon="backArrow" size="32"/>
		</div>

		<v-spacer/>

		<!--Company Logo-->
		<div @click="MIX_go('/')" style="aspect-ratio: 4/1; width: 50%; max-width: 136px">
			<v-img :src="require('@/assets/images/company-logo.svg')"/>
		</div>

		<v-spacer/>

		<!--Menu Icon-->
		<v-app-bar-nav-icon @click.stop="$emit('emitOpenNavigationDrawer')">
			<app-icon color="white" icon="menu" size="32"/>
		</v-app-bar-nav-icon>

	</v-app-bar>
</template>

<script>
export default {

	name: "AppBar",

	data: () => ({
		//
	})
}
</script>

<style scoped>
.appBar {
	background: linear-gradient(90deg, var(--v-orange-base), var(--v-pink-base));
}
</style>
