<template>
	<div class="settings-container">

		<div>

			<!--Icon | Title-->
			<div class="d-flex align-center">

				<!--Icon-->
				<app-icon color="pink" icon="settings" size="48"/>

				<!--Title-->
				<app-text class="ml-4" color="orange" size="large-bold">Settings</app-text>

			</div>

			<!--Install App ---------------------------------------------------------------------------------------- -->
			<app-text class="mt-4" size="normal-bold">Install Fitap</app-text>
			<v-divider/>
			<app-text size="small" class="mt-2">
				Fitap can be installed and used as a native app on most mobile devices and desktop computers.
				<span v-if="['android', 'ios'].includes(userDevicePlatform)">The steps below can help show you how.</span>
			</app-text>
			<app-text v-if="userDevicePlatform === 'desktop'" class="mt-2" size="small">
				Fitap can be installed as a desktop app, but as the support between browsers can differ, we recommend
				checking your browser's menu for an <strong>Install</strong> option.
			</app-text>
			<ios-install-banner v-if="userDevicePlatform === 'ios'"
								class="mt-2"
								:show-dismiss-banner="false"/>
			<android-install-instructions v-if="userDevicePlatform === 'android'"
										  class="mt-2"
										  :show-dismiss-banner="false"/>

			<!--Update Location Permissions ------------------------------------------------------------------------ -->
			<app-text class="mt-4" size="normal-bold">Location Access</app-text>
			<v-divider/>
			<app-text class="mt-2" size="small">
				Fitap requires location access to be able to serve you more refined results based on where you are.
				You can change your device's location access at any time.
				<span v-if="['android', 'ios'].includes(userDevicePlatform)">the steps below can help show you how.</span>
			</app-text>
			<app-text v-if="userDevicePlatform === 'desktop'" class="mt-2" size="small">
				The guide below is an overview for Chrome and Safari. For other browsers, please search the web for extra help.
			</app-text>
			<ios-location-access-instructions v-if="userDevicePlatform === 'ios'" class="mt-2"/>
			<android-location-access-instructions v-if="userDevicePlatform === 'android'" class="mt-2"/>
			<desktop-location-access-instructions v-if="userDevicePlatform === 'desktop'" class="mt-2"/>

		</div>

		<!--Log Out Button-->
		<div class="d-flex justify-center mt-4">
			<app-btn @click.native="MIX_auth_logOut"
					 :block="true"
					 color="pink"
					 label="Log Out"/>
		</div>

	</div>
</template>

<script>
import IosInstallBanner from "@/components/IosInstallInstructions";
import AndroidInstallInstructions from "@/components/AndroidInstallInstructions";
import IosLocationAccessInstructions from "@/components/IosLocationAccessInstructions";
import AndroidLocationAccessInstructions from "@/components/AndroidLocationAccessInstructions";
import DesktopLocationAccessInstructions from "@/components/DesktopLocationAccessInstructions";

export default {

	name: "Settings",

	components: {
		DesktopLocationAccessInstructions,
		AndroidLocationAccessInstructions,
		IosLocationAccessInstructions, AndroidInstallInstructions, IosInstallBanner},

	data: () => ({
		userDevicePlatform: '',
	}),

	mounted() {
		const t = this

		const iOSUserAgents = ['iPhone', 'iPad', 'iPod']

		// Android
		if (navigator.userAgent.includes("Android")) {
			console.log("This is an Android device")
			t.userDevicePlatform = 'android'
		}

		// iOS
		else if (iOSUserAgents.some(userAgent => navigator.userAgent.includes(userAgent))) {
			console.log("This is an iOS device")
			t.userDevicePlatform = 'ios'
		}

		// Web
		else if (navigator.userAgent.includes("Windows") || navigator.userAgent.includes("Macintosh")) {
			console.log("This is a desktop device")
			t.userDevicePlatform = 'desktop'
		} else {
			console.log("This is not an Android, iOS, or desktop device");
		}


	}

}
</script>

<style scoped>
.settings-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: calc(100vh - 64px);
	padding: 16px;
}

.iosInstallBanner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
