<template>
	<div class="pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon color="pink" icon="feedback" size="48"/>

			<!--Title-->
			<app-text class="ml-4" color="orange" size="large-bold">Feedback</app-text>

		</div>

		<!--About Text-->
		<app-text class="mt-4">
			Have any comments, problems, questions or suggestions you want to share with us?
			<br>
			<br>
			No problem!
			<br>
			<br>
			Just fill out the form below to let us know.
		</app-text>

		<v-divider class="mt-4"/>

		<!--Form-->
		<feedback-form @emitFeedbackComplete="emittedFeedbackComplete"
					   :userData="userData"/>

		<!--Thanks Dialog-->
		<v-dialog max-width="512"
				  v-model="feedbackCompleteDialog">

			<v-card class="d-flex flex-column align-center rounded-lg pa-4">

				<app-icon class="animate__animated animate__pulse animate__infinite" color="orange" icon="thumbsUp"
						  size="256"/>

				<app-text>Thanks for the feedback!</app-text>

				<app-btn @click.native="closeDialog"
						 :block="true"
						 class="mt-4"
						 color="green"
						 label="Close"/>

			</v-card>

		</v-dialog>

	</div>
</template>

<script>
import FeedbackForm from "@/views/adminConsole/feedbackConsole/FeedbackForm";

export default {

	name: "Feedback",

	components: {FeedbackForm},

	data: () => ({
		feedbackCompleteDialog: false,
		userData: {},
	}),

	methods: {

		/**
		 * Close Dialog
		 *
		 * Close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.feedbackCompleteDialog = false
		},

		emittedFeedbackComplete() {
			const t = this

			t.feedbackCompleteDialog = true
			t.MIX_go('/')
		},

	},

	async created() {
		const t = this

		const RESPONSE = await t.MIX_redis_getCurrentUserData()

		// Handle any errors
		if (RESPONSE.hasErrors) {
			console.error('Error getting User Data: ', RESPONSE.error)
			return
		}

		t.userData = RESPONSE.data
	},

}
</script>

<style scoped>

</style>
