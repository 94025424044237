<template>
	<div>

		<!--Action Buttons | Searches-->
		<div class="d-flex align-center">

			<!--Searches-->
			<div>

				<!--By Name-->
				<app-input input-type="textInput"
						   append-icon="icons8-search"
						   placeholder="Search by name"
						   v-model.trim="searchByName"/>

			</div>

			<v-spacer/>

			<!--New-->
			<app-btn @click.native="openForm"
					 color="pink"
					 label="New"/>

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="rounded-lg mt-4"
					  :headers="computedTableHeaders"
					  :items="computedTableData"
					  item-key="entityId"
					  no-data-text="Nothing to show, does it exist?"
					  style="border: 1px solid var(--v-greyD-base)">

			<!--Name-->
			<template v-slot:item.organisationName="{item}">
				<app-text size="small">{{ item.organisationName }}</app-text>
			</template>

			<!--Town-->
			<template v-slot:item.organisationAddressTown="{item}">
				<app-text size="small">{{ item.organisationAddressTown }}</app-text>
			</template>

			<!--Telephone-->
			<template v-slot:item.organisationTelephone="{item}">
				<app-text size="small">{{ item.organisationTelephone }}</app-text>
			</template>

			<!--Email-->
			<template v-slot:item.organisationEmail="{item}">
				<app-text size="small">{{ item.organisationEmail }}</app-text>
			</template>

			<!--Actions-->
			<template v-slot:item.actions="{item}">
				<app-icon @click.native="openForm(item)"
						  color="pink" icon="forwardArrow" size="24"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<div v-if="!computedTableData.length"
				 class="d-flex align-center justify-center" style="height: 50vh">
				<app-text>Nothing to show, does it exist?</app-text>
			</div>

			<v-card v-for="item in computedTableData" :key="item.entityId"
					@click="openForm(item)"
					class="rounded-lg mt-4 pa-4"
					flat
					style="border: 1px solid var(--v-greyD-base)">

				<!--Name-->
				<app-text class="mb-4" size="normal-bold">{{ item.organisationName }}</app-text>

				<!--Town-->
				<div class="d-flex align-center">
					<app-text class="mr-2" color="orange" size="small">Town</app-text>
					<app-text>{{ item.organisationAddressTown }}</app-text>
				</div>

				<!--Telephone-->
				<div class="d-flex align-center mt-2">
					<app-text class="mr-2" color="orange" size="small">Telephone</app-text>
					<app-text>{{ item.organisationTelephone }}</app-text>
				</div>

				<!--Email-->
				<div class="d-flex align-center mt-2">
					<app-text class="mr-2" color="orange" size="small">Email</app-text>
					<app-text>{{ item.organisationEmail }}</app-text>
				</div>

			</v-card>

		</div>

		<!--Form Dialog-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isDialogVisible"
				 class="white shadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 75%'"
				 v-model="isDialogVisible">

				<!--Toolbar-->
				<v-toolbar class="appGradient">
					<app-text color="white" size="medium">Organisation</app-text>
					<v-spacer/>
					<new-copy-icon @emitCopyItem="duplicateItem"
								   itemName="organisationName"
								   :selectedItem="selectedItem"/>
					<edit-icon @click.native="editKey" class="pa-2" :isActive="!isReadOnly"/>
					<delete-icon @emitDeleteItem="deleteItem(selectedItem.entityId)"
								 class="pa-2"
								 itemName="organisationName"
								 :selectedItem="selectedItem"/>
					<close-icon @click.native="closeDialog" class="pa-2"/>
				</v-toolbar>

				<organisations-form @emitRefreshMembershipData="emittedRefreshMembershipData"
					class="appBackground pb-4 px-4"
									:formData="selectedItem"
									:infoPointsData="infoPointsData"
									:isReadOnly="isReadOnly"
									:membershipData="organisationMembershipData"
									v-on:refreshPage="refreshPage"/>

			</div>
		</transition>

	</div>
</template>

<script>
import OrganisationsForm from "@/views/adminConsole/organisationsConsole/OrganisationsForm";

export default {

	name: "OrganisationsConsole",

	components: {OrganisationsForm},

	data: () => ({
		entity: 'organisation',
		isCopyDialogVisible: false,
		isDialogVisible: false,
		isReadOnly: false,
		searchByName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hiddenByDefault: true},
			{text: 'Name', value: 'organisationName', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Town', value: 'organisationAddressTown', align: 'start', sortable: false, hiddenByDefault: false},
			{
				text: 'Telephone',
				value: 'organisationTelephone',
				align: 'start',
				sortable: false,
				hiddenByDefault: false
			},
			{text: 'Email', value: 'organisationEmail', align: 'start', sortable: false, hiddenByDefault: false},
			{text: '', value: 'actions', align: 'center', sortable: false, hiddenByDefault: false, width: '24px'},
		],

		// Data
		infoPointsData: [],
		organisationsData: [],
		organisationMembershipData: [],
	}),

	computed: {

		computedTableData() {
			const t = this
			let tableData = t.organisationsData

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Search by Name
			if (t.searchByName) {
				tableData = tableData.filter(data => data.organisationName.toUpperCase().match(t.searchByName.toUpperCase()))
			}

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.organisationName > b.organisationName ? 1 : -1
			})

			return tableData
		},

		computedTableHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hiddenByDefault)

			return headers
		}

	},

	methods: {

		emittedRefreshMembershipData(id) {
			const t = this

			t.getOrganisationMembershipsData(id)
		},

		/**
		 * Get Entity Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getEntityData() {
			const t = this

			await t.getOrganisationsData()

			await t.getInfoPointsData()
		},

		/**
		 * Get Organisations Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getOrganisationsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('organisation')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisations data: ', RESPONSE.error)
				return
			}

			t.organisationsData = RESPONSE.data
		},

		/**
		 * Get Info Points Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getInfoPointsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('infoPoint')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Info Points data: ', RESPONSE.error)
				return
			}

			t.infoPointsData = RESPONSE.data
		},

		async getOrganisationMembershipsData(id) {
			const t = this
			let organisationMembershipData = []

			const RESPONSE = await t.MIX_redis_getOrganisationMemberships(id)

			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisation Membership data: ', RESPONSE.error)
				return
			}

			organisationMembershipData = RESPONSE.data

			// Remove deleted items
			if (organisationMembershipData.length) {
				organisationMembershipData = organisationMembershipData.filter(i => !i.isDeleted)
			}

			// Sort into alphabetical
			organisationMembershipData = organisationMembershipData.sort((a, b) => {
				return a.organisationMembershipName > b.organisationMembershipName ? 1 : -1
			})

			t.organisationMembershipData = organisationMembershipData
		},

		/**
		 * Close Dialog
		 *
		 * Close the form dialog.
		 */
		closeDialog() {
			const t = this

			t.selectedItem = {}
			t.organisationMembershipData = []
			t.isDialogVisible = false
		},

		/**
		 * Delete Item
		 *
		 * Delete the key by its ID, and refresh the page.
		 *
		 * @param id ID of key
		 * @returns {Promise<void>}
		 */
		async deleteItem(id) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete(t.entity, id, t.selectedItem)

			if (RESPONSE.hasErrors) {
				console.error('Error deleting Organisation: ', RESPONSE.error)
				return
			}

			await t.deleteOrganisationActivities(id)
			await t.deleteOrganisationMemberships(id)

			await t.refreshPage()
		},

		async duplicateItem(item) {
			const t = this

			// Modify the name to make the file obvious
			item.organisationName = `! COPY OF - ${item.organisationName}`

			const RESPONSE = await t.MIX_redis_create('organisation', item)

			if (RESPONSE.hasErrors) {
				console.error('Error deleting Organisation: ', RESPONSE.error)
				return
			}

			await t.refreshPage()
		},

		editKey() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Open Dialog
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openForm(item) {
			const t = this

			if (item.entityId) {
				t.isReadOnly = true
				t.selectedItem = item
				t.getOrganisationMembershipsData(item.entityId)
			} else {
				t.isReadOnly = false
			}

			t.isDialogVisible = true
		},

		/**
		 * Refresh Page
		 *
		 * Close the open form dialog, and call for the new entity data.
		 *
		 * @returns {Promise<void>}
		 */
		async refreshPage() {
			const t = this

			t.closeDialog()

			await t.getEntityData()
		},

		/**
		 * Delete Organisation Activities
		 *
		 * If the Organisation is deleted, also delete it's associated Activities, so they aren't orphaned.
		 *
		 * @param organisationId the ID of the deleted Organisation
		 * @returns {Promise<void>}
		 */
		async deleteOrganisationActivities(organisationId) {
			const t = this

			// Get all activities related to the organisation
			const RESPONSE = await t.MIX_redis_getActivitiesByOrganisationId(organisationId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisation Activities: ', RESPONSE.error)
				return
			}

			// Update the location information for each related activity
			const ACTIVITIES = RESPONSE.data
			for (const ACTIVITY of ACTIVITIES) {

				const RESPONSE = await t.MIX_redis_delete('activity', ACTIVITY.entityId, ACTIVITY)

				if (RESPONSE.hasErrors) {
					console.error('Error deleting Organisation Activity: ', RESPONSE.error)
					return
				}

			}
		},

		/**
		 * Delete Organisation Memberships
		 *
		 * If the Organisation is deleted, also delete it's associated Memberships, so they aren't orphaned.
		 *
		 * @param organisationId the ID of the deleted Organisation
		 * @returns {Promise<void>}
		 */
		async deleteOrganisationMemberships(organisationId) {
			const t = this

			// Get all Memberships related to the Organisation
			const MEMBERSHIPS = t.organisationMembershipData.filter(m => m.organisationId === organisationId)

			// Delete each Membership for this Organisation
			for (const MEMBERSHIP of MEMBERSHIPS) {

				const RESPONSE = await t.MIX_redis_delete('organisationMembership', MEMBERSHIP.entityId, MEMBERSHIP)

				// Handle any errors
				if (RESPONSE.hasErrors) {
					console.error('Error deleting Organisation Membership: ', RESPONSE.error)
					return
				}

			}
		}

	},

	async created() {
		const t = this

		await t.getEntityData()
	}

}
</script>

<style scoped>

</style>
