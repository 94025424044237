<template>
	<div v-if="!isPageLoading" class="mypro-container">

		<!--Title | Icon | Information Text-->
		<div class="pa-4">

			<!--Title | Icon-->
			<div class="d-flex align-center">

				<!--Icon-->
				<app-icon class="mr-4" color="pink" icon="profile" size="48"/>

				<!--Title-->
				<app-text color="orange" size="large-bold">My Profile</app-text>

			</div>

			<!--Information Text-->
			<app-text class="mt-4">
				Tell us a bit about yourself and what you're looking for, to allow us to refine your search results to show you the closest matches.
			</app-text>

		</div>

		<!--Tabs (Profile | Search)-->
		<v-tabs centered grow slider-color="pink" v-model="tabs">

			<v-tab href="#profile">
				<app-text size="small">Profile</app-text>
			</v-tab>

			<v-tab href="#search">
				<app-text size="small">Preferences</app-text>
			</v-tab>

		</v-tabs>

		<!--Tabs Content (Profile | Search)-->
		<v-tabs-items class="transparent" v-model="tabs">

			<!--Profile-->
			<v-tab-item value="profile">

				<users-form @refreshPage="emittedHandleFormSaved"
							class="mt-4 pa-4"
							:form-data="userData"
							:userLevel="userLevel"/>

			</v-tab-item>

			<!--Search-->
			<v-tab-item value="search">

				<search-preferences-form @refreshPage="emittedHandleFormSaved"
										 class="mt-4 pa-4"
										 :userSearchPreferenceData="userSearchPreferenceData"/>

			</v-tab-item>

		</v-tabs-items>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<v-dialog overlay-color="appGradient" overlay-opacity="0.8" max-width="512" v-model="isWelcomeDialogVisible">
			<div>

				<v-card class="rounded-lg pa-4" flat>

					<app-text class="text-center" color="pink" size="large-bold">
						Welcome
						<span class="grey--text">to </span>
						<span class="orange--text">Fitap!</span>
					</app-text>

					<app-text class="mt-4">
						We pull together all of the fitness and wellbeing venues in your area, so that you can quickly and easily
						search.
						<br>
						<br>
						To enable us to direct the right results to you, please complete your profile and search preferences.
						<br>
						<br>
						In addition, by allowing us to use your location, you will ensure the most relevant info appears in your
						search.
					</app-text>

					<!--My Profile-->
					<app-btn @click.native="isWelcomeDialogVisible = false"
							 :block="true"
							 class="mt-4"
							 color="appGradient"
							 label="Got it!"/>

				</v-card>


			</div>
		</v-dialog>

	</div>
</template>

<script>
import UsersForm from "@/views/adminConsole/usersConsole/UsersForm";
import SearchPreferencesForm from "@/views/myProfile/searchPreferencesForm/SearchPreferencesForm";

export default {

	name: "MyProfile",

	components: {SearchPreferencesForm, UsersForm},

	data: () => ({
		isPageLoading: true,
		isWelcomeDialogVisible: false,
		tabs: '',
		userData: {},
		userLevel: '',
		userSearchPreferenceData: {},
	}),

	methods: {

		async emittedHandleFormSaved() {
			const t = this

			await t.loadData()

			if (!t.userData.userIsProfileComplete) {
				window.scroll({top: 0})
				t.tabs = 'profile'
			}

			else if (!t.userData.userIsSearchPreferencesComplete) {
				window.scroll({top: 0})
				t.tabs = 'search'
			}

			else {
				t.MIX_go('/')
			}

		},

		async loadData() {
			const t = this

			t.isPageLoading = true

			await t.getCurrentUserData()
			await t.getCurrentUserPreferencesData(t.userData.entityId)

			t.isPageLoading = false
		},

		async getCurrentUserData() {
			const t = this
			let responseData = {}

			const RESPONSE = await t.MIX_redis_getCurrentUserData()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting user data: ', RESPONSE.error)
				return
			}

			responseData = RESPONSE.data

			t.userData = responseData
		},

		async getCurrentUserPreferencesData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getCurrentUserSearchPreference(t.userData.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Search Preferences: ', RESPONSE.error())
				return
			}

			t.userSearchPreferenceData = RESPONSE.data
		},

	},

	async mounted() {
		const t = this

		await t.loadData()

		// Show the Welcome dialog if either form is incomplete
		if (!t.userData.userIsProfileComplete || !t.userData.userIsSearchPreferencesComplete) t.isWelcomeDialogVisible = true

		t.userLevel = await t.MIX_getUserLevel()
	}

}
</script>

<style scoped>
.mypro-container {
	display: grid;
	grid-template-rows: auto 1fr;

	min-height: calc(100vh - 64px);
}
</style>
