<template>
	<v-expansion-panels flat>
		<v-expansion-panel>

			<!--Header-->
			<v-expansion-panel-header class="iosInstallBanner text-center rounded-t-lg" color="appGradient" hide-actions>
				<app-text color="white" size="normal-bold" style="letter-spacing: 2px">Desktop Location Access</app-text>
				<app-text class="mt-2" color="white" size="small">
					Allow Fitap to use your device's location
				</app-text>
			</v-expansion-panel-header>

			<!--Content-->
			<v-expansion-panel-content class="rounded-b-lg" color="appGradient">
				<div class="d-flex flex-column align-center text-center white rounded-lg mt-0 ma-4 pa-4">

					<app-text color="orange" size="normal-bold">Chrome</app-text>
					<app-text>- On your computer, open <strong>Chrome</strong> -</app-text>
					<app-text class="mt-2">- At the top right, click the Menu icon, and then Settings -</app-text>
					<app-text class="mt-2">- Click <strong>Privacy and Security</strong> and then <strong>Site settings</strong> -</app-text>
					<app-text class="mt-2">- Click <strong>Location</strong> -</app-text>
					<app-text class="mt-2">- Choose the option that you want as your default setting -</app-text>

					<v-divider class="my-4" style="width: 100%"/>

					<app-text color="pink" size="normal-bold">Safari</app-text>
					<app-text>- On your <strong>Mac</strong>, choose <strong>Apple menu > System Settings</strong> -</app-text>
					<app-text class="mt-2">- Click <strong>Privacy & Security</strong> -</app-text>
					<app-text class="mt-2">- Click <strong>Location Services</strong> -</app-text>
					<app-text class="mt-2">- Turn Location Services on or off for each app in the list -</app-text>

				</div>
			</v-expansion-panel-content>

		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {

	name: "DesktopLocationAccessInstructions",

	props: {showDismissBanner: {default: true}},

}
</script>

<style scoped>
.iosInstallBanner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>
