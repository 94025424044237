<template>
	<div :class="active ? 'fbtn-filled' : 'appGradientOutline fbtn-outlined'">

		<v-btn :block="block"
			   color="transparent"
			   :dark="!disabled"
			   depressed
			   :disabled="disabled"
			   height="32"
			   :value="value">

			<app-text :color="active ? 'white' : 'orange'" size="small">{{ label }}</app-text>

		</v-btn>

	</div>
</template>

<script>
export default {

	name: "FormBtn",

	props: ['active', 'block', 'disabled', 'label', 'value']

}
</script>

<style scoped>
.fbtn-filled {
	background: linear-gradient(90deg, var(--v-orange-base), var(--v-pink-base));
	border: 1px solid transparent;
	border-radius: 8px;
	width: fit-content;
}

.fbtn-outlined {
	border-radius: 8px;
	width: fit-content;
}
</style>
