<template>
	<v-card class="card-container" flat>

		<v-img v-if="label === 'C.A.L.L'" :src="require(`@/assets/images/logo-call-1.svg`)"/>
		<v-img v-else :src="require('@/assets/images/top-card-kids.png')"/>

		<app-text class="card-text" size="small" style="width: 100%">{{ label }}</app-text>

	</v-card>
</template>

<script>
export default {

	name: "TopCard",

	props: ['label'],

}
</script>

<style scoped>
.card-container {
	border-radius: 8px;
	border: 1px solid lightgrey;
	height: 80px;
	position: relative;
	width: 96px;
}

.card-text {
	position: absolute;
	bottom: 0;

	background: #FFFFFFEE;
	padding: 4px;
	text-align: center;
}
</style>
