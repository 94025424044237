<template>
	<v-card class="card-container" flat>

		<div>

			<!--Logo-->
			<v-img v-if="organisation.organisationLogo"
				   :src="organisation.organisationLogo"
				   center
				   height="48"
				   style="border-radius:8px 0 8px 0; position:absolute; z-index: 1; box-shadow: 2px 2px 4px grey"
				   width="48"/>

			<!--Image-->
			<v-img :src="organisation.organisationCoverImageSmall"
				   class="rounded-lg"
				   cover
				   width="100%"
				   max-width="200"
				   height="100%"
				   max-height="100"
				   style="aspect-ratio: 2/1"/>

		</div>

		<div class="cardText d-flex align-center justify-space-between">

			<!--Center Name-->
			<app-text class="centerCard-truncateText" size="small-bold"
					  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
					  :style="MIX_getFromLocalStorage('isSearchActive') ? 'width: 120px' : 'width: 184px'">
				{{ organisation.organisationName }}
			</app-text>

			<!--Center Distance-->
			<div v-if="organisation.distance" class="d-flex align-center">
				<app-icon color="orange" icon="locationPin" size="16"/>
				<app-text class="ml-1" size="small">{{ (organisation.distance).toFixed(1) }} mi</app-text>
			</div>

		</div>

	</v-card>
</template>

<script>
export default {

	name: "CenterCard",

	props: ['organisation']

}
</script>

<style scoped>
.card-container {
	border-radius: 8px;
	border: 1px solid lightgrey;
}

.cardText {
	position: absolute;
	bottom: 0;

	background: #FFFFFFEE;
	padding: 8px;
	width: 100%
}

.centerCard-logo {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	aspect-ratio: 1/1;
	border-radius: 8px 0 8px 0;
	width: 48px;
}

/*.centerCard-truncateText {*/
/*	width: 100px;*/
/*	white-space: nowrap;*/
/*	overflow: hidden;*/
/*	text-overflow: ellipsis;*/
/*}*/
</style>
