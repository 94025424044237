<template>
	<div class="bulletPoints">

			<div v-for="point in points" :key="point"
				 :class="$vuetify.breakpoint.width < 440 ? 'd-flex align-center justify-center' : 'd-flex align-center'">

				<!--Left Dash-->
				<div v-if="$vuetify.breakpoint.width < 440" class="orange mr-4" style="width: 8px; height: 1px"/>

				<!--Bullet Point-->
				<div v-if="$vuetify.breakpoint.width >= 440" class="pink mx-4" style="aspect-ratio: 1/1; border-radius: 50%; height: 8px; width: 8px"/>

				<!--Text-->
				<app-text class="text-center">{{ point }}</app-text>

				<!--Right Dash-->
				<div v-if="$vuetify.breakpoint.width < 440" class="pink ml-4" style="width: 8px; height: 1px"/>

			</div>

	</div>
</template>

<script>
export default {

	name: "SideBySideBulletPoints",

	props: ['points'],

}
</script>

<style scoped>
.bulletPoints {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(204px, 1fr));
}
</style>
