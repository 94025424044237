<template>
	<div class="feedback-container">

		{{ computedInit }}

		<!--Form-->
		<v-form class="mt-4">

			<!--Name-->
			<app-input input-type="textInput"
					   :disabled="$route.name === 'AdminConsole'"
					   :error="errors.feedbackTitle"
					   :error-message="errors.feedbackTitleErrorMessage"
					   label="Title"
					   v-model.trim="form.feedbackTitle"/>

			<!--Type-->
			<app-input input-type="select"
					   class="mt-4"
					   :disabled="$route.name === 'AdminConsole'"
					   :error="errors.feedbackType"
					   :error-message="errors.feedbackTypeErrorMessage"
					   :items="feedbackTypeOptions"
					   label="Feedback Type"
					   v-model="form.feedbackType"/>

			<!--Name-->
			<app-input input-type="textInput"
					   class="mt-4"
					   :disabled="$route.name === 'AdminConsole'"
					   :error="errors.feedbackUserName"
					   :error-message="errors.feedbackUserNameErrorMessage"
					   label="Name"
					   v-model.trim="form.feedbackUserName"/>

			<!--Contact Number-->
			<app-input input-type="textInput"
					   class="mt-4"
					   :disabled="$route.name === 'AdminConsole'"
					   label="Contact Number"
					   type="number"
					   v-model.trim="form.feedbackContactNumber"/>

			<!--User Email-->
			<app-input input-type="textInput"
					   class="mt-4"
					   :disabled="$route.name === 'AdminConsole'"
					   :error="errors.feedbackUserEmail"
					   :error-message="errors.feedbackUserEmailErrorMessage"
					   label="Email Address"
					   v-model.trim="form.feedbackUserEmail"/>

			<!--Feedback Text-->
			<app-input input-type="textArea"
					   class="mt-4"
					   :disabled="$route.name === 'AdminConsole'"
					   :error="errors.feedbackText"
					   :error-message="errors.feedbackTextErrorMessage"
					   label="Feedback"
					   v-model.trim="form.feedbackText"/>

		</v-form>

		<!--Submit Button-->
		<app-btn @click.native="submitForm"
				 :block="true"
				 class="mt-8"
				 color="green"
				 :disabled="isReadOnly"
				 label="Submit"/>

	</div>
</template>

<script>
export default {

	name: "FeedbackForm",

	props: ['formData', 'isReadOnly', 'userData'],

	data: () => ({
		dialog: false,
		errors: {
			feedbackText: false,
			feedbackTextErrorMessage: '',
			feedbackTitle: false,
			feedbackTitleErrorMessage: '',
			feedbackType: false,
			feedbackTypeErrorMessage: '',
			feedbackUserEmail: false,
			feedbackUserEmailErrorMessage: '',
			feedbackUserName: false,
			feedbackUserNameErrorMessage: '',
		},
		form: {
			feedbackTitle: '',
			feedbackUserId: '',
			feedbackContactNumber: '',
			feedbackText: '',
			feedbackType: '',
			feedbackUserEmail: '',
			feedbackUserName: '',
		},
	}),

	computed: {

		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData
			const USER_DATA = t.$props.userData

			if (FORM_DATA?.entityId) t.form = FORM_DATA

			if (USER_DATA) {

				t.form.feedbackUserName = USER_DATA.userName
				t.form.feedbackUserEmail = USER_DATA.userEmail
				t.form.feedbackUserId = USER_DATA.entityId

			}

		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		resetForm() {
			const t = this

			t.form.feedbackContactNumber = ''
			t.form.feedbackText = ''
			t.form.feedbackTitle = ''
			t.form.feedbackType = ''
			t.form.feedbackUserEmail = ''
			t.form.feedbackUserName = ''
		},

		/**
		 * Submit Form
		 *
		 * Submit and close the form.
		 */
		async submitForm() {
			const t = this

			// Only save if the form has passed validation
			if (!t.validateForm()) return

			const RESPONSE = await t.MIX_redis_create('feedback', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving feedback: ', RESPONSE.error)
				return
			}

			t.resetForm()
			t.$emit('emitFeedbackComplete')
		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// User Name
			if (!t.form.feedbackUserName.trim()) {
				t.errors.feedbackUserName = true
				t.errors.feedbackUserNameErrorMessage = 'Name is required'
			}

			// Type
			if (!t.form.feedbackType) {
				t.errors.feedbackType = true
				t.errors.feedbackTypeErrorMessage = 'Type is required'
			}

			// User Email
			if (!t.form.feedbackUserEmail.trim()) {
				t.errors.feedbackUserEmail = true
				t.errors.feedbackUserEmailErrorMessage = 'Email is required'
			}

			// Feedback Text
			if (!t.form.feedbackText.trim()) {
				t.errors.feedbackText = true
				t.errors.feedbackTextErrorMessage = 'Feedback is required'
			}

			// Feedback Title
			if (!t.form.feedbackTitle.trim()) {
				t.errors.feedbackTitle = true
				t.errors.feedbackTitleErrorMessage = 'Title is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
.feedback-container {
	display: grid;
	grid-template-rows: 1fr auto;

	height: calc(100% - 64px);
}
</style>
