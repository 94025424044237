<template>
	<div>

		<!--Form-->
		<div class="form-container shadow mt-16 pa-4">
			<v-form>

				<!--Email-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="email" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userEmail"
								  :error-messages="errors.userEmailErrorMessage"
								  flat
								  hide-details="auto"
								  label="Email..."
								  type="email"
								  solo
								  v-model.trim="userEmail"/>
				</div>

				<v-divider class="white my-2"/>

				<!--Password-->
				<div class="d-flex align-center">
					<app-icon color="orange" icon="password" size="32"/>
					<v-text-field background-color="transparent"
								  :error="errors.userPassword"
								  :error-messages="errors.userPasswordErrorMessage"
								  flat
								  hide-details="auto"
								  label="Password..."
								  type="password"
								  solo
								  v-model.trim="userPassword"/>
				</div>

			</v-form>
		</div>

		<!--Forgot Password | Log In Button-->
		<div class="d-flex align-start justify-space-between mt-4 pa-4">

			<app-text @click.native="$emit('emitHandleSelectedForm', {name: 'ResetPassword'})" color="white">
				Forgot your password?
			</app-text>

			<app-btn @click.native="logIn" class="shadow ml-4" color="green" label="Log In"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "Login",

	data: () => ({
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
			userPassword: false,
			userPasswordErrorMessage: '',
		},
		userEmail: '',
		userPassword: '',
	}),

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Log In
		 *
		 * @returns {Promise<void>}
		 */
		async logIn() {
			const t = this
			let result

			if (!t.validateForm()) return

			const EMAIL = t.userEmail.toLowerCase()
			const PASSWORD = t.userPassword

			result = await t.MIX_auth_logIn(EMAIL, PASSWORD)

			if (result?.errorCode) t.$emit('emitAuthError', {errorCode: result.errorCode})
		},

		/**
		 * Validate Form
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this
			/*
            * Password must:
            * - Be at least 8 characters
            * - Contain at least 1 upper case character
            * - Contain at least 1 lower case character
            * - Contain at least 1 number
            * - Contain at least 1 special character from ? # @ ! £ $ % &
            */
			const passwordRegex = /(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[?#@!£$%&]).{8,}/

			t.clearErrorsAndErrorMessages()

			// If there is no email
			if (!t.userEmail.trim()) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email required'
			}
			// If the email is not valid
			else if (!/.+@.+\..+/.test(t.userEmail)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Not a valid email format'
			}

			// If there is no password
			if (!t.userPassword.trim()) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password required'
			}
			// If the password is less than 8 characters
			else if (t.userPassword.trim().length < 8) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password must be at least 8 characters'
			}
			// If the password is not in the expected format (regex)
			else if (!passwordRegex.test(t.userPassword.trim())) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password is not in the expected format'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>
@media screen and (max-width: 959px) {
	.form-container {
		background: #FFFFFF88;
		border-radius: 0 16px 16px 0;
		margin-right: 16px;
	}
}

@media screen and (min-width: 960px) {
	.form-container {
		background: #FFFFFF88;
		border-radius: 16px;
	}
}
</style>
