<template>
	<div class="appBackground">

		{{ computedInit }}

		<!--Toolbar-->
		<v-toolbar class="appGradient">
			<app-text color="white" size="medium">Membership</app-text>
			<v-spacer/>
			<delete-icon @emitDeleteItem="deleteItem"
						 class="pa-2"
						 itemName="organisationName"
						 :selectedItem="form"/>
			<close-icon @click.native="closeForm" class="pa-2"/>
		</v-toolbar>

		<!--Content-->
		<div class="pa-4">

			<!--Name-->
			<app-input input-type="textInput"
					   :disabled="isReadOnly"
					   label="Name"
					   v-model="form.organisationMembershipName"/>

			<!--Cost | Duration-->
			<v-row no-gutters>

				<!--Cost-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
					<app-input input-type="textInput"
							   :disabled="isReadOnly"
							   label="Cost"
							   type="number"
							   v-model.number="form.organisationMembershipCost"/>
				</v-col>

				<!--Duration-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
					<app-input input-type="textInput"
							   :disabled="isReadOnly"
							   label="Duration"
							   v-model="form.organisationMembershipDuration"/>
				</v-col>

			</v-row>

			<!--Point 1-->
			<app-input input-type="textInput"
					   :disabled="isReadOnly"
					   class="mt-4"
					   label="Main Point 1"
					   v-model="form.organisationMembershipPoint1"/>

			<!--Point 2-->
			<app-input input-type="textInput"
					   :disabled="isReadOnly"
					   class="mt-4"
					   label="Main Point 2"
					   v-model="form.organisationMembershipPoint2"/>

			<!--Point 3-->
			<app-input input-type="textInput"
					   :disabled="isReadOnly"
					   class="mt-4"
					   label="Main Point 3"
					   v-model="form.organisationMembershipPoint3"/>

			<!--Save Button-->
			<app-btn @click.native="saveMembership"
					 :block="true"
					 class="mt-4"
					 color="green"
					 :disabled="isReadOnly"
					 label="Save"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "OrganisationMembershipForm",

	props: ['formData', 'isReadOnly', 'organisationId'],

	data: () => ({
		entity: 'organisationMembership',
		form: {
			entityId: '',
			organisationMembershipName: '',
			organisationMembershipCost: null,
			organisationMembershipDuration: '',
			organisationMembershipPoint1: '',
			organisationMembershipPoint2: '',
			organisationMembershipPoint3: '',
			organisationId: '',

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		},
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) {
				t.form = formData
			} else {
				t.form = {}
			}
		},

	},

	methods: {

		closeForm() {
			const t = this

			t.$emit('emitCloseMembershipForm')
		},

		/**
		 * Delete Item
		 *
		 * Delete the key by its ID, and refresh the page.
		 *
		 * @param item ID of key
		 * @returns {Promise<void>}
		 */
		async deleteItem(item) {
			const t = this
			let response

			response = await t.MIX_redis_delete(t.entity, item.entityId, t.form)

			if (response.hasErrors) {
				console.error('Error deleting membership: ', response.error)
				return
			}

			t.$emit('emitRefreshMembershipData', t.$props.organisationId)

			t.closeForm()
		},

		async saveMembership() {
			const t = this
			let response

			// Create
			if (!t.form?.entityId) {
				t.form.organisationId = t.$props.organisationId
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			// Handle any errors
			if (response.hasErrors) {
				console.error('Error saving membership: ', response.error)
			}

			t.$emit('emitRefreshMembershipData', t.$props.organisationId)

			t.closeForm()
		},

	}

}
</script>

<style scoped>

</style>
