import Vue from 'vue'
import VueRouter from 'vue-router'
import {getAuth, onAuthStateChanged} from "firebase/auth";

// Auth
import Auth from '@/views/auth/Auth'

// Views
import Home from '@/views/home/Home.vue'
import AboutUs from "@/views/aboutUs/AboutUs";
import Activity from '@/views/activity/Activity.vue'
import AdminConsole from "@/views/adminConsole/AdminConsole";
import Challenges from '@/views/challenges/Challenges.vue'
import ExclusiveOffers from '@/views/exclusiveOffers/ExclusiveOffers'
import Feedback from '@/views/feedback/Feedback'
import MentalHealth from '@/views/mentalHealth/MentalHealth'
import MyFavorites from '@/views/myFavorites/MyFavorites'
import MyProfile from '@/views/myProfile/MyProfile'
import Organisation from '@/views/organisation/Organisation.vue'
import Settings from '@/views/settings/Settings'

Vue.use(VueRouter)

const routes = [

	// Auth
	{
		path: '/auth',
		name: 'Auth',
		component: Auth,
	},

	// Views
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {requiresAuth: true}
	},
	{
		path: '/aboutUs',
		name: 'AboutUs',
		component: AboutUs,
		meta: {requiresAuth: true}
	},
	{
		path: '/activity',
		name: 'Activity',
		component: Activity,
		meta: {requiresAuth: true}
	},
	{
		path: '/adminConsole',
		name: 'AdminConsole',
		component: AdminConsole,
		meta: {requiresAuth: true}
	},
	{
		path: '/challenges',
		name: 'Challenges',
		component: Challenges,
		meta: {requiresAuth: true}
	},
	{
		path: '/feedback',
		name: 'Feedback',
		component: Feedback,
		meta: {requiresAuth: true}
	},
	{
		path: '/exclusiveOffers',
		name: 'ExclusiveOffers',
		component: ExclusiveOffers,
		meta: {requiresAuth: true}
	},
	{
		path: '/myFavorites',
		name: 'MyFavorites',
		component: MyFavorites,
		meta: {requiresAuth: true}
	},
	{
		path: '/mentalHealth',
		name: 'MentalHealth',
		component: MentalHealth,
		meta: {requiresAuth: true}
	},
	{
		path: '/myProfile',
		name: 'MyProfile',
		component: MyProfile,
		meta: {requiresAuth: true}
	},
	{
		path: '/organisation',
		name: 'Organisation',
		component: Organisation,
		meta: {requiresAuth: true}
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		meta: {requiresAuth: true}
	},

	// Fallback to Home page for unknown routes
	{
		path: '*',
		name: 'Home',
		component: Home,
		meta: {requiresAuth: true}
	}

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach(async (to, from, next) => {

	// If the routes does not require authenticating, carry on
	if (!to.meta.requiresAuth) {

		navigator.serviceWorker.register('/service-worker.js')
			.then(reg => {
				reg.update()
			})

		next()
		return
	}

	// Listen to changes in auth state (logged in and out)
	const AUTH = getAuth()
	onAuthStateChanged(AUTH, (user) => {

		if (user) next()
		else next('/auth')

	})

})

export default router
