<template>
	<v-hover v-slot="{hover}">

		<app-icon class="component-container"
				  :class="isActive && 'orange rounded-pill'"
				  :color="hover || isActive ? 'white' : ''"
				  icon="edit"
				  title="Edit"/>

	</v-hover>
</template>

<script>
export default {

	name: "EditIcon",

	props: ['isActive'],
}
</script>

<style scoped>
.component-container {
	transition: 0.3s;
}

.component-container:hover {
	background: var(--v-orange-base);
	border-radius: 50%;
	padding: 8px;
	transition: 0.3s;
}
</style>
