<template>
	<div class="favCard-container">

		<!--Logo-->
		<div class="d-flex justify-center align-center mr-4">
			<v-img v-if="computedEntityData.entityImage"
				   :src="computedEntityData.entityImage"
				   contain
				   min-height="48"
				   width="48"/>
			<v-img v-if="!computedEntityData.entityImage"
				   :src="require('@/assets/images/auth-bg-2.svg')"
				   contain
				   min-height="48"
				   width="48"/>
		</div>

		<!--Details-->
		<div class="d-flex flex-column justify-space-between">

			<app-text size="normal-bold">{{ computedEntityData.entityName }}</app-text>
			<app-text size="small">{{ computedEntityData.entityAddress }}</app-text>
			<div class="d-flex">
				<app-text v-for="item in computedEntityData.entityCategories" size="small">{{ item }} &nbsp;</app-text>
			</div>

		</div>

		<!--Heart-->
		<div class="d-flex justify-center align-center ml-4">
			<app-icon color="red" icon="favoriteFilled" size="32"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "MyFavoritesCard",

	props: ['entity'],

	computed: {

		computedEntityData() {
			const t = this
			const ENTITY = t.$props.entity

			return {
				entityName: ENTITY.activityName || ENTITY.organisationName,
				entityImage: ENTITY.organisationLogo || ENTITY.organisation.organisationLogo,
				entityAddress: t.getAddress(ENTITY),
				entityCategories: ENTITY.activityCategory
			}

		},

	},

	methods: {

		getAddress(entity) {
			const t = this
			let address = ''

			let addressLine1 = entity?.organisationAddressLine1
			let addressLine2 = entity?.organisationAddressLine2
			let addressTown = entity?.organisationAddressTown
			let addressPostcode = entity?.organisationAddressPostcode

			if (addressLine1) address += addressLine1 + ', '
			if (addressLine2) address += addressLine2 + ', '
			if (addressTown) address += addressTown + ', '
			if (addressPostcode) address += addressPostcode

			return address
		},

	}

}
</script>

<style scoped>
.favCard-container {
	display: grid;
	grid-template-columns: auto 1fr auto;

	background: white;
	border-radius: 16px;
	margin-bottom: 16px;
	padding: 16px;
}
</style>
