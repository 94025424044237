<template>
	<v-card class="appBackground pa-4">

		{{ computedInit }}

		<!--Image | Search | Upload Buttons-->
		<div :class="$vuetify.breakpoint.width < 800 ? 'orgForm-header-mobile' : 'orgForm-header-desktop'">

			<!--Logo | Image-->
			<div :class="$vuetify.breakpoint.width < 800 && 'd-flex justify-center'"
				 style="position:relative;">

				<!--Logo-->
				<v-img v-if="form.organisationLogo"
					   class="shadow"
					   contain
					   height="100%"
					   max-height="96"
					   max-width="96"
					   :src="form.organisationLogo"
					   style="
					   		border-radius: 8px 0 8px 0;
					   		position:absolute; left: 0;
					   		z-index: 999;"
					   width="100%"/>
				<app-icon v-if="!form.organisationLogo"
						  icon="image"
						  size="96"
						  style="
						  		background-color: white;
						  		border-radius: 8px 0 8px 0;
						  		padding: 0 8px 8px 4px;
						  		position:absolute; left: 0;
						  		z-index: 999;"/>

				<!--Image-->
				<v-img v-if="form.organisationCoverImageSmall"
					   class="rounded-lg"
					   contain
					   :src="form.organisationCoverImageSmall"
					   height="100%"
					   max-height="464"
					   width="100%"
					   max-width="464"/>
				<app-icon v-if="!form.organisationCoverImageSmall" icon="image" size="256"/>

			</div>

			<!--Search | Upload Buttons-->
			<div :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'ml-4'">

				<google-autocomplete @placechanged="getAddressData"
									 :disabled="isReadOnly"
									 placeholder="Business search..."
									 types="establishment"/>

				<!--Upload LOGO Button-->
				<image-upload @emitImageUrl="handleLogoUpload"
							  :block="true"
							  class="mt-4"
							  color="appGradient"
							  :disabled="isReadOnly"
							  folder="organisations"
							  icon="upload"
							  imageFor="OrganisationIcon"
							  label="Upload Logo"/>

				<!--Logo Errors-->
				<app-text v-if="errors.organisationLogo"
						  color="red" size="small">
					Logo Required
				</app-text>

				<!--Upload IMAGE Button-->
				<div class="mt-4">

					<image-upload @emitImageUrl="handleImageUpload"
								  :block="true"
								  color="appGradient"
								  :disabled="isReadOnly"
								  folder="organisations"
								  icon="upload"
								  imageFor="Organisation"
								  label="Upload Image"/>

					<!--Image Errors-->
					<app-text v-if="errors.organisationImage" color="red" size="small">Image Required</app-text>

					<app-text class="d-flex justify-center text-center mt-2" size="small">
						For best results:
						<br>
						Logos should be 1:1 (e.g. (w)500px (h)500px
						<br>
						Images should be 2:1 (e.g. (w)1000px (h)500px
					</app-text>

				</div>

			</div>

		</div>

		<!--Name-->
		<app-input input-type="textInput"
				   class="pt-4"
				   :disabled="isReadOnly"
				   :error="errors.organisationName"
				   :error-message="errors.organisationNameErrorMessage"
				   label="Name"
				   v-model.trim="form.organisationName"/>

		<!--Telephone | Email | Website-->
		<v-row no-gutters>

			<!--Telephone-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.organisationTelephone"
						   :error-message="errors.organisationTelephoneErrorMessage"
						   label="Telephone"
						   v-model.trim="form.organisationTelephone"/>
			</v-col>

			<!--Email-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 px-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.organisationEmail"
						   :error-message="errors.organisationEmailErrorMessage"
						   label="Email"
						   v-model.trim="form.organisationEmail"/>
			</v-col>

			<!--Website-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.organisationWebsite"
						   :error-message="errors.organisationWebsiteErrorMessage"
						   label="Website"
						   v-model.trim="form.organisationWebsite"/>
			</v-col>

		</v-row>

		<!--Address-->
		<v-row>

			<!--Address Line 1-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Line 1"
						   v-model.trim="form.organisationAddressLine1"/>
			</v-col>

			<!--Address Line 2-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Line 2"
						   v-model.trim="form.organisationAddressLine2"/>
			</v-col>

			<!--Address Town-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Town"
						   v-model.trim="form.organisationAddressTown"/>
			</v-col>

			<!--Address City-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address City"
						   v-model.trim="form.organisationAddressCity"/>
			</v-col>

			<!--Address County-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address County"
						   v-model.trim="form.organisationAddressCounty"/>
			</v-col>

			<!--Address Postcode-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Postcode"
						   v-model.trim="form.organisationAddressPostcode"/>
			</v-col>

			<!--Latitude-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Coords - Latitude"
						   v-model.trim="form.organisationAddressCoords.latitude"/>
			</v-col>

			<!--Longitude-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Coords - Longitude"
						   v-model.trim="form.organisationAddressCoords.longitude"/>
			</v-col>

		</v-row>

		<!--Info Points-->
		<app-text class="mt-4" color="grey9" size="normal-bold">Info Points</app-text>
		<v-autocomplete background-color="white"
						chips
						class="rounded-lg mt-2"
						color="pink"
						deletable-chips
						:disabled="isReadOnly"
						:error="errors.organisationInfoPoints"
						:error-message="errors.organisationInfoPointsErrorMessage"
						filled
						hide-details="auto"
						item-color="pink"
						item-text="infoPointName"
						:items="computedInfoPoints"
						label="Points"
						multiple
						outlined
						small-chips
						v-model="form.organisationInfoPoints">
			<template v-slot:item="{item}">
				<div class="d-flex flex-column">
					<app-text>{{ item.infoPointName }}</app-text>
					<app-text :color="item.infoPointType === 'Disability' ? 'blue' : 'orange'" size="small">
						{{ item.infoPointType }}
					</app-text>
				</div>
			</template>
		</v-autocomplete>

		<!--Description-->
		<app-text class="mt-4 mb-2" color="grey" size="normal-bold">Description</app-text>
		<vue-editor class="white"
					:class="computedTextEditorClass"
					:disabled="isReadOnly"
					:editor-toolbar="richTextEditorOptions"
					style="border: 1px solid red"
					v-model="form.organisationDescription"/>
		<app-text v-if="errors.organisationDescription" class="ml-4 pa-1" color="red" size="small">
			Description is required
		</app-text>

		<!--Membership-->
		<div class="mt-4">

			<!--Title | Add Icon-->
			<div class="d-flex align-center mb-2">
				<app-text class="mr-2" color="grey9" size="normal-bold">Membership</app-text>
				<app-icon v-if="!isReadOnly && formData.entityId" @click.native="openMembershipFormDialog"
						  color="orange" icon="add" size="32"/>
			</div>

			<!--Membership Cards-->
			<div>

				<!--No Organisation message-->
				<!--TODO The Organisation must be saved to get an ID for the Membership, change this work bubble up to the parent-->
				<app-text v-if="!formData.entityId" class="ml-4">Save this Organisation before adding Membership
					options
				</app-text>

				<!--No Memberships message-->
				<app-text v-if="formData.entityId && !membershipData.length" class="ml-4">No Memberships to show
				</app-text>

				<!--Membership Cards-->
				<div class="orgMembershipCards-grid">

					<organisation-membership-card v-for="item in membershipData" :key="item.entityId"
												  @click.native="openMembershipFormDialog(item)"
												  :cardData="item"
												  class="mb-4"/>

				</div>

			</div>

		</div>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block="true"
				 class="mt-4"
				 color="green"
				 :disabled="isReadOnly"
				 label="Save"/>

		<!-- ------------------------------------------------------------------------------------------------ Dialogs-->

		<!--Membership Form Dialog-->
		<v-dialog max-width="512"
				  v-model="isMembershipDialogVisible">

			<organisation-membership-form @emitCloseMembershipForm="emittedCloseMembershipForm"
										  @emitRefreshMembershipData="emittedRefreshMembershipData"
										  :organisationId="form.entityId"
										  :formData="selectedMembership"
										  :isReadOnly="isReadOnly"
										  @refreshPage="refreshPage"/>

		</v-dialog>

	</v-card>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import GoogleAutocomplete from "@/components/GoogleAutocomplete";
import {VueEditor} from "vue2-editor"
import OrganisationMembershipForm
	from "@/views/adminConsole/organisationsConsole/organisationMembershipForm/OrganisationMembershipForm";
import OrganisationMembershipCard from "@/views/organisation/organisationMembershipCard/OrganisationMembershipCard";

export default {

	name: "OrganisationsForm",

	components: {
		OrganisationMembershipCard,
		OrganisationMembershipForm, GoogleAutocomplete, VueEditor, VueGoogleAutocomplete
	},

	props: ['formData', 'infoPointsData', 'isReadOnly', 'membershipData'],

	data: () => ({
		entity: 'organisation',
		errors: {
			organisationName: false,
			organisationNameErrorMessage: '',
			organisationTelephone: false,
			organisationTelephoneErrorMessage: '',
			organisationEmail: false,
			organisationEmailErrorMessage: '',
			organisationWebsite: false,
			organisationWebsiteErrorMessage: '',
			organisationDescription: '',
			organisationInfoPoints: false,
			organisationInfoPointsErrorMessage: '',
			// organisationLogo: '',
			// organisationImages: [],
			// organisationCoverImage: '',
			// organisationCoverImageSmall: '',
			// organisationCoverImageMedium: '',
			// organisationCoverImageLarge: '',
			organisationImage: false,
			organisationImageErrorMessage: '',
			organisationLogo: false,
			organisationLogoErrorMessage: '',
		},
		form: {
			entityId: '',
			organisationName: '',
			organisationAddressLine1: '',
			organisationAddressLine2: '',
			organisationAddressTown: '',
			organisationAddressCity: '',
			organisationAddressCounty: '',
			organisationAddressPostcode: '',
			organisationAddressCoords: {longitude: 0.0, latitude: 0.0},
			organisationTelephone: '',
			organisationEmail: '',
			organisationWebsite: '',
			organisationDescription: '',
			organisationInfoPoints: [],
			organisationLogo: '',
			organisationImages: [],
			organisationCoverImage: '',
			organisationCoverImageSmall: '',
			organisationCoverImageMedium: '',
			organisationCoverImageLarge: '',

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		},
		isMembershipDialogVisible: false,

		selectedMembership: {},
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

		computedInfoPoints() {
			const t = this
			let infoPointsData = t.$props.infoPointsData

			infoPointsData = infoPointsData.filter(point => ['Organisation', 'Disability'].includes(point.infoPointType))

			infoPointsData = infoPointsData.sort((a, b) => {
				return a.infoPointType < b.infoPointType ? 1 : -1
			})

			return infoPointsData
		},

		/**
		 * Computed Text Editor Class
		 *
		 * Return a class name for the Text Editor UI, based on error and readOnly states.
		 *
		 * @returns {string} the class name to render
		 */
		computedTextEditorClass() {
			const t = this
			let computedClass

			if (t.errors.organisationDescription) computedClass = 'richText-error'

			if (!t.errors.organisationDescription && t.$props.isReadOnly) computedClass = 'richText-disabled'

			if (!t.errors.organisationDescription && !t.$props.isReadOnly) computedClass = 'richText-enabled'

			return computedClass
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		emittedCloseMembershipForm() {
			const t = this

			t.selectedMembership = {}
			t.isMembershipDialogVisible = false
		},

		emittedRefreshMembershipData(id) {
			const t = this

			t.$emit('emitRefreshMembershipData', id)
		},

		getAddressData(data) {
			const t = this

			t.form.organisationAddressLine1 = data.addressData?.route
			t.form.organisationAddressTown = data.addressData?.administrative_area_level_2
			t.form.organisationAddressCity = data.addressData?.administrative_area_level_2
			t.form.organisationAddressPostcode = data.addressData?.postal_code
			t.form.organisationAddressCoords.longitude = data.addressData?.longitude
			t.form.organisationAddressCoords.latitude = data.addressData?.latitude
			t.form.organisationTelephone = data.places?.formatted_phone_number
			t.form.organisationWebsite = data.places?.website
			t.form.organisationName = data.places?.name
		},

		/**
		 * Handle Image Upload
		 *
		 * Assign the emitted payload URL to the form.
		 *
		 * @param payload the url of the image
		 */
		handleImageUpload(payload) {
			const t = this

			if (payload.sizeLabel === 'small') t.form.organisationCoverImageSmall = payload.url
			if (payload.sizeLabel === 'medium') t.form.organisationCoverImageMedium = payload.url
			if (payload.sizeLabel === 'large') t.form.organisationCoverImageLarge = payload.url
		},

		handleLogoUpload(payload) {
			const t = this

			t.form.organisationLogo = payload.url
		},

		openMembershipFormDialog(item) {
			const t = this

			if (item.entityId) t.selectedMembership = item

			t.isMembershipDialogVisible = true
		},

		async save() {
			const t = this
			let response

			// Ensure the location object is numeric to create a Point
			t.form.organisationAddressCoords.longitude = t.form.organisationAddressCoords.longitude ? Number(t.form.organisationAddressCoords.longitude) : 0
			t.form.organisationAddressCoords.latitude = t.form.organisationAddressCoords.latitude ? Number(t.form.organisationAddressCoords.latitude) : 0

			// Only continue if the form passed validation
			if (!t.validateForm()) return

			// Create
			if (!t.form?.entityId) {
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			if (response.hasErrors) {
				console.error('Error saving Organisation: ', response.error)
				return
			}

			await t.updateOrganisationActivities()

			t.refreshPage()
		},

		refreshPage() {
			const t = this

			t.$emit('refreshPage')
		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Organisation Name
			if (!t.form.organisationName.trim()) {
				t.errors.organisationName = true
				t.errors.organisationNameErrorMessage = 'Name is required'
			}

			// Organisation Telephone
			// if (!t.form.organisationTelephone.trim()) {
			// 	t.errors.organisationTelephone = true
			// 	t.errors.organisationTelephoneErrorMessage = 'Telephone Number is required'
			// }

			// Organisation Email Address
			// if (!t.form.organisationEmail.trim()) {
			// 	t.errors.organisationEmail = true
			// 	t.errors.organisationEmailErrorMessage = 'Email Address is required'
			// }

			// Organisation Website
			if (!t.form.organisationWebsite.trim()) {
				t.errors.organisationWebsite = true
				t.errors.organisationWebsiteErrorMessage = 'Website is required'
			}

			// Organisation Info Points
			if (!t.form.organisationInfoPoints.length) {
				t.errors.organisationInfoPoints = true
				t.errors.organisationInfoPointsErrorMessage = 'Info Points are required'
			}

			// Organisation Description
			if (!t.form.organisationDescription || t.form.organisationDescription.replace(/\s/g, '') === '<p></p>') {
				t.errors.organisationDescription = true
				t.errors.organisationDescriptionErrorMessage = 'Description is required'
			}

			// Organisation Image
			if (!(t.form.organisationCoverImageSmall || t.form.organisationCoverImageMedium || t.form.organisationCoverImageLarge)) {
				t.errors.organisationImage = true
				t.errors.organisationImageErrorMessage = 'Cover Image is required'
			}

			// Organisation Logo
			if (!t.form.organisationLogo) {
				t.errors.organisationLogo = true
				t.errors.organisationLogoErrorMessage = 'Logo is required'
			}

			return !Object.values(t.errors).includes(true)
		},

		/**
		 * Update Organisation Activities
		 *
		 * Updates all the activities related to the organisation, if they need to be.
		 *
		 * @returns {Promise<void>}
		 */
		async updateOrganisationActivities() {
			const t = this

			// Get all activities related to the organisation
			const RESPONSE = await t.MIX_redis_getActivitiesByOrganisationId(t.formData.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisation Activities: ', RESPONSE.error)
				return
			}

			// Update the location information for each related activity
			const ACTIVITIES = RESPONSE.data
			for (const ACTIVITY of ACTIVITIES) {

				// Check if the activity's location information needs updating
				// Update it if it does
				if (
					ACTIVITY.activityAddressLine1 !== t.formData.organisationAddressLine1 ||
					ACTIVITY.activityAddressLine2 !== t.formData.organisationAddressLine2 ||
					ACTIVITY.activityAddressTown !== t.formData.organisationAddressTown ||
					ACTIVITY.activityAddressCity !== t.formData.organisationAddressCity ||
					ACTIVITY.activityAddressCounty !== t.formData.organisationAddressCounty ||
					ACTIVITY.activityAddressPostcode !== t.formData.organisationAddressPostcode ||
					JSON.stringify(ACTIVITY.activityAddressCoords) !== JSON.stringify(t.formData.organisationAddressCoords)
				) {
					ACTIVITY.activityAddressLine1 = t.formData.organisationAddressLine1
					ACTIVITY.activityAddressLine2 = t.formData.organisationAddressLine2
					ACTIVITY.activityAddressTown = t.formData.organisationAddressTown
					ACTIVITY.activityAddressCity = t.formData.organisationAddressCity
					ACTIVITY.activityAddressCounty = t.formData.organisationAddressCounty
					ACTIVITY.activityAddressPostcode = t.formData.organisationAddressPostcode
					ACTIVITY.activityAddressCoords = t.formData.organisationAddressCoords

					// Save the updated activity
					const UPDATED_RESPONSE = await t.MIX_redis_update('activity', ACTIVITY.entityId, ACTIVITY)
					if (UPDATED_RESPONSE.hasErrors) {
						console.error('Error updating Organisation Activity: ', UPDATED_RESPONSE.error)
						return
					}
				}

			}

		},

	},

}
</script>

<style scoped>
.orgForm-header-mobile {
	display: grid;
	grid-template-rows: auto auto;
}

.orgForm-header-desktop {
	display: grid;
	grid-template-columns: auto 1fr;
}

.orgMembershipCards-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
	grid-gap: 16px;
}
</style>
