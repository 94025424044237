import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {
	theme: {
		options: { customProperties: true },
		themes: {
			light: {

				// App colors
				appBackground: '#F5F5F5',
				green: '#4CAF50',
				orange: '#F15B22',
				pink: '#B52377',
				red: '#FF3D00',

				// Greys
				grey3: '#333333',
				grey6: '#666666',
				grey9: '#999999',
				greyD: '#DDDDDD',

				error: '#FF3D00' // same as red

			}
		}
	}
}

export default new Vuetify(opts)
