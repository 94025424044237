<!--
Local Storage used:
- categoriesData
- devicePositionLatitude
- devicePositionLongitude
- currentUserData

- isSearchActive
- searchActivities
- searchCardData
- searchCriteria
- searchPreferencesSwitch

- selectedCategory
- selectedOrganisation
- isProfileComplete
-->
<template>
	<div class="pb-8">

		<!--Error Message-->
		<div v-if="errorMessage" class="red pa-2" :class="$vuetify.breakpoint.width < 960 ? '' : 'rounded-b-lg'">
			<app-text class="text-center" color="white" size="small">
				{{ errorMessage }}
			</app-text>
		</div>

		<loading-animation v-if="isPageLoading"/>

		<!--<app-btn @click.native="updateActivityWithOrganisationAddressData" label="update activity address data"/>-->
		<!--<app-btn @click.native="updateOrganisationWithActivityCategoryData" label="update organisation category data"/>-->

		<!--Page Content-->
		<div v-if="!isPageLoading">

			<ios-install-banner v-if="showInstallMessage" @emitHideInstallPrompt="showInstallMessage = false"/>

			<!--Clear Search-->
			<div v-if="MIX_getFromLocalStorage('isSearchActive')" class="pt-4 px-4">
				<app-btn @click.native="clearSearchFilters"
						 :block="true"
						 color="green"
						 label="Click to clear Search"/>
			</div>

			<!--Clear Filter-->
			<div v-if="quickSearchCategory" class="pt-4 px-4">
				<app-btn @click.native="clearQuickSearchFilters"
						 :block="true"
						 class="text-capitalize"
						 color="green"
						 :label="`Click to clear ${quickSearchCategory.toUpperCase()} Filter`"/>
			</div>

			<!--Search Button-->
			<div v-if="!quickSearchCategory" class="pt-4 px-4">
				<app-btn @click.native="handleSearchClick"
						 :block="true"
						 color="appGradient"
						 :icon="isSearchPreferencesFormVisible ? 'close' : 'search'"
						 :label="isSearchPreferencesFormVisible ? 'Close Search' : 'Search'"/>
			</div>

			<!--Search Preferences Form-->
			<div v-if="isSearchPreferencesFormVisible" class="d-flex flex-column my-4 pa-4">

				<!--My Preferences Switch-->
				<v-switch @change="handleSearchPreferenceSwitch" class="mt-0"
						  color="orange"
						  hide-details
						  :label="useMySearchPreferences ? 'Using my search preferences' : 'Use my search preferences'"
						  v-model="useMySearchPreferences"/>

				<v-divider class="mt-8"/>

				<search-preferences-form @emitSearchCriteria="emittedSearchCriteria"
										 class="mt-8"
										 :manualSearchCriteria="MIX_getFromLocalStorage('searchCriteria')"
										 :isSearch="true"/>

				<v-divider class="mt-8"/>

			</div>

			<!--Home Page Body-->
			<div v-if="!isSearchPreferencesFormVisible">

				<!--Top Cards-->
				<div v-if="!MIX_getFromLocalStorage('isSearchActive')"
					 :class="$vuetify.breakpoint.width < 600 ? 'd-flex mt-4 px-4' : 'd-flex justify-center mt-4 px-4'"
					 style="overflow-x: auto">
					<top-card @click.native="handleQuickSearch('For Kids')" class="mr-2" label="Kids"/>
					<top-card label="Challenges" @click.native="MIX_go('challenges')" class="mx-2"/>
					<top-card @click.native="handleQuickSearch('Nutrition')" class="mx-2" label="Nutrition"/>
					<top-card label="C.A.L.L" @click.native="MIX_go('/mentalHealth')" class="ml-2"/>
				</div>

				<!--Exclusive Offers-->
				<div v-if="!quickSearchCategory && !MIX_getFromLocalStorage('isSearchActive')"
					 class="d-flex pt-4 px-4">
					<exclusive-offers-carousel @click.native="MIX_go('exclusiveOffers')"
											   :exclusive-offers-data="exclusiveOffersData"/>
				</div>

				<!--Categories-->
				<div v-for="item in computedCardData" :key="item.category.entityId" class="ml-4">

					<category-divider :description="item.category.categoryDescription"
									  :icon="item.category.categoryIcon"
									  :name="item.category.categoryName"/>

					<!--Center Cards-->
					<div class="category-row-container">

						<!--No Cards Message-->
						<app-text v-if="!item.organisations.length">There are no cards to show here</app-text>

						<!--Organisation Cards-->
						<center-card v-for="organisation in item.organisations"
									 @click.native="handleOrganisationSelection(item.category, organisation)"
									 class="category-card"
									 :organisation="organisation"/>

					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import CategoryDivider from "@/views/home/categoryDivider/CategoryDivider";
import CenterCard from "@/views/home/centerCard/CenterCard";
import ExclusiveOffersCarousel from "@/views/home/exclusiveOffers/ExclusiveOffersCarousel";
import LoadingAnimation from "@/components/LoadingAnimation";
import SearchPreferencesForm from "@/views/myProfile/searchPreferencesForm/SearchPreferencesForm";
import TopCard from "@/views/home/topCard/TopCard";
import IosInstallBanner from "@/components/IosInstallInstructions";

export default {

	name: 'Home',

	components: {
		IosInstallBanner,
		CategoryDivider,
		CenterCard,
		ExclusiveOffersCarousel,
		LoadingAnimation,
		SearchPreferencesForm,
		TopCard,
	},

	data: () => ({
		currentUserData: {},
		errorMessage: '',
		isSearchPreferencesFormVisible: false,
		quickSearchCategory: '',
		searchCriteria: {},
		useMySearchPreferences: false,

		devicePosition: {latitude: 0.0, longitude: 0.0},
		showInstallMessage: false,

		// Data
		categoriesData: [],
		exclusiveOffersData: [],
		organisationsData: [],
		searchPreferenceData: {},
		isPageLoading: true,

		organisationDistances: [],
	}),

	computed: {

		computedErrorMessage() {
			const t = this


		},

		computedCardData() {
			const t = this
			let cardData = []
			const QUICK_SEARCH_CATEGORY = t.quickSearchCategory
			const IS_SEARCH = t.MIX_getFromLocalStorage('isSearchActive')
			const SEARCH_CARD_DATA = t.MIX_getFromLocalStorage('searchCardData')
			let categoriesData = t.categoriesData
			let organisationsData = t.organisationsData
			let activitiesData = t.activitiesData

			// If this is a currently active search (but not new), return the stored card data
			if (IS_SEARCH && SEARCH_CARD_DATA?.length && !t.isNewSearch) return t.MIX_getFromLocalStorage('searchCardData')

			// If there is an active search filter, only show the required categories
			if (t.searchCriteria?.searchPreferenceCategories?.length) {
				categoriesData = categoriesData.filter(c => t.searchCriteria.searchPreferenceCategories.includes(c.categoryName))
			}

			categoriesData.forEach(category => {

				// Get the Activities for this category
				const activities = activitiesData?.filter(a => a.activityCategory?.includes(category.categoryName))

				// Get the Organisations for this category
				let organisations = organisationsData.filter(o => o.organisationCategories?.includes(category.categoryName))
				// If this is a search, also filter by activities to avoid duplicated Organisation cards
				// This will cause cards to appear because of the Organisation having this category, but doesn't have an activity
				if (IS_SEARCH) organisations = organisationsData.filter(o => o.organisationCategories?.includes(category.categoryName) && activities.length)

				// Add the geo-distances from the search into the Organisation
				organisations.forEach(org => {
					t.organisationDistances.forEach(dist => {
						if (org.entityId === dist.organisationId) org.distance = dist.distance
					})
				})

				// If there are distances (a search is in place), sort the results by distance (nearest first)
				if (t.organisationDistances.length) {
					organisations = organisations.sort((a, b) => {
						return a.distance > b.distance ? 1 : -1
					})
				}

				cardData.push({category, organisations})
			})

			// If there is a quick search in place, only show the required category
			if (QUICK_SEARCH_CATEGORY) cardData = cardData.filter(cd => cd.category.categoryName === QUICK_SEARCH_CATEGORY)

			// If this is a new search, add the card data to the local storage,
			// so it can be retrieved and displayed when returning to the home page,
			// otherwise, the search state data is lost
			// if (IS_SEARCH && !SEARCH_CARD_DATA?.length && t.isNewSearch) {
			if (IS_SEARCH && t.isNewSearch) t.MIX_addToLocalStorage('searchCardData', cardData)

			return cardData
		},

	},

	methods: {

		/**
		 * Clear Search Filters
		 *
		 * Clear the search filters, close the search form, and reload the data.
		 */
		clearSearchFilters() {
			const t = this

			t.isPageLoading = true

			t.searchCriteria = {}
			t.useMySearchPreferences = false
			t.organisationDistances = []
			t.MIX_addToLocalStorage('isSearchActive', false)
			t.MIX_addToLocalStorage('searchActivities', false)
			t.MIX_addToLocalStorage('searchCardData', false)
			t.MIX_addToLocalStorage('searchCriteria', false)
			t.MIX_addToLocalStorage('searchPreferencesSwitch', false)

			t.closeSearchPreferences()
			t.loadData()
		},

		/**
		 * Clear Quick Search Filters
		 *
		 * Clear the quick search filter
		 */
		clearQuickSearchFilters() {
			const t = this

			t.isPageLoading = true

			t.quickSearchCategory = ''

			t.isPageLoading = false
		},

		/**
		 * Close Search Preferences
		 *
		 * Close the search preferences window.
		 */
		closeSearchPreferences() {
			const t = this

			t.isSearchPreferencesFormVisible = false
		},

		/**
		 * Emitted Search Responses
		 *
		 * Take the searchCriteria from the emitted searchPreferenceForm payload, and assign it to state.
		 * Call to search when done.
		 *
		 * @param payload the search criteria from the Search Preferences Form
		 */
		emittedSearchCriteria(payload) {
			const t = this

			t.searchCriteria = payload.searchCriteria

			t.search(t.searchCriteria)
		},

		/**
		 * Get Categories Data
		 *
		 * Get the data from the DB, remove any deleted items, and assign to state.
		 *
		 * @returns {Promise<void>}
		 */
		async getCategoriesData() {
			const t = this
			let responseData = []

			const RESPONSE = await t.MIX_redis_getAll('category')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Categories data: ', RESPONSE.error)
				return
			}

			responseData = RESPONSE.data

			// Remove any deleted items
			responseData = responseData.filter(i => !i.isDeleted)

			// Sort alphabetically by name
			responseData = responseData.sort((a, b) => {
				return a.categoryName > b.categoryName ? 1 : -1
			})

			t.categoriesData = responseData
		},

		/**
		 * Get Exclusive Offers Data
		 *
		 * Get the data from the DB, remove any deleted items, and assign to state.
		 *
		 * @returns {Promise<void>}
		 */
		async getExclusiveOffersData() {
			const t = this
			let responseData = []

			const RESPONSE = await t.MIX_redis_getAll('exclusiveOffer')

			// Handle any error
			if (RESPONSE.hasErrors) {
				console.error('Error getting Exclusive Offers: ', RESPONSE.error)
				return
			}

			responseData = RESPONSE.data

			// Remove any deleted items
			responseData = responseData.filter(i => !i.isDeleted)

			// Sort alphabetically by name
			responseData = responseData.sort((a, b) => {
				return a.exclusiveOfferName > b.exclusiveOfferName ? 1 : -1
			})

			t.exclusiveOffersData = responseData
		},

		/**
		 * Get Organisations Data
		 *
		 * Get the data from the DB, remove any deleted items, and assign to state.
		 *
		 * @returns {Promise<void>}
		 */
		async getOrganisationsData() {
			const t = this
			const DEVICE_LONGITUDE = t.devicePosition.longitude
			const DEVICE_LATITUDE = t.devicePosition.latitude
			const RADIUS = !DEVICE_LONGITUDE || !DEVICE_LATITUDE ? '10000' : '10'
			let responseData = []

			const RESPONSE = await t.MIX_redis_getOrganisationsByDistance(DEVICE_LONGITUDE, DEVICE_LATITUDE, RADIUS)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisations data: ', RESPONSE.data)
				return
			}

			responseData = RESPONSE.data

			// Sort alphabetically by name
			responseData = responseData.sort((a, b) => {
				return a.organisationName > b.organisationName ? 1 : -1
			})

			t.organisationsData = responseData
		},

		// async getTESTOrganisationsData() {
		// 	const t = this
		// 	const DEVICE_LONGITUDE = t.devicePosition.longitude
		// 	const DEVICE_LATITUDE = t.devicePosition.latitude
		// 	const RADIUS = '10000'
		// 	let responseData = []
		//
		// 	const RESPONSE = await t.MIX_redis_getOrganisationsByDistance(DEVICE_LONGITUDE, DEVICE_LATITUDE, RADIUS)
		//
		// 	// Handle any errors
		// 	if (RESPONSE.hasErrors) {
		// 		console.error('Error getting Organisations data: ', RESPONSE.data)
		// 		return
		// 	}
		//
		// 	responseData = RESPONSE.data
		//
		// 	// Sort alphabetically by name
		// 	responseData = responseData.sort((a, b) => {
		// 		return a.organisationName > b.organisationName ? 1 : -1
		// 	})
		//
		// 	t.organisationsData = responseData
		// },

		// async getTESTActivityData() {
		// 	const t = this
		// 	const DEVICE_LONGITUDE = t.devicePosition.longitude
		// 	const DEVICE_LATITUDE = t.devicePosition.latitude
		// 	const RADIUS = '10000'
		// 	let responseData = []
		//
		// 	const RESPONSE = await t.MIX_redis_getAll('activity')
		//
		// 	// Handle any errors
		// 	if (RESPONSE.hasErrors) {
		// 		console.error('Error getting Activities data: ', RESPONSE.data)
		// 		return
		// 	}
		//
		// 	t.activitiesData = RESPONSE.data
		// },

		/**
		 * Get Search Preferences Data
		 *
		 * Get the data from the DB, remove any deleted items, and assign to state.
		 *
		 * @returns {Promise<void>}
		 */
		async getCurrentUserData() {

			const t = this

			const RESPONSE = await t.MIX_redis_getCurrentUserData()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Current User data: ', RESPONSE.error)
				return
			}

			t.currentUserData = RESPONSE.data
		},

		/**
		 * Get Search Preferences Data
		 *
		 * Get the data from the DB, remove any deleted items, and assign to state.
		 *
		 * @returns {Promise<void>}
		 */
		async getSearchPreferenceData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getCurrentUserSearchPreference(t.currentUserData.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Search Preferences data: ', RESPONSE.error)
				return
			}

			t.searchPreferenceData = RESPONSE.data
		},

		/**
		 * Handle Organisation Selection
		 *
		 * On Organisation card selection, assign the data so the correct Activities are shown for the Organisation,
		 * and in the correct sections.
		 *
		 * @param category
		 * @param organisation
		 */
		async handleOrganisationSelection(category, organisation) {
			const t = this

			// Add selected Organisation data to local storage
			t.MIX_addToLocalStorage('selectedOrganisation', organisation)
			// Add Categories data to local storage
			t.MIX_addToLocalStorage('categoriesData', t.categoriesData)
			// Add selected Category to local storage
			t.MIX_addToLocalStorage('selectedCategory', category)

			t.MIX_go('organisation')
		},

		handleQuickSearch(category) {
			const t = this

			t.quickSearchCategory = category
		},

		/**
		 * Handle Search Click
		 *
		 * If the search preferences form is close, open it on input touch.
		 */
		handleSearchClick() {
			const t = this

			t.isSearchPreferencesFormVisible = !t.isSearchPreferencesFormVisible
		},

		/**
		 * Handle Search Preferences Switch
		 *
		 * When the 'Use My Search Preference' switch is activated, load the current user's Search Preference data into
		 * the search criteria.
		 * Clear search filters when deactivated.
		 */
		handleSearchPreferenceSwitch() {
			const t = this

			if (t.useMySearchPreferences) {
				t.MIX_addToLocalStorage('searchCriteria', t.searchPreferenceData)
				t.MIX_addToLocalStorage('searchPreferencesSwitch', t.useMySearchPreferences)
			} else {
				t.clearSearchFilters()
			}

		},

		/**
		 * Load Data
		 *
		 * Call to load all the required data from the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this
			const IS_SEARCH_ACTIVE = t.MIX_getFromLocalStorage('isSearchActive')

			// await t.getTESTActivityData()
			// await t.getTESTOrganisationsData()

			!IS_SEARCH_ACTIVE && await t.getOrganisationsData()
			await t.getCategoriesData()
			await t.getCurrentUserData()
			await t.getExclusiveOffersData()
			await t.getSearchPreferenceData()

			t.MIX_addToLocalStorage('currentUserData', t.currentUserData)

			// Go to the Profile page if either the Profile or Search Preferences are incomplete
			if (!t.currentUserData.userIsProfileComplete || !t.currentUserData.userIsSearchPreferencesComplete) t.MIX_go('/myProfile')

			t.isPageLoading = false
		},

		/**
		 * Search
		 *
		 * Using the user's search criteria, get the data from the DB and assign the new Activity and Organisation data.
		 *
		 * @param searchCriteria the search criteria from the search preferences form
		 * @returns {Promise<void>}
		 */
		async search(searchCriteria) {
			const t = this

			t.isPageLoading = true

			// Fetch search data
			const SEARCH_RESPONSE = await t.MIX_redis_getSearchActivities(
				searchCriteria.searchPreferenceLocation.longitude,
				searchCriteria.searchPreferenceLocation.latitude,
				searchCriteria.searchPreferenceDistance,
				searchCriteria
			)

			// Handle any errors
			if (SEARCH_RESPONSE.hasErrors) {
				console.error('Error searching: ', SEARCH_RESPONSE)
				return
			}

			// Set search data
			t.activitiesData = SEARCH_RESPONSE.data.matchingActivities
			t.organisationsData = SEARCH_RESPONSE.data.matchingOrganisations
			t.organisationDistances = SEARCH_RESPONSE.data.distanceToMatchingOrganisation
			t.MIX_addToLocalStorage('isSearchActive', true)
			t.MIX_addToLocalStorage('searchActivities', t.activitiesData)
			t.MIX_addToLocalStorage('searchCriteria', searchCriteria)
			t.isNewSearch = true

			t.isPageLoading = false

			t.closeSearchPreferences()
		},

		/**
		 * Update the db data using the functions below ----------------------------------------------------------------
		 */

		// Run First - Add the organisation's address to their activities
		async updateActivityWithOrganisationAddressData() {
			const t = this
			t.isPageLoading = true
			let activityData = {}
			let organisationData = {}

			let counter = 0

			for (const o of t.organisationsData) {
				// console.log('organisation name: ', organisationData.organisationName)
				organisationData = o

				for (const a of t.activitiesData) {
					// console.log('==== activity name: ', activityData.activityName)
					activityData = a

					if (o.entityId === a.organisation) {
						counter++
						console.log('============================================')
						console.log('match: ', o.entityId + ' : ', a.organisation)
						console.log('match: ', o.organisationName + ' : ', a.activityName)

						activityData.activityAddressLine1 = organisationData.organisationAddressLine1
						activityData.activityAddressLine2 = organisationData.organisationAddressLine2
						activityData.activityAddressTown = organisationData.organisationAddressTown
						activityData.activityAddressCity = organisationData.organisationAddressCity
						activityData.activityAddressCounty = organisationData.organisationAddressCounty
						activityData.activityAddressPostcode = organisationData.organisationAddressPostcode
						activityData.activityAddressCoords = {
							longitude: organisationData.organisationAddressCoords.longitude,
							latitude: organisationData.organisationAddressCoords.latitude
						}

						// console.log('activityData: ', activityData)

						await t.MIX_redis_update('activity', a.entityId, activityData)
					}

				}
			}

			console.log('counter: ', counter)
			console.log('done')
			t.isPageLoading = false
		},

		// Run second - add the activity category data to its parent organisation
		async updateOrganisationWithActivityCategoryData() {
			const t = this
			t.isPageLoading = true
			let organisationData = {}

			for (const o of t.organisationsData) {
				console.log('organisation name: ', organisationData.organisationName)
				organisationData = o
				let organisationCategories = []

				for (const a of t.activitiesData) {
					console.log('==== activity name: ', a.activityName)

					if (o.entityId === a.organisation) {
						console.log('======== match: ', o.entityId + ' : ', a.organisation)
						console.log('======== activity: ', a.activityName)
						console.log('======== category: ', a.activityCategory)

						// Add all the Categories from each Organisation's Activities to the array
						organisationCategories.push(a.activityCategory)
					}

				}

				// Remove any duplications
				organisationCategories = [...new Set(organisationCategories.flat())]
				console.log(o.organisationName + '\n' + organisationCategories)

				organisationData.organisationCategories = organisationCategories

				console.log('organisationData: ', organisationData)
				await t.MIX_redis_update('organisation', o.entityId, organisationData)
			}

			console.log('done')
			t.isPageLoading = false
		},

		getDevicePosition() {
			const t = this
			let error

			if (!navigator.geolocation) error = 'Geolocation not supported'

			// Check the location permission
			// navigator.permissions.query({name: 'geolocation'})
			// 	.then(result => {

			// If the Geolocation permission has been granted, get the current position
			// if (['granted', 'prompt'].includes(result.state)) {

			// Fetch the device position
			navigator.geolocation.getCurrentPosition(
				position => t.handleDevicePosition(position),
				error => t.handleDevicePositionError(error)
			)

			// }

			// If the Geolocation permission has NOT been granted, reset the position
			// else {
			// 	console.log('Location permission denied: ', result)
			//
			// 	t.devicePosition.latitude = 0.0
			// 	t.devicePosition.longitude = 0.0
			//
			// 	t.MIX_addToLocalStorage('devicePositionLongitude', 0.0)
			// 	t.MIX_addToLocalStorage('devicePositionLatitude', 0.0)
			// }

			// })
		},

		handleDevicePosition(position) {
			const t = this

			t.devicePosition.latitude = position.coords.latitude
			t.devicePosition.longitude = position.coords.longitude

			t.MIX_addToLocalStorage('devicePositionLongitude', position.coords.longitude)
			t.MIX_addToLocalStorage('devicePositionLatitude', position.coords.latitude)
		},

		handleDevicePositionError(error) {
			const t = this
			let errorMessage
			console.error('Error fetching device position: ', error)

			switch (error.code) {
				case error.PERMISSION_DENIED:
					// User denied the request for Geolocation.
					errorMessage = 'Allow location permissions for quicker local searches'
					break;
				case error.POSITION_UNAVAILABLE:
					errorMessage = 'Location information is unavailable'
					break;
				case error.TIMEOUT:
					errorMessage = 'Oops, search has timed-out'
					break;
				case error.UNKNOWN_ERROR:
					errorMessage = 'Oops, something went wrong'
					break;
				default:
					errorMessage = 'There was an issue fetching your location'
			}

			t.devicePosition.latitude = 0.0
			t.devicePosition.longitude = 0.0

			t.MIX_addToLocalStorage('devicePositionLongitude', 0.0)
			t.MIX_addToLocalStorage('devicePositionLatitude', 0.0)

			t.errorMessage = errorMessage
		},

	},

	watch: {

		// async 'devicePosition.latitude'() {
		// 	console.log('devicePosition.latitude...')
		// 	const t = this
		//
		// 	if (!t.isPageLoading) await t.loadData()
		// }

		errorMessage() {
			const t = this

			setTimeout(() => {
				t.errorMessage = ''
			}, 4000)
		}

	},

	async created() {
		const t = this

		// t.MIX_deleteAllFromLocalStorage()

		t.devicePosition.latitude = t.MIX_getFromLocalStorage('devicePositionLatitude')
		t.devicePosition.longitude = t.MIX_getFromLocalStorage('devicePositionLongitude')

		t.useMySearchPreferences = t.MIX_getFromLocalStorage('searchPreferencesSwitch')

		t.getDevicePosition()

		await t.loadData()

		// iOS user agents
		const iOSUserAgents = ['iPhone', 'iPad', 'iPod']
		// Check if the device is an iOS device and if it's not in standalone mode
		// (standalone mode is when the app is added to the home screen)
		if (!t.MIX_getFromLocalStorage('hideInstallPrompt')) {
			if (iOSUserAgents.some(userAgent => navigator.userAgent.includes(userAgent)) && !navigator.standalone) {
				// Set the showInstallMessage property to true
				// This will display a message prompting the user to add the app to their home screen
				t.showInstallMessage = true
			}
		}

	}

}
</script>

<style scoped>
.category-row-container {
	display: flex;
	gap: 16px;

	background: transparent;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
}

.category-card {
	scroll-snap-align: start;
}
</style>
