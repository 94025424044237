<template>
	<div>

		<!--Action Buttons | Searches-->
		<div class="d-flex align-center">

			<!--Searches-->
			<div>

				<!--By Name-->
				<app-input input-type="textInput"
						   append-icon="icons8-search"
						   placeholder="Search by name"
						   v-model.trim="searchByName"/>

			</div>

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="rounded-lg mt-4"
					  :headers="computedTableHeaders"
					  :items="computedTableData"
					  item-key="entityId"
					  no-data-text="Nothing to show, does it exist?"
					  style="border: 1px solid var(--v-greyD-base)">

			<!--Status-->
			<template v-slot:item.userStatus="{item}">
				<app-icon color="green" icon="check"/>
				<!--<app-text size="small">{{ item.userStatus }}</app-text>-->
			</template>

			<!--Name-->
			<template v-slot:item.userName="{item}">
				<app-text size="small">{{ item.userName }}</app-text>
			</template>

			<!--Email-->
			<template v-slot:item.userEmail="{item}">
				<app-text size="small">{{ item.userEmail }}</app-text>
			</template>

			<!--Date of Birth-->
			<template v-slot:item.userDateOfBirth="{item}">
				<app-text size="small">
					{{ MIX_formattedDate('long', item.userDateOfBirth) }}
				</app-text>
			</template>

			<!--Gender-->
			<template v-slot:item.userGender="{item}">
				<app-text size="small">{{ item.userGender }}</app-text>
			</template>

			<!--Actions-->
			<template v-slot:item.actions="{item}">
				<app-icon @click.native="openDialog(item)"
						  color="pink" icon="forwardArrow" size="24"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<div v-if="!computedTableData.length"
				 class="d-flex align-center justify-center" style="height: 50vh">
				<app-text>Nothing to show, does it exist?</app-text>
			</div>

			<v-card v-for="item in computedTableData" :key="item.entityId"
					@click="openDialog(item)"
					class="rounded-lg mt-4 pa-4"
					flat
					style="border: 1px solid var(--v-greyD-base)">

				<!--UserName-->
				<app-text class="mb-4" size="normal-bold">{{ item.userName }}</app-text>

				<!--Date of Birth-->
				<div class="d-flex align-center">
					<app-text class="mr-2" color="orange" size="small">Date of Birth</app-text>
					<app-text>
						{{ MIX_formattedDate('long', item.userDateOfBirth) }}
					</app-text>
				</div>

				<!--Sex-->
				<div class="d-flex align-center mt-2">
					<app-text class="mr-2" color="orange" size="small">Gender</app-text>
					<app-text>{{ item.userGender }}</app-text>
				</div>

			</v-card>

		</div>

		<!--Form Dialog-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isDialogVisible"
				 class="white shadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 75%'"
				 v-model="isDialogVisible">

				<v-toolbar class="appGradient">
					<app-text color="white" size="medium">User</app-text>
					<v-spacer/>
					<edit-icon @click.native="editKey" class="pa-2" :isActive="!isReadOnly"/>
					<delete-icon @emitDeleteItem="deleteKey(selectedItem.entityId)"
								 class="pa-2"
								 itemName="userName"
								 :selectedItem="selectedItem"/>
					<close-icon @click.native="closeDialog" class="pa-2"/>
				</v-toolbar>

				<users-form class="mt-4 pa-4"
							:formData="selectedItem"
							:isReadOnly="isReadOnly"
							:userLevel="userLevel"
							viewType="Admin"
							v-on:refreshPage="refreshPage"/>

			</div>
		</transition>

	</div>
</template>

<script>
import UsersForm from "@/views/adminConsole/usersConsole/UsersForm";

export default {

	name: "UsersConsole",

	components: {UsersForm},

	data: () => ({
		entity: 'user',
		isDialogVisible: false,
		isReadOnly: false,
		searchByName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hiddenByDefault: true},
			{text: '', value: 'userStatus', align: 'start', sortable: false, hiddenByDefault: false, width: '24px'},
			{text: 'Name', value: 'userName', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Email', value: 'userEmail', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Date of Birth', value: 'userDateOfBirth', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Gender', value: 'userGender', align: 'start', sortable: false, hiddenByDefault: false},
			{text: '', value: 'actions', align: 'center', sortable: false, hiddenByDefault: false, width: '24px'},
		],
		userLevel: '',

		// Data
		usersData: [],
	}),

	computed: {

		computedTableData() {
			const t = this
			let tableData = t.usersData

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Search by Name
			if (t.searchByName) {
				tableData = tableData.filter(data => data.userName.toUpperCase().match(t.searchByName.toUpperCase()))
			}

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.userName > b.userName ? 1 : -1
			})

			return tableData
		},

		computedTableHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hiddenByDefault)

			return headers
		}

	},

	methods: {

		/**
		 * Get Entity Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getEntityData() {
			const t = this
			let RESPONSE

			RESPONSE = await t.MIX_redis_getAll(t.entity)

			if (RESPONSE.hasErrors) {
				console.error('Error getting users data: ', RESPONSE.error)
				return
			}

			t.usersData = RESPONSE.data
		},

		/**
		 * Delete Key
		 *
		 * Delete the key by its ID, and refresh the page.
		 *
		 * @param id ID of key
		 * @returns {Promise<void>}
		 */
		async deleteKey(id) {
			const t = this
			let response

			response = await t.MIX_redis_delete(t.entity, id, t.selectedItem)

			if (!response.hasErrors) await t.refreshPage()
		},

		editKey() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Close Dialog
		 *
		 * Close the form dialog.
		 */
		closeDialog() {
			const t = this

			t.selectedItem = {}
			t.isDialogVisible = false
		},

		/**
		 * Open Dialog
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openDialog(item) {
			const t = this

			t.isReadOnly = !!item.entityId

			t.selectedItem = item

			t.isDialogVisible = true
		},

		/**
		 * Refresh Page
		 *
		 * Close the open form dialog, and call for the new entity data.
		 *
		 * @returns {Promise<void>}
		 */
		async refreshPage() {
			const t = this

			t.closeDialog()

			await t.getEntityData()
		},

	},

	async created() {
		const t = this

		await t.getEntityData()

		t.userLevel = await t.MIX_getUserLevel()
	}

}
</script>

<style scoped>

</style>
