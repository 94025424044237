<template>
	<v-expansion-panels flat>
		<v-expansion-panel>

			<!--Header-->
			<v-expansion-panel-header class="iosInstallBanner text-center rounded-t-lg" color="appGradient" hide-actions>
				<div class="animate__animated animate__pulse animate__infinite animate__faster mb-2">
					<app-text color="white" size="normal-bold" style="letter-spacing: 2px">Install ME !</app-text>
				</div>
				<app-text color="white" size="small">Add Fitap to your home page for quick and easy access</app-text>
			</v-expansion-panel-header>

			<!--Content-->
			<v-expansion-panel-content class="rounded-b-lg" color="appGradient">
				<div class="d-flex flex-column align-center text-center white rounded-lg mt-0 ma-4 pa-4">

					<!--Title-->
					<app-text size="normal-bold">
						Add Fitap to your Home Page with these 3 simple steps:
					</app-text>

					<!--Step 1-->
					<app-text class="mt-8 mb-4">Tap the <strong>SHARE</strong> icon</app-text>
					<v-img :src="require('@/assets/images/ios-install-step-1.svg')"
						   class="shadow rounded-lg"
						   width="100%" max-width="400"/>

					<!--Step 2-->
					<app-text class="mt-8 mb-4">Tap the <strong>Add to Home Screen</strong> option</app-text>
					<v-img :src="require('@/assets/images/ios-install-step-2.svg')"
						   class="shadow rounded-lg"
						   width="100%" max-width="400"/>

					<!--Step 3-->
					<app-text class="mt-8 mb-4">Tap <strong>Add</strong></app-text>
					<v-img :src="require('@/assets/images/ios-install-step-3.svg')"
						   class="shadow rounded-lg"
						   width="100%" max-width="400"/>

					<!--Don't show again button-->
					<div v-if="showDismissBanner" style="width: 100%">
						<v-divider class="mt-8" />
						<app-text @click.native="handleDismissInstallPrompt" class="mt-4" color="grey9">Don't show me this again</app-text>
					</div>

				</div>
			</v-expansion-panel-content>

		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {

	name: "IosInstallInstructions",

	props: {showDismissBanner: {default: true}},

	methods: {

		/**
		 * Handle Dismiss Install Prompt
		 *
		 * If actioned, permanently hide the Install prompt.
		 */
		handleDismissInstallPrompt() {
			const t = this

			t.MIX_addToLocalStorage('hideInstallPrompt', true)
			t.$emit('emitHideInstallPrompt')
		}

	},

}
</script>

<style scoped>
.iosInstallBanner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>
