<template>
	<v-form class="appBackground pa-4">

		{{ computedInit }}

		<!--Image-->
		<div :class="$vuetify.breakpoint.width < 800 ? 'exOffForm-header-mobile' : 'exOffForm-header-desktop'">

			<!--Image | Icon-->
			<div :class="$vuetify.breakpoint.width < 800 && 'd-flex justify-center'">

				<!--Image-->
				<v-img v-if="form.exclusiveOfferCoverImageSmall"
					   class="rounded-lg"
					   contain
					   :src="form.exclusiveOfferCoverImageSmall"
					   height="100%"
					   max-height="464"
					   width="100%"
					   max-width="464"/>

				<!--Icon-->
				<app-icon v-if="!form.exclusiveOfferCoverImageSmall" icon="image" size="256"/>

			</div>

			<!--Search | Upload Button-->
			<div :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'ml-4'">

				<google-autocomplete @placechanged="getAddressData"
									 placeholder="Business search..."
									 types="establishment"/>

				<image-upload @emitImageUrl="handleImageUpload"
							  :block="true"
							  class="mt-4"
							  color="appGradient"
							  :disabled="isReadOnly"
							  folder="exclusiveOffers"
							  icon="upload"
							  imageFor="ExclusiveOffer"
							  label="Upload Image"/>

				<!--Image Errors-->
				<app-text v-if="errors.exclusiveOfferImage" color="red" size="small">Image Required</app-text>

				<app-text class="d-flex justify-center text-center mt-2" size="small">
					For best results, the image should have a 2:1 aspect ratio
					<br>
					E.g. (w)1000px (h)500px
				</app-text>

			</div>

		</div>

		<!--Name-->
		<app-input input-type="textInput"
				   class="mt-4"
				   :disabled="isReadOnly"
				   :error="errors.exclusiveOfferName"
				   :error-message="errors.exclusiveOfferNameErrorMessage"
				   label="Name"
				   v-model.trim="form.exclusiveOfferName"/>

		<!--Telephone | Email | Website-->
		<v-row no-gutters>

			<!--Telephone-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.exclusiveOfferTelephone"
						   :error-message="errors.exclusiveOfferTelephoneErrorMessage"
						   label="Telephone"
						   type="number"
						   v-model.trim="form.exclusiveOfferTelephone"/>
			</v-col>

			<!--Email-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 px-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.exclusiveOfferEmail"
						   :error-message="errors.exclusiveOfferEmailErrorMessage"
						   label="Email"
						   v-model.trim="form.exclusiveOfferEmail"/>
			</v-col>

			<!--Website-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.exclusiveOfferWebsite"
						   :error-message="errors.exclusiveOfferWebsiteErrorMessage"
						   label="Website"
						   v-model.trim="form.exclusiveOfferWebsite"/>
			</v-col>

		</v-row>

		<!--Date | Time-->
		<v-row no-gutters>

			<!--Date Picker-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<date-picker @emitDate="handleDateSelection"
							 :date="form.exclusiveOfferDate"
							 :disabled="isReadOnly"
							 label="Date (optional)"
							 :max-date="[10, 'years', 'future']"/>
			</v-col>

			<!--Time Picker-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<time-picker @emitTime="handleTimeSelection"
							 :disabled="isReadOnly"
							 label="Time (optional)"
							 :time="form.exclusiveOfferTime"/>
			</v-col>

		</v-row>

		<!--Cost | Sex-->
		<v-row no-gutters>

			<!--Cost-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Cost (optional)"
						   v-model.trim="form.exclusiveOfferCost"/>
			</v-col>

			<!--Sex-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :items="activitySexOptions"
						   label="Sex (optional)"
						   v-model.trim="form.exclusiveOfferSex"/>
			</v-col>

		</v-row>

		<!--Address-->
		<v-row no-gutters>

			<!--Address Line 1-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Line 1 (optional)"
						   v-model.trim="form.exclusiveOfferAddressLine1"/>
			</v-col>

			<!--Address Line 2-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Line 2 (optional)"
						   v-model.trim="form.exclusiveOfferAddressLine2"/>
			</v-col>

			<!--Address Town-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Town (optional)"
						   v-model.trim="form.exclusiveOfferAddressTown"/>
			</v-col>

			<!--Address City-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address City"
						   v-model.trim="form.exclusiveOfferAddressCity"/>
			</v-col>

			<!--Address County-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address County (optional)"
						   v-model.trim="form.exclusiveOfferAddressCounty"/>
			</v-col>

			<!--Address Postcode-->
			<v-col :cols="$vuetify.breakpoint.width < 800 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Postcode (optional)"
						   v-model.trim="form.exclusiveOfferAddressPostcode"/>
			</v-col>

			<!--Latitude-->
			<!--<app-input input-type="textInput"-->
			<!--		   class="mt-8"-->
			<!--		   label="Address Coords - Latitude"-->
			<!--		   v-model.trim="form.challengeAddressCoords.latitude"/>-->

			<!--Longitude-->
			<!--<app-input input-type="textInput"-->
			<!--		   class="mt-8"-->
			<!--		   label="Address Coords - Longitude"-->
			<!--		   v-model.trim="form.exclusiveOfferAddressCoords.longitude"/>-->

		</v-row>

		<!--Info Points-->
		<app-input input-type="autoComplete"
				   class="mt-4"
				   :disabled="isReadOnly"
				   label="Info Points (optional)"
				   :items="infoPointsOptions"
				   v-model="form.exclusiveOfferInfoPoints"/>

		<!--Description-->
		<app-text class="mt-4 mb-2" color="grey" size="normal-bold">Description</app-text>
		<vue-editor class="white"
					:class="computedTextEditorClass"
					:disabled="isReadOnly"
					:editor-toolbar="richTextEditorOptions"
					v-model="form.exclusiveOfferDescription"/>
		<app-text v-if="errors.exclusiveOfferDescription" class="ml-4 pa-1" color="red" size="small">
			Description is required
		</app-text>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block="true"
				 class="mt-4"
				 color="green"
				 :disabled="isReadOnly"
				 label="Save"/>

	</v-form>
</template>

<script>
import GoogleAutocomplete from "@/components/GoogleAutocomplete";
import {VueEditor} from "vue2-editor";

export default {

	name: "ExclusiveOffersForm",

	components: {GoogleAutocomplete, VueEditor},

	props: ['formData', 'isReadOnly'],

	data: () => ({
		entity: 'exclusiveOffer',
		errors: {
			exclusiveOfferName: false,
			exclusiveOfferNameErrorMessage: '',
			exclusiveOfferTelephone: false,
			exclusiveOfferTelephoneErrorMessage: '',
			exclusiveOfferEmail: false,
			exclusiveOfferEmailErrorMessage: '',
			exclusiveOfferWebsite: false,
			exclusiveOfferWebsiteErrorMessage: '',
			exclusiveOfferDescription: false,
			exclusiveOfferDescriptionErrorMessage: '',
			exclusiveOfferImage: false,
			exclusiveOfferImageErrorMessage: '',
		},
		form: {
			entityId: '',
			exclusiveOfferName: '',
			exclusiveOfferAddressLine1: '',
			exclusiveOfferAddressLine2: '',
			exclusiveOfferAddressTown: '',
			exclusiveOfferAddressCity: '',
			exclusiveOfferAddressCounty: '',
			exclusiveOfferAddressPostcode: '',
			exclusiveOfferAddressCoords: {longitude: 0.0, latitude: 0.0},
			exclusiveOfferTelephone: '',
			exclusiveOfferEmail: '',
			exclusiveOfferWebsite: '',
			exclusiveOfferDescription: '',
			exclusiveOfferInfoPoints: [],
			exclusiveOfferLogo: '',
			exclusiveOfferImages: [],
			exclusiveOfferCoverImage: '',
			exclusiveOfferCoverImageSmall: '',
			exclusiveOfferCoverImageMedium: '',
			exclusiveOfferCoverImageLarge: '',

			exclusiveOfferDate: '',
			exclusiveOfferTime: '',
			exclusiveOfferCost: '',
			exclusiveOfferSex: '',

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		}
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

		/**
		 * Computed Text Editor Class
		 *
		 * Return a class name for the Text Editor UI, based on error and readOnly states.
		 *
		 * @returns {string} the class name to render
		 */
		computedTextEditorClass() {
			const t = this
			let computedClass

			if (t.errors.exclusiveOfferDescription) computedClass = 'richText-error'

			if (!t.errors.exclusiveOfferDescription && t.$props.isReadOnly) computedClass = 'richText-disabled'

			if (!t.errors.exclusiveOfferDescription && !t.$props.isReadOnly) computedClass = 'richText-enabled'

			return computedClass
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		getAddressData(data) {
			const t = this

			t.form.exclusiveOfferAddressLine1 = data.addressData?.route
			t.form.exclusiveOfferAddressTown = data.addressData?.administrative_area_level_2
			t.form.exclusiveOfferAddressCity = data.addressData?.administrative_area_level_2
			t.form.exclusiveOfferAddressPostcode = data.addressData?.postal_code
			t.form.exclusiveOfferAddressCoords.longitude = data.addressData?.longitude
			t.form.exclusiveOfferAddressCoords.latitude = data.addressData?.latitude
			t.form.exclusiveOfferTelephone = data.places?.formatted_phone_number
			t.form.exclusiveOfferWebsite = data.places?.website
		},

		handleDateSelection(date) {
			const t = this

			t.form.exclusiveOfferDate = date
		},

		handleImageUpload(payload) {
			const t = this

			if (payload.sizeLabel === 'small') t.form.exclusiveOfferCoverImageSmall = payload.url
			if (payload.sizeLabel === 'medium') t.form.exclusiveOfferCoverImageMedium = payload.url
			if (payload.sizeLabel === 'large') t.form.exclusiveOfferCoverImageLarge = payload.url
		},

		handleTimeSelection(time) {
			const t = this

			t.form.exclusiveOfferTime = time
		},

		async save() {
			const t = this
			let response

			// Only save if the form has passed validation
			if (!t.validateForm()) return

			// Create
			if (!t.form?.entityId) {
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			if (!response.hasErrors) t.$emit('refreshPage')

		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Offer Name
			if (!t.form.exclusiveOfferName.trim()) {
				t.errors.exclusiveOfferName = true
				t.errors.exclusiveOfferNameErrorMessage = 'Name is required'
			}

			// Offer Telephone
			if (!t.form.exclusiveOfferTelephone.trim()) {
				t.errors.exclusiveOfferTelephone = true
				t.errors.exclusiveOfferTelephoneErrorMessage = 'Telephone is required'
			}

			// Offer Email
			if (!t.form.exclusiveOfferEmail.trim()) {
				t.errors.exclusiveOfferEmail = true
				t.errors.exclusiveOfferEmailErrorMessage = 'Email is required'
			}

			// Offer Website
			if (!t.form.exclusiveOfferWebsite.trim()) {
				t.errors.exclusiveOfferWebsite = true
				t.errors.exclusiveOfferWebsiteErrorMessage = 'Website is required'
			}

			// Organisation Description
			if (!t.form.exclusiveOfferDescription || t.form.exclusiveOfferDescription.replace(/\s/g, '') === '<p></p>') {
				t.errors.exclusiveOfferDescription = true
				t.errors.exclusiveOfferDescriptionErrorMessage = 'Description is required'
			}

			// Organisation Image
			if (!(t.form.exclusiveOfferCoverImageSmall || t.form.exclusiveOfferCoverImageMedium || t.form.exclusiveOfferCoverImageLarge)) {
				t.errors.exclusiveOfferImage = true
				t.errors.exclusiveOfferImageErrorMessage = 'Cover Image is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
.exOffForm-header-mobile {
	display: grid;
	grid-template-rows: auto auto;
}

.exOffForm-header-desktop {
	display: grid;
	grid-template-columns: auto 1fr;
}
</style>
