<template>
	<div class="pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon color="pink" icon="challenge" size="48"/>

			<!--Title-->
			<app-text class="ml-4" color="orange" size="large-bold">Challenges</app-text>

		</div>

		<!--About Text-->
		<app-text class="mt-4">
			Our ‘Challenges’ section allows you access to a directory of upcoming events in Wales to sign up to,
			in order to really ‘challenge’ yourself.
		</app-text>

		<v-divider class="mt-8"/>

		<!--Nothing to show message-->
		<app-text v-if="!challengesData.length" class="d-flex justify-center text-center mt-8">
			There are no Challenges at present, but check back soon!
		</app-text>

		<!--Cards-->
		<v-expansion-panels v-for="item in challengesData" :key="item.entityId" flat>
			<v-expansion-panel class="panel-container rounded-lg">

				<!--Date | Cost-->
				<div class="d-flex px-4 py-2">

					<!--Date-->
					<div class="d-flex align-center mr-4">
						<app-icon class="mr-2" color="orange" icon="calendar"/>
						<app-text>
							{{ MIX_formattedDate('full', item.challengeDate) }}
						</app-text>
					</div>

					<v-spacer/>

					<!--Cost-->
					<div class="d-flex align-center">
						<app-text class="mr-2" color="pink" size="large-bold">£</app-text>
						<app-text>
							{{ item.challengeCost === '0' ? 'Free' : `£${item.challengeCost}` }}
						</app-text>
					</div>

				</div>

				<!--Header-->
				<v-expansion-panel-header class="pa-0" hide-actions>

					<div class="card-container">

						<v-img :src="MIX_renderImageForScreenWidth(item.challengeCoverImageSmall, item.challengeCoverImageMedium, item.challengeCoverImageLarge)"
							   cover
							   class="rounded-lg"
							   style="aspect-ratio: 2/1"
							   max-width="600"/>

					</div>

				</v-expansion-panel-header>

				<!--Content-->
				<v-expansion-panel-content>
					<div class="pt-4">

						<!--Contact Links-->
						<div class="d-flex justify-space-around">
							<contact-icon-link @click.native="MIX_handleTelephoneLink(item.challengeTelephone)"
											   icon="phone"/>
							<contact-icon-link @click.native="MIX_handleWebsiteLink(item.challengeWebsite)"
											   icon="website"/>
							<contact-icon-link @click.native="MIX_handleEmailLink(item.challengeEmail)"
											   icon="email"/>
						</div>

						<!--Date | Time | Cost-->
						<div class="d-flex flex-column mt-8">

							<!--Date-->
							<div class="d-flex align-end">
								<app-text class="content-label" color="pink" size="small">Date:</app-text>
								<app-text>{{ MIX_formattedDate('full', item.challengeDate) }}</app-text>
							</div>

							<!--Time-->
							<div v-if="item.challengeTime" class="d-flex align-end">
								<app-text class="content-label" color="pink" size="small">Time:</app-text>
								<app-text>{{ item.challengeTime && item.challengeTime }}</app-text>
							</div>

							<!--Cost-->
							<div class="d-flex align-end">
								<app-text class="content-label" color="pink" size="small">Cost:</app-text>
								<app-text>
									{{ item.challengeCost === '0' ? 'Free' : `£${item.challengeCost}` }}
								</app-text>
							</div>

							<!--Sex-->
							<div class="d-flex align-end">
								<app-text class="content-label" color="pink" size="small">Sex:</app-text>
								<app-text>{{ item.challengeSex }}</app-text>
							</div>

							<!--Group Individual-->
							<div class="d-flex align-end">
								<app-text class="content-label" color="pink" size="small">Type:</app-text>
								<app-text>{{ item.challengeGroupIndividual }}</app-text>
							</div>

						</div>

						<!--Description-->
						<div class="d-flex justify-center mt-4">
							<app-text style="line-height: 1.25em; max-width: 60ch"
									  v-html="item.challengeDescription"/>
						</div>

					</div>
				</v-expansion-panel-content>

			</v-expansion-panel>
		</v-expansion-panels>

	</div>
</template>

<script>
export default {

	name: "Challenges",

	data: () => ({
		challengesData: [],
		entity: 'challenge',
	}),

	methods: {

		/**
		 * Get Entity Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getEntityData() {
			const t = this
			let response

			response = await t.MIX_redis_getAll(t.entity)

			if (!response.hasErrors) t.challengesData = response.data
		},

	},

	async created() {
		const t = this

		await t.getEntityData()
	}

}
</script>

<style scoped>
.panel-container {
	margin-top: 32px;
	max-width: 600px;
}

.card-container {
	width: calc(100vw - 32px);
}

.content-label {
	width: 48px;
}
</style>
