<template>
	<v-hover v-slot="{hover}">

		<app-icon class="component-container"
				  :color="hover ? 'white' : ''"
				  icon="close"
				  title="Close"/>

	</v-hover>
</template>

<script>
export default {
	name: "CloseIcon"
}
</script>

<style scoped>
.component-container {
	transition: 0.3s;
}

.component-container:hover {
	background: var(--v-pink-base);
	border-radius: 50%;
	padding: 8px;
	transition: 0.3s;
}
</style>
