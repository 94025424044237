<template>
	<div>

		<!--Day Times-->
		<div class="mt-4">

			<!--Day | Add-->
			<div class="d-flex align-center">
				<app-icon v-if="!disabled" @click.native="handleAdd" class="mr-4" color="orange" icon="add" size="32"/>
				<app-text color="grey" size="normal">{{ day }}</app-text>
			</div>

			<!--No Activities Message-->
			<app-text v-if="!activityTimes.length" class="ml-12" color="greyD">No times set</app-text>

			<!--Existing Times-->
			<div v-for="item in activityTimes" :key="item.startTime"
				 style="display: grid; grid-template-columns: 1fr 1fr 24px; grid-gap: 16px; align-items: center">

				<!--Start Time-->
				<time-picker class="red" :disabled="true"
							 field-label="Start"
							 :time="item.startTime"/>

				<!--Finish Time-->
				<time-picker :disabled="true"
							 field-label="Finish"
							 :time="item.finishTime"/>

				<!--Delete Icon-->
				<app-icon v-if="!disabled" @click.native="handleDelete(item)" icon="delete"/>

			</div>

		</div>

		<!--Times Dialog-->
		<v-dialog v-if="isTimeDialogVisible"
				  max-width="480"
				  v-model="isTimeDialogVisible">

			<v-card>

				<!--Toolbar-->
				<v-toolbar color="appGradient">
					<app-text color="white" size="medium">Change Times</app-text>
					<v-spacer/>
					<app-icon @click.native="closeTimeDialog" icon="close"/>
				</v-toolbar>

				<!--Body-->
				<div class="pa-4">

					<!--Info-->
					<app-text>You are about to add a new time for this activity</app-text>

					<!--Question-->
					<app-text class="mt-4" size="normal-bold">What are the new times?</app-text>

					<!--Pickers-->
					<div class="d-flex align-center mt-4">

						<!--Start-->
						<time-picker @emitTime="handleStartTimeSelection"
									 field-label="Start"
									 :time="startTime"/>

						<div class="mx-2"/>

						<!--Finish-->
						<time-picker @emitTime="handleFinishTimeSelection"
									 field-label="Finish"
									 :time="finishTime"/>

					</div>

					<!--Action Buttons-->
					<div class="d-flex justify-space-between mt-4">

						<!--Cancel Button-->
						<app-btn @click.native="closeTimeDialog"
								 color="grey"
								 icon="close"
								 label="Cancel"/>

						<!--Save Button-->
						<app-btn @click.native="handleSave"
								 color="green"
								 icon="check"
								 label="Save"/>

					</div>

				</div>

			</v-card>

		</v-dialog>

		<!--Delete Dialog-->
		<v-dialog v-if="isDeleteDialogVisible"
				  max-width="480"
				  v-model="isDeleteDialogVisible">

			<v-card>

				<!--Toolbar-->
				<v-toolbar color="appGradient">
					<app-text color="white" size="medium">Delete Times</app-text>
					<v-spacer/>
					<app-icon @click.native="closeDeleteDialog" icon="close"/>
				</v-toolbar>

				<!--Body-->
				<div class="pa-4">

					<app-text size="normal-bold">Are you sure you want to delete these times?</app-text>

					<!--Action Buttons-->
					<div class="d-flex justify-space-between mt-4">

						<!--Cancel Button-->
						<app-btn @click.native="closeDeleteDialog"
								 color="grey"
								 icon="close"
								 label="Cancel"/>

						<!--Delete Button-->
						<app-btn @click.native="deleteTime"
								 color="red"
								 icon="delete"
								 label="Delete"/>

					</div>

				</div>

			</v-card>

		</v-dialog>

	</div>
</template>

<script>
export default {

	name: "ActivityTimes",

	props: ['activity', 'activityTimes', 'day', 'disabled', 'entityId', 'tempOrganisation'],

	data: () => ({
		finishTime: '',
		isDeleteDialogVisible: false,
		isTimeDialogVisible: false,
		startTime: '',
		timesToDelete: {},
	}),

	methods: {

		/**
		 * Close Delete Dialog
		 */
		closeDeleteDialog() {
			const t = this

			t.isDeleteDialogVisible = false
		},

		/**
		 * Close Time Dialog
		 */
		closeTimeDialog() {
			const t = this

			t.isTimeDialogVisible = false
		},

		/**
		 * Delete Time
		 *
		 * Remove the time object from the current day's activity times,
		 * and set the current new times to the day's form field.
		 * Call to save the updated activity.
		 */
		async deleteTime() {
			const t = this
			const ACTIVITY = t.$props.activity
			const ACTIVITY_TIMES = t.$props.activityTimes
			const DAY = t.$props.day
			const NEW_ACTIVITY_TIMES = ACTIVITY_TIMES.filter(times => times !== t.timesToDelete)

			if (DAY === 'Monday') ACTIVITY.activityMondayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)
			if (DAY === 'Tuesday') ACTIVITY.activityTuesdayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)
			if (DAY === 'Wednesday') ACTIVITY.activityWednesdayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)
			if (DAY === 'Thursday') ACTIVITY.activityThursdayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)
			if (DAY === 'Friday') ACTIVITY.activityFridayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)
			if (DAY === 'Saturday') ACTIVITY.activitySaturdayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)
			if (DAY === 'Sunday') ACTIVITY.activitySundayTimes = JSON.stringify(NEW_ACTIVITY_TIMES)

			await t.save(ACTIVITY)
		},

		/**
		 * Handle Add
		 *
		 * Call to open the time dialog to enter a new time.
		 */
		handleAdd() {
			const t = this

			t.openTimeDialog()
		},

		/**
		 * Handle Delete
		 *
		 * Open the DeleteDialog, and store the time object to delete.
		 *
		 * @param times the time object to delete
		 */
		handleDelete(times) {
			const t = this

			t.isDeleteDialogVisible = true
			t.timesToDelete = times
		},

		/**
		 * Handle Finish Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 */
		handleFinishTimeSelection(time) {
			const t = this

			t.finishTime = time
		},

		/**
		 * Handle Save
		 *
		 * Assign the new start and finish times to a newTimes object, and push to the activity times array.
		 * As the DB field is a string, stringify the activity times and assign it to the form's current day.
		 */
		async handleSave() {
			const t = this
			const ACTIVITY = t.$props.activity
			const ACTIVITY_TIMES = t.$props.activityTimes
			const DAY = t.$props.day
			let newTimes = {}

			newTimes.startTime = t.startTime
			newTimes.finishTime = t.finishTime

			ACTIVITY_TIMES.push(newTimes)

			if (DAY === 'Monday') ACTIVITY.activityMondayTimes = JSON.stringify(ACTIVITY_TIMES)
			if (DAY === 'Tuesday') ACTIVITY.activityTuesdayTimes = JSON.stringify(ACTIVITY_TIMES)
			if (DAY === 'Wednesday') ACTIVITY.activityWednesdayTimes = JSON.stringify(ACTIVITY_TIMES)
			if (DAY === 'Thursday') ACTIVITY.activityThursdayTimes = JSON.stringify(ACTIVITY_TIMES)
			if (DAY === 'Friday') ACTIVITY.activityFridayTimes = JSON.stringify(ACTIVITY_TIMES)
			if (DAY === 'Saturday') ACTIVITY.activitySaturdayTimes = JSON.stringify(ACTIVITY_TIMES)
			if (DAY === 'Sunday') ACTIVITY.activitySundayTimes = JSON.stringify(ACTIVITY_TIMES)

			await t.save(ACTIVITY)
		},

		/**
		 * Handle Start Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 */
		handleStartTimeSelection(time) {
			const t = this

			t.startTime = time
		},

		/**
		 * Open Time Dialog
		 */
		openTimeDialog() {
			const t = this

			t.isTimeDialogVisible = true
		},

		/**
		 * Save
		 *
		 * Convert the required field to their storable types, and update the Redis Activity Key.
		 * If there are no Redis errors, reset the form, close all dialogs,
		 * and emit the Activity back to the parent to update the UI.
		 *
		 * @param activity the activity data to update
		 */
		async save(activity) {
			const t = this

			// Convert the Yes/No text to a boolean
			activity.activityIsIncludedInMembership = activity.activityIsIncludedInMembership === 'Yes'

			// Convert Organisation to ID
			activity.organisation = t.$props.tempOrganisation?.entityId

			const RESPONSE = await t.MIX_redis_update('activity', activity.entityId, activity)

			if (!RESPONSE.hasErrors) {
				t.startTime = ''
				t.finishTime = ''
				t.timesToDelete = {}
				t.closeTimeDialog()
				t.closeDeleteDialog()
				t.$emit('emitNewTimes', activity)
			}

		},

	}
}
</script>

<style scoped>

</style>
