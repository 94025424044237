<template>
	<div class="d-flex align-center">

		<vue-google-autocomplete @placechanged="emitData"
								 id="map"
								 classname="form-control"
								 :country="'gb'"
								 :disabled="disabled"
								 :fields="fields"
								 :placeholder="placeholder || 'Address search...'"
								 style="background: white;
								 		border: 1px solid var(--v-pink-base);
								 		border-radius: 8px;
								 		padding: 16px 8px;
								 		width: 100%"
								 :types="(types)"
								 :value="formattedAddress ? formattedAddress : null"/>

	</div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {

	name: "GoogleAutocomplete",

	props: [
		'disabled',
		'formattedAddress',
		'placeholder', // Defaults to 'Address search...'
		'types', // Defaults to 'address' // Choose from 'address', 'geocode' (for cities), 'establishment'
	],

	components: {VueGoogleAutocomplete},

	data: () => ({
		fields: ['address_components',
			'adr_address',
			'alt_id',
			'formatted_address',
			'geometry',
			'icon',
			'id',
			'name',
			'permanently_closed',
			'photo',
			'place_id',
			'scope',
			'type',
			'url',
			'utc_offset',
			'vicinity',
			'formatted_phone_number',
			'website'
		]
	}),

	methods: {

		emitData(addressData, places) {
			const t = this

			t.$emit('placechanged', {addressData, places})
		}

	}

}
</script>

<style scoped>

</style>
