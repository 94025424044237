<template>
	<v-card :class="viewType === 'Admin' ? 'appBackground' : 'transparent'" flat>

		{{ computedInit }}

		<!--Name-->
		<app-input input-type="textInput"
				   :disabled="isReadOnly"
				   :error="errors.userName"
				   :error-message="errors.userNameErrorMessage"
				   label="Name"
				   v-model.trim="form.userName"/>

		<!--Email-->
		<app-input input-type="textInput"
				   class="mt-8"
				   :disabled="true"
				   label="Email"
				   v-model.trim="form.userEmail"/>

		<!--Date of Birth-->
		<div class="mt-8">
			<date-picker @emitDate="handleDateSelection"
						 :date="form.userDateOfBirth"
						 :disabled="isReadOnly"
						 :error="errors.userDateOfBirth"
						 :error-message="errors.userDateOfBirthErrorMessage"
						 label="Date of Birth"
						 :min-date="[100, 'years', 'past']"/>
		</div>

		<!--Gender-->
		<div class="mt-8">

			<!--Label-->
			<app-text class="mb-2" color="grey9" size="normal-bold">Gender</app-text>

			<!--Buttons-->
			<v-btn-toggle @change="handleGenderSelection"
						  background-color="transparent"
						  class="d-flex justify-center"
						  v-model="form.userGender">

				<!--Male-->
				<form-btn :active="form.userGender === 'Male'"
						  :disabled="isReadOnly" label="Male" value="Male"/>

				<!--Female-->
				<form-btn :active="form.userGender === 'Female'"
						  class="mx-4" :disabled="isReadOnly" label="Female" value="Female"/>

				<!--Other-->
				<form-btn :active="form.userGender === 'Other'"
						  :disabled="isReadOnly" label="Other" value="Other"/>

			</v-btn-toggle>

			<!--Other (optional)-->
			<app-input v-if="form.userGender === 'Other'"
					   input-type="textInput"
					   class="mt-2"
					   :disabled="isReadOnly"
					   placeholder="Please specify (optional)"
					   v-model.trim="form.userGenderDescriptionText"/>

			<!--Checkbox-->
			<div @click="handleGenderCheckbox"
				 class="d-flex align-center mt-2"
				 style="width: fit-content">

				<div class="userForm-checkbox-container mr-4">

					<app-icon color="green"
							  :icon="form.userIsGenderCheckboxChecked && 'check'"
							  size="24"/>

				</div>

				<app-text color="grey" size="small">I'd rather not say</app-text>

			</div>

			<!--Error Message-->
			<app-text v-if="errors.userGender" class="ml-4 mt-2" color="red" size="small">
				{{ errors.userGenderErrorMessage }}
			</app-text>

		</div>

		<!--Disabilities-->
		<div class="mt-8">

			<!--Label-->
			<app-text class="mb-2" color="grey9" size="normal-bold">
				Do you consider yourself to have a disability or impairment?
			</app-text>

			<!--Disability / Impairment Options-->
			<app-input input-type="select"
					   :disabled="isReadOnly"
					   :error="errors.userDisabilityOrImpairment"
					   :error-message="errors.userDisabilityOrImpairmentErrorMessage"
					   :items="disabilityImpairmentOptions"
					   v-model="form.userDisabilityOrImpairment"/>

			<!--Other (optional)-->
			<app-input v-if="form.userDisabilityOrImpairment === 'Other (please identify)'"
					   input-type="textInput"
					   class="mt-2"
					   placeholder="Please specify (optional)"
					   v-model.trim="form.userDisabilityDescriptionText"/>

		</div>

		<!--Admin Section-->
		<!--Admin only-->
		<div v-if="$route.name === 'AdminConsole' && userLevel === 'Staff-Admin'">

			<app-text class="text-center mt-8" color="grey" size="medium">Admin</app-text>

			<v-divider class="mt-2"/>

			<!--User Level | User Status-->
			<v-row no-gutters>

				<!--User Level-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
					<app-input input-type="select"
							   :disabled="isReadOnly"
							   :items="userLevelOptions"
							   label="User Level"
							   v-model.trim="form.userLevel"/>
				</v-col>

				<!--User Status-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
					<app-input input-type="select"
							   :disabled="isReadOnly"
							   :items="userStatusOptions"
							   label="User Status"
							   v-model.trim="form.userStatus"/>
				</v-col>

			</v-row>

		</div>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block=true
				 class="mt-8"
				 color=green
				 :disabled="isReadOnly"
				 label="Save"/>

		<confirmation-animation v-if="isConfirmationVisible"/>

	</v-card>
</template>

<script>
export default {

	name: "UsersForm",

	props: ['formData', 'isReadOnly', 'userLevel', 'viewType'],

	data: () => ({
		entity: 'user',
		errors: {
			userDateOfBirth: false,
			userDateOfBirthErrorMessage: '',
			userDisabilityOrImpairment: false,
			userDisabilityOrImpairmentErrorMessage: '',
			userGender: false,
			userGenderErrorMessage: '',
			userName: false,
			userNameErrorMessage: '',
		},
		form: {
			entityId: '',
			userFbId: '',
			userDateOfBirth: '',
			userDisabilityDescriptionText: '',
			userDisabilityOrImpairment: '',
			userEmail: '',
			userGender: '',
			userGenderDescriptionText: '',
			userIsGenderCheckboxChecked: false,
			userLevel: '',
			userName: '',
			userStatus: '',
			userIsProfileComplete: false,
			userIsSearchPreferencesComplete: false,

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		},
		isConfirmationVisible: false,
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Date Selection
		 *
		 * Take the emitted date and save it to the state.
		 *
		 * @param date
		 */
		handleDateSelection(date) {
			const t = this

			t.form.userDateOfBirth = date
		},

		/**
		 * Handle Gender Selection
		 *
		 * When a selection is made, clear the description and uncheck the checkbox.
		 */
		handleGenderSelection() {
			const t = this

			t.form.userGenderDescriptionText = ''
			t.form.userIsGenderCheckboxChecked = false
		},

		/**
		 * Handle Gender Checkbox
		 *
		 * Toggle the checkbox, clear the description text, and clear the button selection.
		 */
		handleGenderCheckbox() {
			const t = this

			if (t.isReadOnly) return

			t.form.userIsGenderCheckboxChecked = !t.form.userIsGenderCheckboxChecked

			t.form.userGenderDescriptionText = ''
			t.form.userGender = ''
		},

		/**
		 * Save
		 *
		 * Update the user's data in Redis and Firebase.
		 * Redis hold the user's data, and Firebase hold the basic auth data & claims.
		 *
		 * @returns {Promise<void>}
		 */
		async save() {
			const t = this

			// Only save if form has passed validation
			if (!t.validateForm()) return

			t.form.userIsProfileComplete = true

			// Updated Redis
			const RESPONSE = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating user data: ', RESPONSE.error)
				return
			}

			// Updated Firebase
			const USER_RESPONSE = await t.MIX_firestore_updateUserDocument('users', t.form)

			// Handle any errors
			if (USER_RESPONSE.hasErrors) {
				console.error('Error updating FB user data: ', USER_RESPONSE.error)
				return
			}

			t.showConfirmationAnimation()
			t.$emit('refreshPage')
		},

		/**
		 * Show Confirmation Animation
		 *
		 * Toggle the ConfirmationAnimation component on for 2 seconds, and off.
		 */
		showConfirmationAnimation() {
			const t = this

			t.isConfirmationVisible = true

			setTimeout(() => {
				t.isConfirmationVisible = false
			}, 2000)
		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Name
			if (!t.form.userName.trim()) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Full Name is required'
			}

			// Date Of Birth
			if (!t.form.userDateOfBirth) {
				t.errors.userDateOfBirth = true
				t.errors.userDateOfBirthErrorMessage = 'Date of Birth is required'
			}

			// Disability or Impairment
			if (!t.form.userDisabilityOrImpairment) {
				t.errors.userDisabilityOrImpairment = true
				t.errors.userDisabilityOrImpairmentErrorMessage = 'Disability is required'
			}

			// Gender
			if (!(t.form.userGender || t.form.userIsGenderCheckboxChecked)) {
				t.errors.userGender = true
				t.errors.userGenderErrorMessage = 'A selection is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	watch: {

		'form.userDisabilityOrImpairment'() {
			const t = this

			// Clear the description text when a different selection is made
			t.form.userDisabilityDescriptionText = ''
		}

	}

}
</script>

<style scoped>
.userForm-checkbox-container {
	display: flex;
	align-items: center;
	justify-content: center;

	aspect-ratio: 1/1;
	background-color: white;
	border: 1px solid var(--v-grey9-base);
	border-radius: 8px;
	width: 28px;
}
</style>
