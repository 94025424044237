<template>
	<v-app id="app">

		<!--Background for Auth pages-->
		<div v-if="['Auth'].includes($route.name)"
			 style="display:flex; align-items: center; justify-content: center; position:fixed;">
			<v-img :src="require('@/assets/images/auth-bg-1.svg')" cover
				   style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 1"/>
			<v-img :src="require('@/assets/images/auth-bg-2.svg')" cover
				   style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 2"/>
			<v-img :src="require('@/assets/images/auth-bg-3.svg')" cover
				   style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 3"/>
		</div>

		<!--Background for App pages-->
		<div v-if="!['Auth'].includes($route.name)"
			 style="display:flex; align-items: center; justify-content: center; position:fixed; width: 100vw">
			<v-img :src="require('@/assets/images/auth-bg-2-light.svg')" cover
				   style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 1"/>
		</div>

		<!--Top App Bar-->
		<app-bar v-if="!['Auth'].includes($route.name)"
				 v-on:emitOpenNavigationDrawer="isNavigationOpen = true"/>

		<!--Vertical Navigation-->
		<v-navigation-drawer app
							 fixed
							 right
							 :src="require('@/assets/images/auth-bg-2-light.svg')"
							 temporary
							 v-model="isNavigationOpen"
							 width="304">

			<navigation-drawer-items/>

		</v-navigation-drawer>

		<!--Page Content-->
		<v-main class="appBackground" style="display: flex; justify-content: center; width: 100%">
			<div style="margin: 0 auto" :style="!['AdminConsole'].includes($route.name) && 'max-width: 960px'">
				<transition name="custom-classes-transition"
							enter-active-class="animate__animated animate__fadeIn animate__faster"
							leave-active-class="animate__animated animate__fadeOut animate__faster"
							mode="out-in">
					<router-view/>
				</transition>
			</div>
		</v-main>

		<app-update-banner/>

	</v-app>
</template>

<script>
import AppBar from "@/components/app/AppBar";
import AppUpdateBanner from "@/components/AppUpdateBanner";
import NavigationDrawerItems from "@/components/NavigationDrawerItems";
import {version as appVersionNumber} from '../package.json'

export default {

	name: 'App',

	components: {AppBar, AppUpdateBanner, NavigationDrawerItems},

	data: () => ({
		LATEST_VERSION: appVersionNumber,
		isNavigationOpen: false,
	}),

	mounted() {
		const t = this

		console.log('App version: v', t.LATEST_VERSION)
	}

};
</script>

<style>
#app {
	font-family: 'Montserrat', Verdana, sans-serif;
}

.appGradient {
	background: linear-gradient(90deg, var(--v-orange-base), var(--v-pink-base));
}

.appGradientOutline {
	background: linear-gradient(white, white) padding-box, linear-gradient(90deg, var(--v-orange-base), var(--v-pink-base)) border-box;
	border: 1px solid transparent;
}

.imageBlur {
	aspect-ratio: 2/1;
	filter: blur(1px);
	margin: 0 auto;
	opacity: 0.25;
	position: absolute;
	inset: 0;
}

.shadow {
	box-shadow: 0 0 8px var(--v-grey6-base);
}

/* * * * Rich Text Editor Styles * * * */
.richText-disabled .ql-toolbar {
	border: 1px solid #aaqaaaa !important;
	border-radius: 8px 8px 0 0;
}

.richText-enabled .ql-toolbar {
	border: 1px solid var(--v-grey9-base) !important;
	border-radius: 8px 8px 0 0;
}

.richText-error .ql-toolbar {
	border: 1px solid var(--v-red-base) !important;
	border-radius: 8px 8px 0 0;
}

.richText-disabled .ql-container {
	border: 1px solid #aaqaaaa !important;
	border-radius: 0 0 8px 8px;
}

.richText-enabled .ql-container {
	border: 1px solid var(--v-grey9-base) !important;
	border-radius: 0 0 8px 8px;
}

.richText-error .ql-container {
	border: 1px solid var(--v-red-base) !important;
	border-radius: 0 0 8px 8px;
}

/* * * * SCROLLBAR * * * */
.hideScrollbar {
	background: white;
	border-radius: 8px;
	overflow-y: scroll;
	position: relative;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.hideScrollbar::-webkit-scrollbar {
	display: none;
}
</style>
