<template>
	<div class="auth">

		<!--Logo | Buttons | Form-->
		<div>

			<!--Company Logo-->
			<div
				class="d-flex flex-column align-center pt-12 animate__animated animate__heartBeat animate__slow animate__delay-1s">

				<div class="d-flex flex-column pa-4" style="width: 75%; max-width: 800px">

					<v-img :src="require('@/assets/images/company-logo.svg')" width="100%"/>

					<app-text color="white" style="letter-spacing: 2px">Search, Save, Get Fit</app-text>

				</div>

			</div>

			<!--Login | Register-->
			<div class="d-flex">

				<!--Login Button-->
				<div @click="selectedForm = 'Login'"
					 class="authButton loginButton mt-16"
					 :class="selectedForm === 'Login' && 'activeButton shadow'">

					<app-text color="white" size="large">Login</app-text>

				</div>

				<v-spacer/>

				<!--Register Button-->
				<div @click="selectedForm = 'Register'"
					 class="authButton registerButton mt-16"
					 :class="selectedForm === 'Register' && 'activeButton shadow'">

					<app-text color="white" size="large">Register</app-text>

				</div>

			</div>

			<login v-if="selectedForm === 'Login'"
				   class="animate__animated animate__bounceInLeft animate__fast"
				   @emitAuthError="handleAuthError"
				   @emitHandleSelectedForm="emittedHandleSelectedForm"/>

			<register v-if="selectedForm === 'Register'"
					  class="animate__animated animate__bounceInRight animate__fast"
					  @emitAuthError="handleAuthError"/>

			<password-reset v-if="selectedForm === 'ResetPassword'"
							class="animate__animated animate__bounceInLeft animate__fast"
							@emitAuthError="handleAuthError"
							@emitHandleSelectedForm="emittedHandleSelectedForm"/>

		</div>

		<!--Socials | Bottom Row-->
		<div>

			<!--Social Accounts-->
			<div class="d-flex flex-column align-center mt-4" style="width: 100%">

				<!--Title-->
				<app-text color="white">Use your social account</app-text>

				<!--Icons-->
				<div class="d-flex shadow white rounded-lg mt-4 px-4 py-2">

					<v-img @click.native="handleRegisterWithGoogle"
						   :src="require('@/assets/images/logo-google.svg')" width="40"/>
					<v-img @click.native="handleRegisterWithFacebook"
						   :src="require('@/assets/images/logo-facebook.svg')" class="mx-8" width="40"/>
					<v-img @click.native="handleRegisterWithTwitter"
						   :src="require('@/assets/images/logo-twitter.svg')" width="40"/>

				</div>

			</div>

			<!--Legal Links || Version Number-->
			<div class="d-flex justify-space-between mt-8 pa-4" style="width: 100%">

				<app-text @click.native="MIX_handleWebsiteLink('https://fitap.co.uk/privacy-policy/')" color="white">
					Privacy Policy
				</app-text>

				<!--App Version Number-->
				<app-text color="white">v{{ version }}</app-text>

				<app-text @click.native="MIX_handleWebsiteLink('https://fitap.co.uk/terms-and-conditions/')"
						  color="white">Terms & Conditions
				</app-text>

			</div>

		</div>

		<!--Error Dialog-->
		<v-dialog max-width="512"
				  style="position:relative;z-index: 999"
				  v-model="isAuthErrorDialogVisible">

			<v-card class="pa-4">

				<app-text class="text-center">{{ displayAuthErrorMessage(authError) }}</app-text>

				<div class="d-flex justify-center mt-4">
					<app-btn @click.native="isAuthErrorDialogVisible = false" color="green" icon="check" label="Ok"/>
				</div>

			</v-card>

		</v-dialog>

		<!--Error Dialog-->
		<v-dialog max-width="512"
				  style="position:relative; z-index: 999"
				  v-model="isAccountInUseDialogVisible">

			<v-card class="rounded-lg pa-4">

				<!--Information-->
				<app-text class="text-center">
					An account with this email address already exists.
					<br>
					<br>
					Please log in with the method you registered with.
				</app-text>

				<!--Ok Button-->
				<app-btn @click.native="isAccountInUseDialogVisible = false"
						 :block="true" class="mt-4" color="appGradient" label="Ok"/>

			</v-card>

		</v-dialog>

		<!--Link Provider Dialog-->
		<v-dialog max-width="512"
				  style="position:relative;z-index: 999"
				  v-model="isContinueDialogVisible">

			<v-card class="pa-4">

				<app-text>
					You have previously registered with another provider,
					but we can link your accounts to also allow you to sign in with this with this one.
				</app-text>

				<div class="d-flex justify-center mt-4">
					<app-btn @click.native="handleLinkingError" color="green" icon="check" label="Ok"/>
				</div>

			</v-card>

		</v-dialog>

	</div>
</template>

<script>
import {version} from '../../../package.json'
import Login from "@/views/auth/login/Login";
import PasswordReset from "@/views/auth/resetPassword/ResetPassword";
import Register from "@/views/auth/register/Register";
import login from "@/views/auth/login/Login.vue";
import {getAuth} from "firebase/auth";

export default {

	name: "Auth",

	components: {
		Login,
		PasswordReset,
		Register
	},

	data: () => ({
		authError: '',
		isAuthErrorDialogVisible: false,
		selectedForm: 'Login',
		version,
		isAccountInUseDialogVisible: false,

		// Old

		isContinueDialogVisible: false,
		authCredential: null,
	}),

	methods: {

		/**
		 * Handle Auth Error
		 *
		 * Get the emitted auth error from a component, set it, and open the error dialog.
		 *
		 * @param payload the auth error payload object
		 */
		handleAuthError(payload) {
			const t = this

			t.authError = payload.errorCode
			t.isAuthErrorDialogVisible = true
		},

		/**
		 * Auth Error Message
		 *
		 * Return a readable error message based on the Firebase error code.
		 *
		 * @param error the Firebase error code
		 * @returns {string} a readable error message
		 */
		displayAuthErrorMessage(error) {
			let errorMessage = 'Sorry, an unexpected error has occurred'

			if (error === 'auth/invalid-email') errorMessage = 'Your email is not in a valid format.'
			if (error === 'auth/email-already-in-use') errorMessage = 'There is already a Fitap account using this email address.'
			if (error === 'auth/user-not-found') errorMessage = 'No account has been found for this email address.'
			if (error === 'auth/wrong-password') errorMessage = 'Either the email or password you have entered is wrong.'
			if (error === 'auth/account-exists-with-different-credential') errorMessage = 'An account already exists with the same email address but different sign-in credentials. This may be because you\'ve previously created an account with a different sign-in method.'

			return errorMessage
		},

		/**
		 * Emitted Handle Selected Form
		 *
		 * Take the emitted form name message, and update which form should be displayed.
		 * This is mainly for the 'Forgot Your Password' form as it's part of the Login form.
		 *
		 * @param form the name of the form to display
		 */
		emittedHandleSelectedForm(form) {
			const t = this

			t.selectedForm = form.name
		},

		/**
		 * Handle Register With Facebook
		 *
		 * Attempt to create an account with Facebook auth.
		 * If there is no account, it will be created.
		 * If there is already an account, an error will be returned triggering a dialog advising the user to sign in with their registered method.
		 *
		 * @returns {Promise<void>}
		 */
		async handleRegisterWithFacebook() {
			const t = this

			const RESPONSE = await t.MIX_auth_register_facebook()

			if (RESPONSE.hasErrors) {
				console.error('Error registering with Facebook: ', RESPONSE.error)
				if (RESPONSE.error.code === 'auth/account-exists-with-different-credential') t.isAccountInUseDialogVisible = true
				else t.handleAuthError('')
				return
			}

			t.MIX_go('/')
		},

		/**
		 * Handle Register With Google
		 *
		 * Attempt to create an account with Google auth.
		 * If there is no account, it will be created.
		 * If there is already an account, an error will be returned triggering a dialog advising the user to sign in with their registered method.
		 *
		 * NB... Firebase treats Google is a trusted provider, and will override Facebook and Twitter if is registered after them.
		 * @link https://firebase.google.com/docs/auth/users#verified_email_addresses
		 *
		 * @returns {Promise<void>}
		 */
		async handleRegisterWithGoogle() {
			const t = this

			const RESPONSE = await t.MIX_auth_register_google()

			if (RESPONSE.hasErrors) {
				console.error('Error registering with Google: ', RESPONSE.error)
				if (RESPONSE.error.code === 'auth/account-exists-with-different-credential') t.isAccountInUseDialogVisible = true
				else t.handleAuthError('')
				return
			}

			t.MIX_go('/')
		},

		/**
		 * Handle Register With Twitter
		 *
		 * Attempt to create an account with Twitter auth.
		 * If there is no account, it will be created.
		 * If there is already an account, an error will be returned triggering a dialog advising the user to sign in with their registered method.
		 *
		 * @returns {Promise<void>}
		 */
		async handleRegisterWithTwitter() {
			const t = this

			const RESPONSE = await t.MIX_auth_register_twitter()

			if (RESPONSE.hasErrors) {
				console.error('Error registering with Twitter: ', RESPONSE.error)
				if (RESPONSE.error.code === 'auth/account-exists-with-different-credential') t.isAccountInUseDialogVisible = true
				else t.handleAuthError('')
				return
			}

			t.MIX_go('/')
		},

		// -------------------------------------------------------------------------------------------------------------

		/**
		 * BELOW - NOT IN USE
		 * The functions below were created to be able to register and link multiple social accounts together,
		 * but the app is only allowing a single auth method.
		 * They are all the same:
		 *  - If the user hasn't registered, it will activate the popup where they can sign in and authorise to create them an account
		 *  - If the user has previously registered with a social provider, it will return back to Auth.vue so the user can accept they want to link accounts.
		 *    Upon confirmation, MIX_auth_continue() will be called to sign them in and link the social accounts.
		 * NB... This does not handle if the use has previously signed up with an email/password, and then was to also use a social provider.
		 * This needs to return back to Auth.vue and ask the user for their password, then return here and complete the process.
		 */

		async not_in_use__handleRegisterWithFacebook() {
			const t = this

			const RESPONSE = await t.MIX_auth_register_facebook()

			if (RESPONSE.hasErrors) {
				console.error('Error registering with Facebook: ', RESPONSE.error)
				t.authCredential = RESPONSE.data
				t.isContinueDialogVisible = true
				return
			}

			t.MIX_go('/')
		},

		async not_in_use__handleRegisterWithGoogle() {
			const t = this

			const RESPONSE = await t.MIX_auth_register_google()

			if (RESPONSE.hasErrors) {
				console.error('Error registering with Google: ', RESPONSE.error)
				t.authCredential = RESPONSE.data
				t.isContinueDialogVisible = true
				return
			}

			t.MIX_go('/')
		},

		async not_in_use__handleRegisterWithTwitter() {
			const t = this

			const RESPONSE = await t.MIX_auth_register_twitter()

			if (RESPONSE.hasErrors) {
				console.error('Error registering with Twitter: ', RESPONSE.error)
				t.authCredential = RESPONSE.data
				t.isContinueDialogVisible = true
				return
			}

			t.MIX_go('/')
		},

		async not_in_use__handleLinkingError() {
			const t = this

			const RESPONSE = await t.MIX_auth_continue(t.authCredential)

			if (RESPONSE.hasErrors) {
				console.error('Error linking accounts: ', RESPONSE.error)
				return
			}

			t.isContinueDialogVisible = false
			t.MIX_go('/')
		},

	},

}
</script>

<style scoped>
.auth {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	min-height: 100vh;
}

.authButton {
	display: flex;
	align-items: center;
	justify-content: center;

	border: 2px solid white;
	padding: 16px;
	width: 40%;
}

.loginButton {
	background: var(--v-pink-base);
	border-radius: 0 16px 16px 0;
	opacity: 0.5;
}

.registerButton {
	background: var(--v-orange-base);
	border-radius: 16px 0 0 16px;
	opacity: 0.5;
}

.activeButton {
	opacity: 1;
}

@media only screen and (min-width: 960px) {
	.loginButton {
		background: var(--v-pink-base);
		border-radius: 16px 16px 16px 16px;
		opacity: 0.5;
	}

	.registerButton {
		background: var(--v-orange-base);
		border-radius: 16px 16px 16px 16px;
		opacity: 0.5;
	}

	.activeButton {
		opacity: 1;
	}
}

</style>
