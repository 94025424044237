<!--
App Icon

Add all icons here for central management.
Icons take props for:
 - color (the colour for the icon)
 - icon - (the given name for the icon - not the icons8 name)
 - size - (the size for the icon)
-->
<template>
	<div>
		<div v-for="item in icons" :key="item.name+item.reference">

			<v-icon v-if="icon === item.name"
					:class="item.reference"
					:disabled="disabled"
					:color="color"
					:size="size"/>

		</div>
	</div>
</template>

<script>
export default {

    name: "AppIcon",

    props: ['color', 'disabled', 'icon', 'size'],

	data: () => ({
		icons: [
			{name: 'add', reference: 'icons8-add'},
			{name: 'admin', reference: 'icons8-admin-settings-male'},
			{name: 'backArrow', reference: 'icons8-back'},
			{name: 'calendar', reference: 'icons8-calendar'},
			{name: 'challenge', reference: 'icons8-goal'},
			{name: 'check', reference: 'icons8-done'},
			{name: 'close', reference: 'icons8-close'},
			{name: 'confirmPassword', reference: 'icons8-unlock'},
			{name: 'delete', reference: 'icons8-delete'},
			{name: 'edit', reference: 'icons8-edit'},
			{name: 'email', reference: 'icons8-gmail-logo'},
			{name: 'forwardArrow', reference: 'icons8-forward'},
			{name: 'favoriteEmpty', reference: 'icons8-favorite'},
			{name: 'favoriteFilled', reference: 'icons8-heart'},
			{name: 'feedback', reference: 'icons8-feedback'},
			{name: 'filter', reference: 'icons8-slider'},
			{name: 'home', reference: 'icons8-home-page'},
			{name: 'image', reference: 'icons8-edit-image'},
			{name: 'info', reference: 'icons8-info'},
			{name: 'locationPin', reference: 'icons8-location'},
			{name: 'map', reference: 'icons8-map-marker'},
			{name: 'mentalHealth', reference: 'icons8-confusion'},
			{name: 'menu', reference: 'icons8-menu'},
			{name: 'newCopy', reference: 'icons8-new-copy'},
			{name: 'offers', reference: 'icons8-gift'},
			{name: 'password', reference: 'icons8-lock'},
			{name: 'phone', reference: 'icons8-phone'},
			{name: 'profile', reference: 'icons8-user'},
			{name: 'save', reference: 'icons8-save'},
			{name: 'search', reference: 'icons8-search'},
			{name: 'searchPreferences', reference: 'icons8-search-2'},
			{name: 'settings', reference: 'icons8-settings'},
			{name: 'thumbsUp', reference: 'icons8-thumbs-up'},
			{name: 'time', reference: 'icons8-time'},
			{name: 'upArrow', reference: 'icons8-collapse-arrow'},
			{name: 'upload', reference: 'icons8-upload-to-the-cloud'},
			{name: 'website', reference: 'icons8-website'},
			// Categories
			{name: 'cardio', reference: 'icons8-heart-with-pulse'},
			{name: 'children', reference: 'icons8-children'},
			{name: 'cyclingSpin', reference: 'icons8-spinning'},
			{name: 'dance', reference: 'icons8-dancing'},
			{name: 'golf', reference: 'icons8-golf'},
			{name: 'gyms', reference: 'icons8-ios-glyph'},
			{name: 'healthClubs', reference: 'icons8-sauna'},
			{name: 'martialArts', reference: 'icons8-taekwondo'},
			{name: 'nutrition', reference: 'icons8-nutrition'},
			{name: 'prePostNatal', reference: 'icons8-pregnant'},
			{name: 'personalTraining', reference: 'icons8-personal-trainer'},
			{name: 'physio', reference: 'icons8-physical-therapy'},
			{name: 'racketSports', reference: 'icons8-tennis-player'},
			{name: 'sports', reference: 'icons8-sports'},
			{name: 'snowSports', reference: 'icons8-snowboarding'},
			{name: 'strength', reference: 'icons8-muscle'},
			{name: 'swimming', reference: 'icons8-swimmer'},
			{name: 'wellbeing', reference: 'icons8-meditate'},
			{name: 'waterSports', reference: 'icons8-sup'},
		],
	}),

}
</script>

<style scoped>

</style>
