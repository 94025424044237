<template>
	<v-form class="appBackground pa-4">

		{{ computedInit }}

		<!--Image | Upload Button | Name | Organisation-->
		<div :class="$vuetify.breakpoint.width < 800 ? 'actForm-header-mobile' : 'actForm-header-desktop'">

			<!--Image-->
			<div class="d-flex justify-center">

				<!--Image-->
				<v-img v-if="form.activityCoverImageSmall"
					   class="rounded-lg"
					   contain
					   :src="form.activityCoverImageSmall"
					   height="100%"
					   max-height="464"
					   width="100%"
					   max-width="464"/>

				<!--Icon-->
				<app-icon v-if="!form.activityCoverImageSmall" icon="image" size="256"/>

			</div>

			<!--Upload Button | Name | Organisation-->
			<div :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : 'ml-4'">

				<!--Upload Button-->
				<image-upload @emitImageUrl="handleImageUpload"
							  :block="true"
							  color="appGradient"
							  :disabled="isReadOnly"
							  folder="activities"
							  icon="upload"
							  imageFor="Activity"
							  label="Upload Image"/>

				<!--Upload Instructional Text-->
				<app-text class="d-flex justify-center text-center mt-2" size="small">
					For best results, the image should have a 2:1 aspect ratio
					<br>
					E.g. (w)1000px (h)500px
				</app-text>

				<!--Name-->
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.activityName"
						   :error-message="errors.activityNameErrorMessage"
						   label="Name"
						   v-model.trim="form.activityName"/>

				<!--Organisation-->
				<app-input input-type="select"
						   class="mt-4"
						   :disabled="isReadOnly"
						   :error="errors.organisation"
						   :error-message="errors.organisationErrorMessage"
						   label="Organisation"
						   :items="organisationsData"
						   item-text="organisationName"
						   :return-object="true"
						   v-model="tempOrganisation"/>

			</div>

		</div>

		<!--Categories | Intensity Level-->
		<v-row no-gutters>

			<!--Category-->
			<v-col :cols="$vuetify.breakpoint.width < 1024 ? 12 : 8"
				   :class="$vuetify.breakpoint.width < 1024 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="autoComplete"
						   :disabled="isReadOnly"
						   :error="errors.activityCategory"
						   :error-message="errors.activityCategoryErrorMessage"
						   :items="categoriesData"
						   item-text="categoryName"
						   label="Category"
						   v-model="form.activityCategory"/>
			</v-col>

			<!--Intensity Level-->
			<v-col :cols="$vuetify.breakpoint.width < 1024 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 1024 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :error="errors.activityIntensityLevel"
						   :error-message="errors.activityIntensityLevelErrorMessage"
						   :items="activityIntensityLevelOptions"
						   label="Intensity Level"
						   v-model="form.activityIntensityLevel"/>
			</v-col>

		</v-row>

		<!--Info Points-->
		<app-text class="mt-4" color="grey9" size="normal-bold">Info Points</app-text>
		<v-autocomplete background-color="white"
						chips
						class="rounded-lg mt-2"
						color="pink"
						deletable-chips
						:disabled="isReadOnly"
						filled
						hide-details="auto"
						item-color="pink"
						item-text="infoPointName"
						:items="computedInfoPoints"
						label="Points"
						multiple
						outlined
						small-chips
						v-model="form.activityInfoPoints">
			<template v-slot:item="{item}">
				<div class="d-flex flex-column">
					<app-text>{{ item.infoPointName }}</app-text>
					<app-text :color="item.infoPointType === 'Disability' ? 'blue' : 'orange'" size="small">
						{{ item.infoPointType }}
					</app-text>
				</div>
			</template>
		</v-autocomplete>

		<!--Cost | Included In Membership-->
		<v-row class="mt-4" no-gutters>

			<!--Cost-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'pr-2' : 'pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.activityCost"
						   :error-message="errors.activityCostErrorMessage"
						   label="Cost"
						   type="number"
						   v-model.trim="form.activityCost"/>
			</v-col>

			<!--Frequency-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'pl-2' : 'px-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.activityCostFrequency"
						   :error-message="errors.activityCostFrequencyErrorMessage"
						   label="Frequency"
						   v-model.trim="form.activityCostFrequency"/>
			</v-col>

			<!--Is Included In Membership-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :error="errors.activityIsIncludedInMembership"
						   :error-message="errors.activityIsIncludedInMembershipErrorMessage"
						   label="Included in Membership?"
						   :items="isIncludedInMembershipOptions"
						   v-model="form.activityIsIncludedInMembership"/>
			</v-col>

		</v-row>

		<!--Sex | Group/Individual-->
		<v-row class="mt-4" no-gutters>

			<!--Sex-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :error="errors.activitySex"
						   :error-message="errors.activitySexErrorMessage"
						   label="Sex"
						   :items="['Female Only', 'Male Only', 'Mixed']"
						   v-model="form.activitySex"/>
			</v-col>

			<!--Group/Individual-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :error="errors.activityGroupIndividual"
						   :error-message="errors.activityGroupIndividualErrorMessage"
						   label="Group or Individual"
						   :items="groupIndividualOptions"
						   v-model="form.activityGroupIndividual"/>
			</v-col>

		</v-row>

		<!--Times-->
		<expansion-panel class="mt-4">

			<!--Header-->
			<template #header>
				<app-text color="grey" size="normal-bold">Times</app-text>
			</template>

			<!--Content-->
			<template #content>
				<activity-times v-for="item in computedActivityDaysTimes" :key="item.day"
								@emitNewTimes="handleNewTimes"
								:activity="form"
								:activityTimes="item.times ? JSON.parse(item.times) : []"
								:day="item.day"
								:disabled="isReadOnly"
								:entityId="form.entityId"
								:tempOrganisation="tempOrganisation"/>
			</template>

		</expansion-panel>

		<!--Description-->
		<app-text class="mt-4" color="grey" size="normal-bold">Description</app-text>
		<vue-editor class="white mt-2"
					:class="computedTextEditorClass"
					:disabled="isReadOnly"
					:editor-toolbar="richTextEditorOptions"
					v-model.trim="form.activityDescription"/>
		<app-text v-if="errors.activityDescription" class="ml-4 pa-1" color="red" size="small">
			Description is required
		</app-text>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block="true"
				 class="mt-4"
				 color="green"
				 :disabled="isReadOnly"
				 label="Save"/>

	</v-form>
</template>

<script>
import ActivityTimes from "@/views/adminConsole/activitiesConsole/activityTimes/ActivityTimes";
import {VueEditor} from "vue2-editor";

export default {

	name: "ActivitiesForm",

	components: {ActivityTimes, VueEditor},

	props: ['categoriesData', 'formData', 'infoPointsData', 'isReadOnly', 'organisationsData'],

	data: () => ({
		entity: 'activity',
		errors: {
			activityName: false,
			activityNameErrorMessage: '',
			organisation: false,
			organisationErrorMessage: '',
			activityCategory: false,
			activityCategoryErrorMessage: '',
			activityIntensityLevel: false,
			activityIntensityLevelErrorMessage: '',
			activityCost: false,
			activityCostErrorMessage: '',
			activityCostFrequency: false,
			activityCostFrequencyErrorMessage: '',
			activityIsIncludedInMembership: false,
			activityIsIncludedInMembershipErrorMessage: '',
			activitySex: false,
			activitySexErrorMessage: '',
			activityGroupIndividual: false,
			activityGroupIndividualErrorMessage: '',
			activityDescription: false,
			activityDescriptionErrorMessage: '',
		},
		form: {
			entityId: '',
			activityName: '',
			activityCategory: [],
			activityDescription: '',
			activityInfoPoints: [],
			activityCost: '',
			activityCostFrequency: '',
			activityIsIncludedInMembership: null,
			activityCoverImageLarge: '',
			activityCoverImageMedium: '',
			activityCoverImageSmall: '',
			activityImages: [],
			activitySex: '',
			activityGroupIndividual: '',
			activityIntensityLevel: '',
			activityMondayTimes: '',
			activityTuesdayTimes: '',
			activityWednesdayTimes: '',
			activityThursdayTimes: '',
			activityFridayTimes: '',
			activitySaturdayTimes: '',
			activitySundayTimes: '',
			organisation: '',

			activityAddressLine1: '',
			activityAddressLine2: '',
			activityAddressTown: '',
			activityAddressCity: '',
			activityAddressCounty: '',
			activityAddressPostcode: '',
			activityAddressCoords: {longitude: 0.0, latitude: 0.0},

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		},

		tempOrganisation: {},
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) {
				t.form = formData

				// Convert the boolean to Yes/No text
				t.form.activityIsIncludedInMembership = t.form.activityIsIncludedInMembership ? 'Yes' : 'No';

				// Convert ID to Organisation
				t.tempOrganisation = t.organisationsData.find(org => org.entityId === t.form.organisation)

			}
		},

		computedActivityDaysTimes() {
			const t = this

			return [
				{times: t.form.activityMondayTimes, day: 'Monday'},
				{times: t.form.activityTuesdayTimes, day: 'Tuesday'},
				{times: t.form.activityWednesdayTimes, day: 'Wednesday'},
				{times: t.form.activityThursdayTimes, day: 'Thursday'},
				{times: t.form.activityFridayTimes, day: 'Friday'},
				{times: t.form.activitySaturdayTimes, day: 'Saturday'},
				{times: t.form.activitySundayTimes, day: 'Sunday'},
			]
		},

		computedInfoPoints() {
			const t = this
			let infoPointsData = t.$props.infoPointsData

			infoPointsData = infoPointsData.filter(point => ['Activity', 'Disability'].includes(point.infoPointType))

			infoPointsData = infoPointsData.sort((a, b) => {
				return a.infoPointType > b.infoPointType ? 1 : -1
			})

			return infoPointsData
		},

		/**
		 * Computed Text Editor Class
		 *
		 * Return a class name for the Text Editor UI, based on error and readOnly states.
		 *
		 * @returns {string} the class name to render
		 */
		computedTextEditorClass() {
			const t = this
			let computedClass

			if (t.errors.activityDescription) computedClass = 'richText-error'

			if (!t.errors.activityDescription && t.$props.isReadOnly) computedClass = 'richText-disabled'

			if (!t.errors.activityDescription && !t.$props.isReadOnly) computedClass = 'richText-enabled'

			return computedClass
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Image Upload
		 *
		 * Assign the emitted payload URL to the form.
		 *
		 * @param payload the url of the image
		 */
		handleImageUpload(payload) {
			const t = this

			if (payload.sizeLabel === 'small') t.form.activityCoverImageSmall = payload.url
			if (payload.sizeLabel === 'medium') t.form.activityCoverImageMedium = payload.url
			if (payload.sizeLabel === 'large') t.form.activityCoverImageLarge = payload.url
		},

		/**
		 * Handle New Times
		 *
		 * Take the ActivityTimes' emitted Activity object, and assign it to the form.
		 *
		 * @param activity the Activity object
		 */
		handleNewTimes(activity) {
			const t = this

			t.form = activity
		},

		/**
		 * Save
		 *
		 * Convert the required fields back to their storable types, and Save/Update the Redis Key.
		 * If there are no errors, refresh the page.
		 */
		async save() {
			const t = this
			let response

			// Only save if the form has passed validation
			if (!t.validateForm()) return

			// Convert the Yes/No text to a boolean
			t.form.activityIsIncludedInMembership = t.form.activityIsIncludedInMembership === 'Yes'
			// Convert Organisation to ID
			t.form.organisation = t.tempOrganisation?.entityId

			// Add the Organisation's location data
			t.form.activityAddressLine1 = t.tempOrganisation.organisationAddressLine1
			t.form.activityAddressLine2 = t.tempOrganisation.organisationAddressLine2
			t.form.activityAddressTown = t.tempOrganisation.organisationAddressTown
			t.form.activityAddressCity = t.tempOrganisation.organisationAddressCity
			t.form.activityAddressCounty = t.tempOrganisation.organisationAddressCounty
			t.form.activityAddressPostcode = t.tempOrganisation.organisationAddressPostcode
			t.form.activityAddressCoords = t.tempOrganisation.organisationAddressCoords

			// Create
			if (!t.form?.entityId) {
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			// Update the parent Organisation's categories
			response = await t.MIX_redis_updateOrganisationCategories(t.form.organisation)
			if (response.hasErrors) {
				console.error('Error updating Organisation Categories: ', response.error)
				return
			}

			t.refreshPage()
		},

		/**
		 * Refresh Page
		 *
		 * Emit a message back the parent component to refresh the page's data.
		 */
		refreshPage() {
			const t = this

			t.$emit('refreshPage')
		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Activity Name
			if (!t.form.activityName.trim()) {
				t.errors.activityName = true
				t.errors.activityNameErrorMessage = 'Name is required'
			}

			// Activity Organisation
			if (t.form.id && !t.form.organisation) {
				t.errors.organisation = true
				t.errors.organisationErrorMessage = 'Organisation is required'
			} else if (!t.form.id && !t.tempOrganisation.entityId) {
				t.errors.organisation = true
				t.errors.organisationErrorMessage = 'Organisation is required'
			}

			// Activity Category
			if (!t.form.activityCategory.length) {
				t.errors.activityCategory = true
				t.errors.activityCategoryErrorMessage = 'At least one Category is required'
			}

			// Activity Intensity
			if (!t.form.activityIntensityLevel) {
				t.errors.activityIntensityLevel = true
				t.errors.activityIntensityLevelErrorMessage = 'Intensity is required'
			}

			// Activity Cost
			if (!t.form.activityCost.trim()) {
				t.errors.activityCost = true
				t.errors.activityCostErrorMessage = 'Cost is required'
			}

			// Activity Cost Frequency
			if (!t.form.activityCostFrequency?.trim()) {
				t.errors.activityCostFrequency = true
				t.errors.activityCostFrequencyErrorMessage = 'Frequency is required'
			}

			// Activity Included In Membership
			if (t.form.activityIsIncludedInMembership === null) {
				t.errors.activityIsIncludedInMembership = true
				t.errors.activityIsIncludedInMembershipErrorMessage = 'Included is required'
			}

			// Activity Sex
			if (!t.form.activitySex) {
				t.errors.activitySex = true
				t.errors.activitySexErrorMessage = 'Sex is required'
			}

			// Activity Group/Individual
			if (!t.form.activityGroupIndividual) {
				t.errors.activityGroupIndividual = true
				t.errors.activityGroupIndividualErrorMessage = 'Group/Individual is required'
			}

			// Activity Description
			if (!t.form.activityDescription.trim() || t.form.activityDescription.replace(/\s/g, '') === '<p></p>') {
				t.errors.activityDescription = true
				t.errors.activityDescriptionErrorMessage = 'Description is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
.actForm-header-mobile {
	display: grid;
	grid-template-rows: auto auto;
}

.actForm-header-desktop {
	display: grid;
	grid-template-columns: auto 1fr;
}
</style>
