<template>
	<div>

		<loading-animation v-if="isPageLoading"/>

		<div v-if="!isPageLoading">

			<!--Carousel Images-->
			<v-img
				:src="MIX_renderImageForScreenWidth(organisationData.organisationCoverImageSmall, organisationData.organisationCoverImageMedium, organisationData.organisationCoverImageLarge)"
				cover
				max-width="960"
				style="aspect-ratio: 2/1"/>

			<!--Header | Contact Links-->
			<div class="pa-4">

				<!--Header-->
				<div class="d-flex align-center">

					<!--Logo-->
					<div class="mr-4">
						<v-img :src="organisationData.organisationLogo"
							   style="aspect-ratio: 1/1; width: 48px; border-radius: 8px 0 8px 0"/>
					</div>

					<!--Name-->
					<app-text size="medium-bold">{{ organisationData.organisationName }}</app-text>

					<v-spacer/>

					<!--Favorite Icon-->
					<app-icon v-if="!currentUser.isAnonymous"
							  @click.native="handleFavoriteSelection"
							  :color="isFavorited ? 'red' : 'grey9'"
							  :icon="isFavorited ? 'favoriteFilled' : 'favoriteEmpty'"
							  size="32"/>

				</div>

				<!--Address-->
				<app-text class="text-center mt-4" color="grey9" size="small">{{ computedAddress }}</app-text>

				<!--Contact Links-->
				<div class="d-flex align-center justify-center mt-8">

					<div class="d-flex flex-grow-1 greyD mr-2" style="height: 1px"/>

					<contact-icon-link @click.native="handleTelephoneLink(organisationData)"
									   class="mx-2"
									   :disabled="!organisationData.organisationTelephone"
									   icon="phone"/>
					<contact-icon-link @click.native="handleWebsiteLink(organisationData)"
									   class="mx-2"
									   :disabled="!organisationData.organisationWebsite"
									   icon="website"/>
					<contact-icon-link @click.native="handleEmailLink(organisationData)"
									   class="mx-2"
									   :disabled="!organisationData.organisationEmail"
									   icon="email"/>
					<contact-icon-link @click.native="handleMapLink(organisationData)"
									   class="mx-2"
									   :disabled="!organisationData.organisationAddressCoords"
									   icon="map"/>

					<div class="d-flex flex-grow-1 greyD ml-2" style="height: 1px"/>

				</div>

			</div>

			<!--Tabs (Description | Membership)-->
			<v-tabs class="mt-4" centered grow slider-color="pink" v-model="tabs">

				<v-tab href="#description">
					<app-text size="small">Description</app-text>
				</v-tab>

				<v-tab href="#membership">
					<app-text size="small">Membership</app-text>
				</v-tab>

			</v-tabs>

			<div class="pa-4">

				<!--Tabs Content (Description | Membership)-->
				<v-tabs-items class="transparent" v-model="tabs">

					<!--Description | Info Points-->
					<v-tab-item value="description">

						<!--Description-->
						<div class="d-flex justify-center py-8">
							<app-text class="text-center"
									  style="line-height: 1.25em; max-width: 60ch"
									  v-html="organisationData.organisationDescription"/>
						</div>

						<v-divider/>

						<!--Info Points-->
						<app-text color="orange" size="normal-bold" class="mt-8">Venue Features...</app-text>
						<div class="mt-4" style="width: 100%">
							<side-by-side-bullet-points :points="organisationData.organisationInfoPoints"/>
						</div>

					</v-tab-item>

					<!--Membership-->
					<v-tab-item value="membership">
						<div class="pa-4">

							<app-text v-if="organisationMembershipData.length" class="text-center">
								Check out the membership options below.
								For more information contact {{ organisationData.organisationName }}
							</app-text>
							<app-text v-if="!organisationMembershipData.length" class="text-center mt-4" color="grey9">
								There are no options for this Organisation
							</app-text>

							<div class="membershipCardGrid mt-4">

								<organisation-membership-card v-for="membership in organisationMembershipData"
															  :key="membership.id"
															  :cardData="membership"/>

							</div>

						</div>
					</v-tab-item>

				</v-tabs-items>

				<!--Matching Activities-->
				<div v-if="selectedCategory" class="mt-8">

					<title-page-break :title="selectedCategory.categoryName"
									  description="Click an activity for more details"/>

					<!--Icons-->
					<div class="organisation-cardGrid-container">
						<icon-with-label v-for="activity in computedActivitiesData.categoryActivities"
										 :key="activity.entityId"
										 @click.native="handleActivitySelection(activity)"
										 color="orange"
										 :icon="selectedCategory.categoryIcon"
										 :label="activity.activityName"/>
					</div>

				</div>

				<!--Other Activities-->
				<div class="mt-8">

					<title-page-break title="Other Activities" description="Other activities this centre offers"/>

					<!--Icons-->
					<div class="organisation-cardGrid-container">
						<icon-with-label v-for="activity in computedActivitiesData.otherActivities"
										 :key="activity.entityId"
										 @click.native="handleActivitySelection(activity)"
										 class="mb-4"
										 color="pink"
										 :icon="getOtherActivityIcon(activity)"
										 :label="activity.activityName"/>
					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import OrganisationMembershipCard from "@/views/organisation/organisationMembershipCard/OrganisationMembershipCard";

export default {

	name: "Organisation",

	components: {OrganisationMembershipCard},

	data: () => ({
		categoriesData: [],
		currentUserData: {},
		isFavorited: false,
		isPageLoading: true,
		myFavoritesData: [],
		organisationData: {},
		organisationMembershipData: [],
		selectedCategory: {},
		tabs: '',
	}),

	computed: {

		/**
		 * Computed Activities Data
		 *
		 * Filter and return this Organisation's Activities data into two arrays.
		 * One with Activities for this category
		 * One with other Activities this Organisation runs.
		 *
		 * @returns {{categoryActivities: [], otherActivities: []}}
		 */
		computedActivitiesData() {
			const t = this
			const ACTIVITIES_DATA = t.activitiesData.filter(a => a.organisation === t.organisationData.entityId)
			const SELECTED_CATEGORY = t.selectedCategory
			let categoryActivities = []
			let otherActivities = []

			categoryActivities = ACTIVITIES_DATA.filter(a => a.activityCategory.includes(SELECTED_CATEGORY?.categoryName))
			otherActivities = ACTIVITIES_DATA.filter(a => !a.activityCategory.includes(SELECTED_CATEGORY?.categoryName))

			return {categoryActivities, otherActivities}
		},

		computedAddress() {
			const t = this
			const ORGANISATION = t.organisationData
			let address = ''

			let addressLine1 = ORGANISATION?.organisationAddressLine1
			let addressLine2 = ORGANISATION?.organisationAddressLine2
			let addressTown = ORGANISATION?.organisationAddressTown
			let addressPostcode = ORGANISATION?.organisationAddressPostcode

			if (addressLine1) address += addressLine1 + ', '
			if (addressLine2) address += addressLine2 + ', '
			if (addressTown) address += addressTown + ', '
			if (addressPostcode) address += addressPostcode

			return address
		},

	},

	methods: {

		/**
		 * Get Activities Data
		 *
		 * Get the Activities for the current Organisation.
		 *
		 * @param organisationId the ID of the Organisation to get the Activities for
		 * @returns {Promise<*>}
		 */
		async getActivitiesData(organisationId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getActivitiesByOrganisationId(organisationId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisation Activities: ', RESPONSE.error)
				return
			}

			return RESPONSE.data
		},

		/**
		 * Get Organisation Memberships Data
		 *
		 * Get the data from the DB, remove any deleted items, and assign to state.
		 *
		 * @returns {Promise<*[]>}
		 */
		async getOrganisationMembershipsData(organisationId) {
			const t = this
			let responseData = []

			const RESPONSE = await t.MIX_redis_getOrganisationMemberships(organisationId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisation Membership data: ', RESPONSE.error)
				return
			}

			responseData = RESPONSE.data

			// Remove any deleted items
			responseData = responseData.filter(i => !i.isDeleted)

			// Sort into alphabetical order
			responseData = responseData.sort((a, b) => {
				return a.organisationMembershipName > b.organisationMembershipName ? 1 : -1
			})

			return responseData
		},

		/**
		 * Get My Favorites Data
		 *
		 * Get the data from the DB, and assign to state.
		 *
		 * @returns {Promise<*[]>}
		 */
		async getMyFavoritesData(userId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getMyFavoritesByUserId(userId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting My Favorites data: ', RESPONSE.error)
				return
			}

			return RESPONSE.data
		},

		/**
		 * Get Other Activity Icon
		 *
		 * An Activity can belong to one or more categories,
		 * so pick the first category from the Activity's activityCategory array and return its icon.
		 *
		 * @param activity the current activity to get the icon for
		 * @returns {string} the name of the icon to display
		 */
		getOtherActivityIcon(activity) {
			const t = this
			let icon
			const CATEGORIES_DATA = t.categoriesData

			CATEGORIES_DATA.forEach(category => {

				if (category.categoryName === activity.activityCategory[0]) icon = category.categoryIcon

			})

			return icon
		},

		/**
		 * Handle Activity Selection
		 *
		 * When an Activity has been selected, emit a message back to the parent
		 *
		 * @param activity
		 */
		handleActivitySelection(activity) {
			const t = this

			t.MIX_addToLocalStorage('selectedActivity', activity)

			t.MIX_go('activity')
		},

		/**
		 * Handle Favorites Selection
		 *
		 * Check to see if this Organisation has been favorited.
		 * If it has, delete it.
		 * If it hasn't, add it.
		 *
		 * @returns {Promise<void>}
		 */
		async handleFavoriteSelection() {
			const t = this
			const MY_FAVORITES_DATA = t.myFavoritesData || []
			const ORGANISATION_DATA = t.organisationData

			// Check if this entity has been favorited
			let favoritesData = MY_FAVORITES_DATA.filter(f => f.myFavoriteEntityId === ORGANISATION_DATA.entityId)

			if (favoritesData.length) {
				await t.deleteFromFavorites(favoritesData[0].entityId)
			} else {
				await t.addToFavorites()
			}
		},

		/**
		 * Add to Favorites
		 *
		 * Create a MyFavorite entry in the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async addToFavorites() {
			const t = this
			const ORGANISATION_DATA = t.organisationData
			const USER_DATA = t.currentUserData

			const RESPONSE = await t.MIX_redis_create('myFavorite', {
				myFavoriteType: 'Organisation',
				myFavoriteEntityId: ORGANISATION_DATA.entityId,
				userId: USER_DATA.entityId
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating My Favorite: ', RESPONSE.error)
				return
			}

			await t.loadData()
		},

		/**
		 * Delete From Favorites
		 *
		 * Remove the MyFavorite entry by its ID.
		 *
		 * @param favoriteId
		 * @returns {Promise<void>}
		 */
		async deleteFromFavorites(favoriteId) {
			const t = this

			t.isFavorited = !t.isFavorited

			const RESPONSE = await t.MIX_redis_destroy('myFavorite', favoriteId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error destroying My Favorite: ', RESPONSE.error)
				return
			}

			await t.loadData()
		},

		/**
		 * Handle Telephone Link
		 *
		 * When the telephone icon is pressed, open the dialer to call the number
		 *
		 * @param organisation the number to call
		 */
		handleTelephoneLink(organisation) {
			if (!organisation.organisationTelephone) return
			this.MIX_handleTelephoneLink(organisation.organisationTelephone)
		},

		/**
		 * Handle Website Link
		 *
		 * When the website icon is pressed, open the Organisation's page
		 *
		 * @param organisation the website to open
		 */
		handleWebsiteLink(organisation) {
			this.MIX_handleWebsiteLink(organisation.organisationWebsite)
		},

		/**
		 * Handle Email Link
		 *
		 * When the Email icon is pressed, open the email client
		 *
		 * @param organisation the email to contact
		 */
		handleEmailLink(organisation) {
			this.MIX_handleEmailLink(organisation.organisationEmail)
		},

		/**
		 * Handle Map Link
		 *
		 * When the Map icon is pressed, open the map at the location
		 *
		 * @param organisation the location to contact
		 */
		handleMapLink(organisation) {
			this.MIX_handleMapLink(organisation.organisationAddressCoords)
		},

		/**
		 * Load Data
		 *
		 * Get and set all the required data from the DB and local storage.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this
			t.currentUserData = t.MIX_getFromLocalStorage('currentUserData')

			// Load data from local storage
			t.organisationData = t.MIX_getFromLocalStorage('selectedOrganisation')
			t.selectedCategory = t.MIX_getFromLocalStorage('selectedCategory')
			t.categoriesData = t.MIX_getFromLocalStorage('categoriesData')

			// Load this Organisation's Membership data
			t.organisationMembershipData = await t.getOrganisationMembershipsData(t.organisationData.entityId)

			// Load this Organisation's Activities
			t.activitiesData = t.MIX_getFromLocalStorage('isSearch')
				? t.activitiesData = t.MIX_getFromLocalStorage('searchActivities')
				: t.activitiesData = await t.getActivitiesData(t.organisationData.entityId)

			// Load My Favorites data
			t.myFavoritesData = await t.getMyFavoritesData(t.currentUserData.entityId)

			// If this Organisation is favorited, set it
			if (t.myFavoritesData.length) t.isFavorited = t.myFavoritesData.find(f => f.myFavoriteEntityId === t.organisationData.entityId)

			t.isPageLoading = false
		}

	},

	async created() {
		const t = this
		t.isPageLoading = true

		// Scroll to top on load
		window.scroll({top: 0})

		await t.loadData()
	}

}
</script>

<style scoped>
.organisation-cardGrid-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
	margin-top: 16px;
}

.membershipCardGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 16px;
}
</style>
