<template>
	<transition name="custom-classes-transition"
				enter-active-class="animate__animated animate__zoomIn animate__fast"
				leave-active-class="animate__animated animate__fadeOut animate__fast"
				mode="out-in">
		<div class="confAnimation-container">

			<div class="white rounded-pill pa-4">

				<app-icon
					color="green"
					icon="check"
					size="256"/>

			</div>

		</div>
	</transition>
</template>

<script>
export default {

	name: "ConfirmationAnimation",

}
</script>

<style scoped>
.confAnimation-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	background: #00000066;

	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: 999;
}
</style>
