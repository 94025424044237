import {
	createUserWithEmailAndPassword,
	FacebookAuthProvider,
	fetchSignInMethodsForEmail,
	getAuth,
	GoogleAuthProvider,
	linkWithCredential,
	sendPasswordResetEmail,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithPopup,
	TwitterAuthProvider,
} from "firebase/auth";

const mixin = {

	methods: {

		/**
		 * Log In
		 *
		 * Log in to an existing Firebase account with the given email and password.
		 *
		 * @param email the user's email address
		 * @param password the user's password
		 * @returns result resulting object (containing firebase error code)
		 */
		async MIX_auth_logIn(email, password) {
			const t = this
			const AUTH = getAuth()
			let result = {errorCode: ''}

			const CREDENTIALS = await signInWithEmailAndPassword(AUTH, email, password)
				.catch(error => {
					console.error('Error logging in - code: ', error.code)
					console.error('Error logging in - message: ', error.message)

					result.errorCode = error.code
				})

			if (CREDENTIALS?.operationType === 'signIn') t.MIX_go('/')

			if (result.errorCode) return result

		},

		/**
		 * Login Anonymously
		 *
		 * Allow users to log in anonymously for those that do not wish to create an account (continue as a guest).
		 * This creates an anonymous account in Firebase that is deleted after 30 days.
		 * NB: Anonymous users do not have access to Profile, Search Preferences, or My Favorites.
		 *
		 * @returns {Promise<{errorCode: string}>}
		 * @constructor
		 */
		async MIX_auth_login_anonymously() {
			const t = this
			const AUTH = getAuth()
			let result = {errorCode: ''}

			await signInAnonymously(AUTH)
				.then(success => {
					console.log('Successfully logged in anonymously: ', success)
					t.MIX_go('/')
				})
				.catch(error => {
					console.error('Error logging in anonymously - code: ', error.code)
					console.error('Error logging in anonymously - message: ', error.message)

					result.errorCode = error.code
					return result
				})

			if (result.errorCode) return result
		},

		/**
		 * Log Out
		 *
		 * Log out from the app, and remove/reset all app-data held in local storage.
		 */
		MIX_auth_logOut() {
			const t = this
			getAuth()

				.signOut()

				.then(() => {

					t.MIX_addToLocalStorage('isSearchActive', false)
					t.MIX_deleteOneFromLocalStorage('searchActivities')
					t.MIX_deleteOneFromLocalStorage('searchCardData')
					t.MIX_deleteOneFromLocalStorage('searchCriteria')
					t.MIX_addToLocalStorage('searchPreferencesSwitch', false)
					t.MIX_deleteOneFromLocalStorage('selectedCategory')
					t.MIX_deleteOneFromLocalStorage('selectedOrganisation')

					t.MIX_go('/auth')
				})

				.catch(error => {
					console.error('Error logging out - code: ', error.code)
					console.error('Error logging out - message: ', error.message)
				})
		},

		/**
		 * Register
		 *
		 * Register for a new Firebase account with the given email and password.
		 * On successful registration, call to create a user document in the Firestore.
		 *
		 * @param email the user's email address
		 * @param password the user's password
		 * @param userName the user's name
		 * @returns result resulting object (containing firebase error code)
		 */
		async MIX_auth_register_email(email, password, userName) {
			const t = this
			const AUTH = getAuth()

			// Create a user, and call to create a data document if successful
			try {
				const RESPONSE = await createUserWithEmailAndPassword(AUTH, email, password)

				if (RESPONSE?.user?.uid) await t.createUserDocument(RESPONSE.user.uid, email, userName)

				return {data: RESPONSE, hasErrors: false, error: null}
			}

			// Return any errors
			catch (error) {
				return {data: null, hasErrors: true, error: error}
			}
		},

		/**
		 * Register With Facebook
		 *
		 * Activate a provider-based popup for the user to sign in to.
		 * If the user does not have a Fitap account, auth and data accounts will be created.
		 * If the user does have a Fitap account, return to the frontend and advise the user they cannot create another.
		 *
		 * @returns {Promise<{hasErrors: boolean, data: null, error}|{hasErrors: boolean, data: string[], error}|{hasErrors: boolean, data: null, error: null}>}
		 */
		async MIX_auth_register_facebook() {
			const t = this
			const PROVIDER = new FacebookAuthProvider()
			const AUTH = getAuth()

			// Try and sign the user in via the provider, creating an account if the email is not already in use
			try {
				const SIGN_IN_RESULT = await signInWithPopup(AUTH, PROVIDER)
				const CREDENTIAL = FacebookAuthProvider.credentialFromResult(SIGN_IN_RESULT)
				const TOKEN = CREDENTIAL.accessToken
				const USER_DATA = SIGN_IN_RESULT.user

				const IS_EMAIL_IN_USE = await t.checkIfEmailIsInUse(USER_DATA.email)

				if (!IS_EMAIL_IN_USE) await t.createUserDocument(USER_DATA.uid, USER_DATA.email, USER_DATA.displayName)

				return {data: null, hasErrors: false, error: null}
			}

				// If there are any errors, handle them
			catch (error) {
				const ERROR_CODE = error.code
				const ERROR_MESSAGE = error.message
				const USER_EMAIL = error.customData?.email

				// If the user has previously registered with another method and error will be thrown,
				// return it to the front-end to inform the user
				if (ERROR_CODE === 'auth/account-exists-with-different-credential') {

					// Get sign-in methods for this email
					const METHODS = await fetchSignInMethodsForEmail(AUTH, USER_EMAIL)

					if (METHODS.length) return {data: METHODS, hasErrors: true, error}
				}

				return {data: null, hasErrors: true, error}
			}
		},

		/**
		 * Register With Google
		 *
		 * Activate a provider-based popup for the user to sign in to.
		 * If the user does not have a Fitap account, auth and data accounts will be created.
		 * If the user does have a Fitap account, return to the frontend and advise the user they cannot create another.
		 *
		 * @returns {Promise<{hasErrors: boolean, data: null, error}|{hasErrors: boolean, data: string[], error}|{hasErrors: boolean, data: null, error: null}>}
		 */
		async MIX_auth_register_google() {
			const t = this
			const PROVIDER = new GoogleAuthProvider()
			const AUTH = getAuth()

			// Try and sign the user in via the provider, creating an account if the email is not already in use
			try {
				const SIGN_IN_RESULT = await signInWithPopup(AUTH, PROVIDER)
				const CREDENTIAL = GoogleAuthProvider.credentialFromResult(SIGN_IN_RESULT)
				const TOKEN = CREDENTIAL.accessToken
				const USER_DATA = SIGN_IN_RESULT.user

				const IS_EMAIL_IN_USE = await t.checkIfEmailIsInUse(USER_DATA.email)

				if (!IS_EMAIL_IN_USE) await t.createUserDocument(USER_DATA.uid, USER_DATA.email, USER_DATA.displayName)

				return {data: null, hasErrors: false, error: null}
			}

				// If there are any errors, handle them
			catch (error) {
				const ERROR_CODE = error.code
				const ERROR_MESSAGE = error.message
				const USER_EMAIL = error.customData?.email

				// If the user has previously registered with another method and error will be thrown,
				// return it to the front-end to inform the user
				if (ERROR_CODE === 'auth/account-exists-with-different-credential') {

					// Get sign-in methods for this email
					const METHODS = await fetchSignInMethodsForEmail(AUTH, USER_EMAIL)

					if (METHODS.length) return {data: METHODS, hasErrors: true, error}
				}

				return {data: null, hasErrors: true, error}
			}
		},

		/**
		 * Register With Twitter
		 *
		 * Activate a provider-based popup for the user to sign in to.
		 * If the user does not have a Fitap account, auth and data accounts will be created.
		 * If the user does have a Fitap account, return to the frontend and advise the user they cannot create another.
		 *
		 * @returns {Promise<{hasErrors: boolean, data: null, error}|{hasErrors: boolean, data: string[], error}|{hasErrors: boolean, data: null, error: null}>}
		 */
		async MIX_auth_register_twitter() {
			const t = this
			const PROVIDER = new TwitterAuthProvider()
			const AUTH = getAuth()

			// Try and sign the user in via the provider, creating an account if the email is not already in use
			try {
				const SIGN_IN_RESULT = await signInWithPopup(AUTH, PROVIDER)
				const CREDENTIAL = TwitterAuthProvider.credentialFromResult(SIGN_IN_RESULT)
				const TOKEN = CREDENTIAL.accessToken
				const USER_DATA = SIGN_IN_RESULT.user

				const IS_EMAIL_IN_USE = await t.checkIfEmailIsInUse(USER_DATA.email)

				if (!IS_EMAIL_IN_USE) await t.createUserDocument(USER_DATA.uid, USER_DATA.email, USER_DATA.displayName)

				return {data: null, hasErrors: false, error: null}
			}

				// If there are any errors, handle them
			catch (error) {
				const ERROR_CODE = error.code
				const ERROR_MESSAGE = error.message
				const USER_EMAIL = error.customData?.email

				// If the user has previously registered with another method and error will be thrown,
				// return it to the front-end to inform the user
				if (ERROR_CODE === 'auth/account-exists-with-different-credential') {

					// Get sign-in methods for this email
					const METHODS = await fetchSignInMethodsForEmail(AUTH, USER_EMAIL)

					if (METHODS.length) return {data: METHODS, hasErrors: true, error}
				}

				return {data: null, hasErrors: true, error}
			}
		},

		/**
		 * Reset Password
		 *
		 * Reset a password on a Firebase account with the given email.
		 *
		 * @param email
		 * @returns result resulting object (containing firebase error code)
		 */
		async MIX_auth_resetPassword(email) {
			const AUTH = getAuth()
			let result = {errorCode: ''}

			await sendPasswordResetEmail(AUTH, email)
				.then(success => console.log('Successfully sent password reset link: ', success))
				.catch(error => {
					console.error('Error resetting password - code: ', error.code)
					console.error('Error resetting password - message: ', error.message)

					result.errorCode = error.code
					return result
				})

			if (result.errorCode) return result
		},

		/**
		 * Create User Document
		 *
		 * On successful registration, create a user document in the database.
		 *
		 * @returns {Promise<*|{}>}
		 */
		async createUserDocument(uid, userEmail, userName) {
			const t = this

			let user = {}
			let docResult = {}

			user.userFbId = uid
			user.userDateOfBirth = ''
			user.userDisabilityDescriptionText = ''
			user.userDisabilityOrImpairment = ''
			user.userEmail = userEmail
			user.userGender = ''
			user.userGenderDescriptionText = ''
			user.userIsGenderCheckboxChecked = false
			user.userLevel = 'User-Standard'
			user.userName = userName
			user.userStatus = 'Approved'
			user.userIsProfileComplete = false
			user.userIsSearchPreferencesComplete = false

			user.createdDateTime = new Date().getTime()
			user.createdUserId = ''
			user.createdUserEmail = userEmail
			user.createdUserName = userName

			user.modifiedDateTime = ''
			user.modifiedUserId = ''
			user.modifiedUserEmail = ''
			user.modifiedUserName = ''

			user.isDeleted = false
			user.deletedDateTime = ''
			user.deletedUserId = ''
			user.deletedUserEmail = ''
			user.deletedUserName = ''

			docResult = await t.MIX_firestore_createUserDocument('users', user)

			return docResult
		},

		/**
		 * Check If Email Is In Use
		 *
		 * Check to see if the userEmail is in use, and return a boolean.
		 *
		 * @param userEmail - the email to check
		 * @returns {boolean} - if the email is in use, or not
		 */
		async checkIfEmailIsInUse(userEmail) {
			const t = this

			return await t.MIX_firestore_checkIfEmailIsInUse(
				'users', {key: 'userEmail', operator: '==', value: userEmail}
			)
		},

		// -------------------------------------------------------------------------------------------------------------

		/**
		 * BELOW - NOT IN USE
		 * The functions below were created to be able to register and link multiple social accounts together,
		 * but the app is only allowing a single auth method.
		 * They are all the same:
		 *  - If the user hasn't registered, it will activate the popup where they can sign in and authorise to create them an account
		 *  - If the user has previously registered with a social provider, it will return back to Auth.vue so the user can accept they want to link accounts.
		 *    Upon confirmation, MIX_auth_continue() will be called to sign them in and link the social accounts.
		 * NB... This does not handle if the use has previously signed up with an email/password, and then was to also use a social provider.
		 * This needs to return back to Auth.vue and ask the user for their password, then return here and complete the process.
		 */

		async not_in_use__MIX_auth_register_facebook() {
			const t = this
			const PROVIDER = new FacebookAuthProvider()
			const AUTH = getAuth()

			try {
				const RESULT = await signInWithPopup(AUTH, PROVIDER)
				const CREDENTIAL = FacebookAuthProvider.credentialFromResult(RESULT)
				const TOKEN = CREDENTIAL.accessToken
				const USER = RESULT.user

				return {
					data: null,
					hasErrors: false,
					error: null
				}

			} catch (error) {
				const ERROR_CODE = error.code
				const ERROR_MESSAGE = error.message

				// The email of the user's account used
				const USER_EMAIL = error.customData.email

				// The AuthCredential type that was used
				const AUTH_CREDENTIAL = FacebookAuthProvider.credentialFromError(error)

				// If the user has previously registered with another method and error will be thrown, handle it
				if (ERROR_CODE === 'auth/account-exists-with-different-credential') {

					// The pending credential
					const PENDING_AUTH_CREDENTIAL = error.credential

					// Get sign-in methods for this email
					const METHODS = await fetchSignInMethodsForEmail(AUTH, USER_EMAIL)

					// If the user has several sign-in methods, the first method in the list will be the 'recommended' method to use
					if (METHODS[0] === 'password') {
						// TODO: implement promptUserForPassword
						// Asks the user their password
						const PASSWORD = ''
						await AUTH.signInWithEmailAndPassword(USER_EMAIL, PASSWORD)
						const RESULT = await RESULT.user.linkWithCredential(PENDING_AUTH_CREDENTIAL)

						// Twitter account successfully linked to the existing Firebase user
						t.MIX_go('/')
						return
					}

					// All the other cases are external providers, so construct a provider object
					let provider
					switch (METHODS[0]) {
						case 'google.com':
							provider = new GoogleAuthProvider()
							provider.setCustomParameters({login_hint: USER_EMAIL})
							break
						case 'facebook.com':
							provider = new FacebookAuthProvider()
							break
						case 'twitter.com':
							provider = new TwitterAuthProvider()
							break
						default:
							console.error(`Unsupported provider: ${provider}`)
							return
					}

					// Return back to Auth.vue to inform the user they have already registered via a different provider.
					// This is also required as the next pop-up will be blocked by the browser due to being called asynchronously.
					// Comes back to call MIX_continue
					return {
						data: {auth: AUTH, provider, credential: AUTH_CREDENTIAL},
						hasErrors: true,
						error: null
					}
				}
			}
		},

		async not_in_use__MIX_auth_register_google() {
			const t = this
			const PROVIDER = new GoogleAuthProvider()
			const AUTH = getAuth()

			try {
				const RESULT = await signInWithPopup(AUTH, PROVIDER)
				const CREDENTIAL = GoogleAuthProvider.credentialFromResult(RESULT)
				const TOKEN = CREDENTIAL.accessToken
				const SECRET = CREDENTIAL.secret
				const USER = RESULT.user

				return {
					data: null,
					hasErrors: false,
					error: null
				}

			} catch (error) {
				const ERROR_CODE = error.code
				const ERROR_MESSAGE = error.message

				// The email of the user's account used
				const USER_EMAIL = error.customData.email

				// The AuthCredential type that was used
				const AUTH_CREDENTIAL = GoogleAuthProvider.credentialFromError(error)

				// If the user has previously registered with another method and error will be thrown, handle it
				if (ERROR_CODE === 'auth/account-exists-with-different-credential') {

					// The pending credential
					const PENDING_AUTH_CREDENTIAL = error.credential

					// Get sign-in methods for this email
					const METHODS = await fetchSignInMethodsForEmail(AUTH, USER_EMAIL)

					// If the user has several sign-in methods, the first method in the list will be the 'recommended' method to use
					if (METHODS[0] === 'password') {
						// TODO: implement promptUserForPassword
						// Asks the user their password
						const PASSWORD = ''
						await AUTH.signInWithEmailAndPassword(USER_EMAIL, PASSWORD)
						const RESULT = await RESULT.user.linkWithCredential(PENDING_AUTH_CREDENTIAL)

						// Twitter account successfully linked to the existing Firebase user
						t.MIX_go('/')
						return
					}

					// All the other cases are external providers, so construct a provider object
					let provider
					switch (METHODS[0]) {
						case 'google.com':
							provider = new GoogleAuthProvider()
							provider.setCustomParameters({login_hint: USER_EMAIL})
							break
						case 'facebook.com':
							provider = new FacebookAuthProvider()
							break
						case 'twitter.com':
							provider = new TwitterAuthProvider()
							break
						default:
							console.error(`Unsupported provider: ${provider}`)
							return
					}

					// Return back to Auth.vue to inform the user they have already registered via a different provider.
					// This is also required as the next pop-up will be blocked by the browser due to being called asynchronously.
					// Comes back to call MIX_continue
					return {
						data: {auth: AUTH, provider, credential: AUTH_CREDENTIAL},
						hasErrors: true,
						error: null
					}
				}
			}
		},

		async not_in_use__MIX_auth_register_twitter() {
			const t = this
			const PROVIDER = new TwitterAuthProvider()
			const AUTH = getAuth()

			try {
				const RESULT = await signInWithPopup(AUTH, PROVIDER)
				const CREDENTIAL = TwitterAuthProvider.credentialFromResult(RESULT)
				const TOKEN = CREDENTIAL.accessToken
				const SECRET = CREDENTIAL.secret
				const USER = RESULT.user

				return {
					data: null,
					hasErrors: false,
					error: null
				}

			} catch (error) {
				const ERROR_CODE = error.code
				const ERROR_MESSAGE = error.message

				// The email of the user's account used
				const USER_EMAIL = error.customData.email

				// The AuthCredential type that was used
				const AUTH_CREDENTIAL = TwitterAuthProvider.credentialFromError(error)

				// If the user has previously registered with another method and error will be thrown, handle it
				if (ERROR_CODE === 'auth/account-exists-with-different-credential') {

					// The pending credential
					const PENDING_AUTH_CREDENTIAL = error.credential

					// Get sign-in methods for this email
					const METHODS = await fetchSignInMethodsForEmail(AUTH, USER_EMAIL)

					// If the user has several sign-in methods, the first method in the list will be the 'recommended' method to use
					if (METHODS[0] === 'password') {
						// TODO: implement promptUserForPassword
						// Asks the user their password
						const PASSWORD = ''
						await AUTH.signInWithEmailAndPassword(USER_EMAIL, PASSWORD)
						const RESULT = await RESULT.user.linkWithCredential(PENDING_AUTH_CREDENTIAL)

						// Twitter account successfully linked to the existing Firebase user
						t.MIX_go('/')
						return
					}

					// All the other cases are external providers, so construct a provider object
					let provider
					switch (METHODS[0]) {
						case 'google.com':
							provider = new GoogleAuthProvider()
							provider.setCustomParameters({login_hint: USER_EMAIL})
							break
						case 'facebook.com':
							provider = new FacebookAuthProvider()
							break
						case 'twitter.com':
							provider = new TwitterAuthProvider()
							break
						default:
							console.error(`Unsupported provider: ${provider}`)
							return
					}

					// Return back to Auth.vue to inform the user they have already registered via a different provider.
					// This is also required as the next pop-up will be blocked by the browser due to being called asynchronously.
					// Comes back to call MIX_continue
					return {
						data: {auth: AUTH, provider, credential: AUTH_CREDENTIAL},
						hasErrors: true,
						error: null
					}
				}
			}
		},

		async not_in_use__MIX_auth_continue(auth, provider, pendingCred) {
			try {
				const RESULT = await signInWithPopup(auth.auth, auth.provider)
				const CURRENT_USER = auth.auth.currentUser
				const CREDENTIAL_RESULT = await linkWithCredential(CURRENT_USER, auth.credential)

				// Account successfully linked to the existing Firebase user
				return {
					data: CREDENTIAL_RESULT,
					hasErrors: false,
					error: null,
				}
			} catch (error) {
				console.error('Error linking account: ', error)
				return {
					data: null,
					hasErrors: true,
					error: error,
				}
			}
		},

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
