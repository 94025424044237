<template>
	<v-expansion-panels flat>
		<v-expansion-panel>

			<!--Header-->
			<v-expansion-panel-header class="iosInstallBanner text-center rounded-t-lg" color="appGradient" hide-actions>
				<app-text color="white" size="normal-bold" style="letter-spacing: 2px">iOS Location Access</app-text>
				<app-text class="mt-2" color="white" size="small">
					Allow Fitap to use your device's location
				</app-text>
			</v-expansion-panel-header>

			<!--Content-->
			<v-expansion-panel-content class="rounded-b-lg" color="appGradient">
				<div class="d-flex flex-column align-center text-center white rounded-lg mt-0 ma-4 pa-4">

					<app-text>- Open your device's <strong>Settings</strong> -</app-text>
					<app-text class="mt-2">- Scroll down and tap <strong>Safari</strong> -</app-text>
					<app-text class="mt-2">- Scroll down and tap <strong>Location</strong> -</app-text>
					<app-text class="mt-2">- Tap Fitap (app.fitap.co.uk) and select your preference -</app-text>

				</div>
			</v-expansion-panel-content>

		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {

	name: "IosLocationAccessInstructions",

	props: {showDismissBanner: {default: true}},

}
</script>

<style scoped>
.iosInstallBanner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>
