<template>
	<div class="pa-4">

		<!--Header-->
		<div class="d-flex align-center">

			<app-icon class="mr-4" color="orange" icon="admin" size="48"/>
			<app-text size="medium-bold">Admin</app-text>

			<v-spacer/>

			<!--Form Type Selector-->
			<app-input input-type="select"
					   class="ml-4"
					   :items="consoleListItems"
					   placeholder="Select a form"
					   v-model="selectedView"/>

		</div>

		<v-divider class="mt-4"/>

		<activities-console v-if="selectedView === 'Activities'" class="mt-4"/>

		<challenges-console v-if="selectedView === 'Challenges'" class="mt-4"/>

		<categories-console v-if="selectedView === 'Categories'" class="mt-4"/>

		<feedback-console v-if="selectedView === 'Feedback'" class="mt-4"/>

		<exclusive-offers-console v-if="selectedView === 'Exclusive Offers'" class="mt-4"/>

		<info-points-console v-if="selectedView === 'Information Points'" class="mt-4"/>

		<organisations-console v-if="selectedView === 'Organisations'" class="mt-4"/>

		<users-console v-if="selectedView === 'Users'" class="mt-4"/>

	</div>
</template>

<script>
import ActivitiesConsole from "@/views/adminConsole/activitiesConsole/ActivitiesConsole";
import OrganisationsConsole from "@/views/adminConsole/organisationsConsole/OrganisationsConsole";
import UsersConsole from "@/views/adminConsole/usersConsole/UsersConsole";
import CategoriesConsole from "@/views/adminConsole/categoriesConsole/CategoriesConsole";
import ChallengesConsole from "@/views/adminConsole/challengesConsole/ChallengesConsole";
import ExclusiveOffers from "@/views/home/exclusiveOffers/ExclusiveOffersCarousel";
import ExclusiveOffersConsole from "@/views/adminConsole/exclusiveOffersConsole/ExclusiveOffersConsole";
import InfoPointsConsole from "@/views/adminConsole/infoPointsConsole/InfoPointsConsole";
import FeedbackConsole from "@/views/adminConsole/feedbackConsole/FeedbackConsole";

export default {

	name: "AdminConsole",

	components: {
		FeedbackConsole,
		ActivitiesConsole,
		CategoriesConsole,
		ChallengesConsole,
		ExclusiveOffersConsole,
		ExclusiveOffers,
		InfoPointsConsole,
		OrganisationsConsole,
		UsersConsole
	},

	data: () => ({
		consoleListItems: [
			'Activities',
			'Categories',
			'Challenges',
			'Feedback',
			'Exclusive Offers',
			'Information Points',
			'Organisations',
			'Users'
		],
		selectedView: '',
	}),

}
</script>

<style scoped>

</style>
