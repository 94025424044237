<template>
	<div v-if="!isLoading">

		<!--Action Buttons | Searches-->
		<div class="d-flex align-center">

			<!--Searches-->
			<v-row no-gutters>

				<!--By Title-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
					   :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
				<app-input input-type="textInput"
						   append-icon="icons8-search"
						   :clearable="true"
						   placeholder="Search by Title"
						   v-model.trim="searchByTitle"/>
				</v-col>

				<!--By Type-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
					   :class="$vuetify.breakpoint.width < 600 ? '' : 'px-2'">
				<app-input input-type="select"
						   append-icon="icons8-search"
						   :clearable="true"
						   :items="feedbackTypeOptions"
						   placeholder="Search by Type"
						   v-model.trim="searchByType"/>
				</v-col>

				<!--By Name-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
					   :class="$vuetify.breakpoint.width < 600 ? '' : 'pl-2'">
				<app-input input-type="textInput"
						   append-icon="icons8-search"
						   :clearable="true"
						   placeholder="Search by User Name"
						   v-model.trim="searchByUserName"/>
				</v-col>

			</v-row>

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="rounded-lg mt-4"
					  :headers="computedTableHeaders"
					  :items="computedTableData"
					  item-key="entityId"
					  no-data-text="Nothing to show, does it exist?"
					  style="border: 1px solid var(--v-greyD-base)">

			<!--Title-->
			<template v-slot:item.feedbackTitle="{item}">
				<app-text size="small">{{ item.feedbackTitle }}</app-text>
			</template>

			<!--Type-->
			<template v-slot:item.feedbackType="{item}">
				<app-text size="small">{{ item.feedbackType }}</app-text>
			</template>

			<!--UserName-->
			<template v-slot:item.feedbackUserName="{item}">
				<app-text size="small">{{ item.feedbackUserName }}</app-text>
			</template>

			<!--Date-->
			<template v-slot:item.createdDateTime="{item}">
				<app-text size="small">{{ MIX_formattedDate('short', item.createdDateTime) }}</app-text>
			</template>

			<!--Actions-->
			<template v-slot:item.actions="{item}">
				<app-icon @click.native="openDialog(item)"
						  color="pink" icon="forwardArrow" size="24"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<div v-if="!computedTableData.length"
				 class="d-flex align-center justify-center" style="height: 50vh">
				<app-text>Nothing to show, does it exist?</app-text>
			</div>

			<v-card v-for="item in computedTableData" :key="item.entityId"
					@click="openDialog(item)"
					class="rounded-lg mt-4 pa-4"
					flat
					style="border: 1px solid var(--v-greyD-base)">

				<!--Title-->
				<app-text class="mb-2" size="normal-bold">{{ item.feedbackTitle }}</app-text>

				<!--Date-->
				<div class="d-flex align-center mt-2">
					<app-text class="mr-2" color="orange" size="small">Date</app-text>
					<app-text>{{ MIX_formattedDate('long', item.createdDateTime) }}</app-text>
				</div>

				<!--Type-->
				<div class="d-flex align-center mt-2">
					<app-text class="mr-2" color="orange" size="small">Type</app-text>
					<app-text>{{ item.feedbackType }}</app-text>
				</div>

				<!--UserName-->
				<div class="d-flex align-center mt-2">
					<app-text class="mr-2" color="orange" size="small">User Name</app-text>
					<app-text>{{ item.feedbackUserName }}</app-text>
				</div>

			</v-card>

		</div>

		<!--Form Dialog-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isDialogVisible"
				 class="white shadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 75%'"
				 v-model="isDialogVisible">

				<v-toolbar class="appGradient">
					<app-text color="white" size="medium">Feedback</app-text>
					<v-spacer/>
					<!--<edit-icon @click.native="editKey" class="pa-2" :isActive="!isReadOnly"/>-->
					<!--<delete-icon @emitDeleteItem="deleteKey(selectedItem.entityId)"-->
					<!--			 class="pa-2"-->
					<!--			 itemName="Feedback Title"-->
					<!--			 :selectedItem="selectedItem"/>-->
					<close-icon @click.native="closeDialog" class="pa-2"/>
				</v-toolbar>

				<feedback-form class="appBackground pb-4 px-4"
							   :formData="selectedItem"
							   :isReadOnly="isReadOnly"
							   v-on:refreshPage="refreshPage"/>

			</div>
		</transition>

	</div>
</template>

<script>
import FeedbackForm from "@/views/adminConsole/feedbackConsole/FeedbackForm";

export default {

	name: "FeedbackConsole",

	components: {FeedbackForm},

	data: () => ({
		entity: 'feedback',
		isDialogVisible: false,
		isReadOnly: false,
		searchByTitle: '',
		searchByType: '',
		searchByUserName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hiddenByDefault: true},
			{text: 'Title', value: 'feedbackTitle', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Type', value: 'feedbackType', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'UserName', value: 'feedbackUserName', align: 'start', sortable: false, hiddenByDefault: false},
			{text: 'Date', value: 'createdDateTime', align: 'start', sortable: false, hiddenByDefault: false},
			{text: '', value: 'actions', align: 'center', sortable: false, hiddenByDefault: false, width: '24px'},
		],

		// Data
		feedbackData: [],
		isLoading: true,
	}),

	computed: {

		computedTableData() {
			const t = this
			let tableData = t.feedbackData

			// Filter out deleted data
			tableData = tableData.filter(data => !data.isDeleted)

			// Search by Title
			if (t.searchByTitle) {
				tableData = tableData.filter(data =>
					data.feedbackTitle.toUpperCase().match(t.searchByTitle.toUpperCase()))
			}

			// Search by Type
			if (t.searchByType) {
				tableData = tableData.filter(data =>
					data.feedbackType.toUpperCase().match(t.searchByType.toUpperCase()))
			}

			// Search by UserName
			if (t.searchByUserName) {
				tableData = tableData.filter(data =>
					data.feedbackUserName.toUpperCase().match(t.searchByUserName.toUpperCase()))
			}

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.createdDateTime > b.createdDateTime ? 1 : -1
			})

			return tableData
		},

		computedTableHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hiddenByDefault)

			return headers
		}

	},

	methods: {

		/**
		 * Get Entity Data
		 *
		 * Get all the keys for the given entity.
		 *
		 * @returns {Promise<void>}
		 */
		async getEntityData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll(t.entity)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Feedback data: ', RESPONSE.error)
				return
			}

			t.feedbackData = RESPONSE.data

			t.isLoading = false
		},

		/**
		 * Delete Key
		 *
		 * Delete the key by its ID, and refresh the page.
		 *
		 * @param id ID of key
		 * @returns {Promise<void>}
		 */
		async deleteKey(id) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete(t.entity, id, t.selectedItem)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Feedback: ', RESPONSE.error)
				return
			}

			await t.refreshPage()
		},

		editKey() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Close Dialog
		 *
		 * Close the form dialog.
		 */
		closeDialog() {
			const t = this

			t.selectedItem = {}
			t.isDialogVisible = false
		},

		/**
		 * Open Dialog
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openDialog(item) {
			const t = this

			t.isReadOnly = !!item.entityId

			t.selectedItem = item

			t.isDialogVisible = true
		},

		/**
		 * Refresh Page
		 *
		 * Close the open form dialog, and call for the new entity data.
		 *
		 * @returns {Promise<void>}
		 */
		async refreshPage() {
			const t = this

			t.closeDialog()

			await t.getEntityData()
		},

	},

	async created() {
		const t = this
		t.isLoading = true

		await t.getEntityData()
	}

}
</script>

<style scoped>

</style>
