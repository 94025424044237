<template>
	<div style="display: grid; grid-template-rows: 1fr auto; height: calc(100% - 64px)">
		<v-form class="appBackground">

			{{ computedInit }}

			<!--Instructional Text-->
			<app-text class="mt-8">
				Info Points are any pieces of information that are used often as helpful tips for
				Activities, Disabilities, or Organisations
			</app-text>

			<v-divider class="mt-8"/>

			<!--Name-->
			<app-input input-type="textInput"
					   class="pt-8"
					   :disabled="isReadOnly"
					   :error="errors.infoPointName"
					   :error-message="errors.infoPointNameErrorMessage"
					   label="Name"
					   v-model.trim="form.infoPointName"/>

			<!--Type-->
			<app-input input-type="select"
					   class="mt-8"
					   :disabled="isReadOnly"
					   :error="errors.infoPointType"
					   :error-message="errors.infoPointTypeErrorMessage"
					   :items="infoPointTypes"
					   label="Type"
					   v-model.trim="form.infoPointType"/>

		</v-form>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block="true"
				 class="mt-8"
				 color="green"
				 :disabled="isReadOnly"
				 label="Save"/>

	</div>
</template>

<script>
export default {

	name: "InfoPointsForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		entity: 'infoPoint',
		errors: {
			infoPointName: false,
			infoPointNameErrorMessage: '',
			infoPointType: false,
			infoPointTypeErrorMessage: '',
		},
		form: {
			entityId: '',
			infoPointName: '',
			infoPointType: '',

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		},
		infoPointTypes: ['Activity', 'Disability', 'Organisation'],
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async save() {
			const t = this
			let response

			// Only save if form has passed validation
			if (!t.validateForm()) return

			// Create
			if (!t.form?.entityId) {
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			if (!response.hasErrors) t.$emit('refreshPage')

		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Name
			if (!t.form.infoPointName.trim()) {
				t.errors.infoPointName = true
				t.errors.infoPointNameErrorMessage = 'Name is required'
			}

			// Type
			if (!t.form.infoPointType) {
				t.errors.infoPointType = true
				t.errors.infoPointTypeErrorMessage = 'Type is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
