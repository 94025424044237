<template>
	<v-form class="appBackground">

		{{ computedInit }}

		<!--Image-->
		<div :class="$vuetify.breakpoint.width < 800 ? 'challForm-header-mobile' : 'challForm-header-desktop'">

			<!--Image-->
			<div :class="$vuetify.breakpoint.width < 800 && 'd-flex justify-center'">

				<!--Image-->
				<v-img v-if="form.challengeCoverImageSmall"
					   class="rounded-lg"
					   contain
					   :src="form.challengeCoverImageSmall"
					   height="100%"
					   max-height="464"
					   width="100%"
					   max-width="464"/>

				<!--Icon-->
				<app-icon v-if="!form.challengeCoverImageSmall" icon="image" size="256"/>

			</div>

			<!--Search | Upload Button-->
			<div class="mt-4">

				<google-autocomplete @placechanged="getAddressData"
									 placeholder="Business search..."
									 types="establishment"/>

				<image-upload @emitImageUrl="handleImageUpload"
							  :block="true"
							  class="mt-4"
							  color="appGradient"
							  :disabled="isReadOnly"
							  folder="challenges"
							  icon="upload"
							  imageFor="Challenge"
							  label="Upload Image"/>

				<!--Image Errors-->
				<app-text v-if="errors.challengeImage" class="ml-4 mt-2" color="red" size="small">
					Image Required
				</app-text>

				<app-text class="d-flex justify-center text-center mt-2" size="small">
					For best results, the image should have a 2:1 aspect ratio
					<br>
					E.g. (w)1000px (h)500px
				</app-text>

			</div>

		</div>

		<!--Name-->
		<app-input input-type="textInput"
				   :disabled="isReadOnly"
				   :error="errors.challengeName"
				   :error-message="errors.challengeNameErrorMessage"
				   label="Name"
				   v-model.trim="form.challengeName"/>

		<!--Telephone | Email | Website-->
		<v-row no-gutters>

			<!--Telephone-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.challengeTelephone"
						   :error-message="errors.challengeTelephoneErrorMessage"
						   label="Telephone"
						   type="number"
						   v-model.trim="form.challengeTelephone"/>
			</v-col>

			<!--Email-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 px-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.challengeEmail"
						   :error-message="errors.challengeEmailErrorMessage"
						   label="Email"
						   type="email"
						   v-model.trim="form.challengeEmail"/>
			</v-col>

			<!--Website-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.challengeWebsite"
						   :error-message="errors.challengeWebsiteErrorMessage"
						   label="Website"
						   v-model.trim="form.challengeWebsite"/>
			</v-col>

		</v-row>

		<!--Date | Time-->
		<v-row no-gutters>

			<!--Date Picker-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<date-picker @emitDate="handleDateSelection"
							 :date="form.challengeDate"
							 :disabled="isReadOnly"
							 :error="errors.challengeDate"
							 :error-message="errors.challengeDateErrorMessage"
							 :max-date="[10, 'years', 'future']"/>
			</v-col>

			<!--Time Picker-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<time-picker @emitTime="handleTimeSelection"
							 :disabled="isReadOnly"
							 :error="errors.challengeTime"
							 :error-message="errors.challengeTimeErrorMessage"
							 label="Time"
							 :time="form.challengeTime"/>
			</v-col>

		</v-row>

		<!--Cost | Sex | Group/Individual-->
		<v-row no-gutters>

			<!--Cost-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.challengeCost"
						   :error-message="errors.challengeCostErrorMessage"
						   label="Cost"
						   v-model.trim="form.challengeCost"/>
			</v-col>

			<!--Sex-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 px-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :error="errors.challengeSex"
						   :error-message="errors.challengeSexErrorMessage"
						   :items="activitySexOptions"
						   label="Sex"
						   v-model="form.challengeSex"/>
			</v-col>

			<!--Group or Individual-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :disabled="isReadOnly"
						   :error="errors.challengeGroupIndividual"
						   :error-message="errors.challengeGroupIndividualErrorMessage"
						   :items="groupIndividualOptions"
						   label="Group / Individual"
						   v-model="form.challengeGroupIndividual"/>
			</v-col>

		</v-row>

		<!--Address-->
		<v-row no-gutters>

			<!--Address Line 1-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Line 1"
						   v-model.trim="form.challengeAddressLine1"/>
			</v-col>

			<!--Address Line 2-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Line 2"
						   v-model.trim="form.challengeAddressLine2"/>
			</v-col>

			<!--Address Town-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Town"
						   v-model.trim="form.challengeAddressTown"/>
			</v-col>

			<!--Address City-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   :error="errors.challengeAddressCity"
						   :error-message="errors.challengeAddressCityErrorMessage"
						   label="Address City"
						   v-model.trim="form.challengeAddressCity"/>
			</v-col>

			<!--Address County-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address County"
						   v-model.trim="form.challengeAddressCounty"/>
			</v-col>

			<!--Address Postcode-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :disabled="isReadOnly"
						   label="Address Postcode"
						   v-model.trim="form.challengeAddressPostcode"/>
			</v-col>

			<!--Latitude-->
			<!--<app-input input-type="textInput"-->
			<!--		   class="mt-8"-->
			<!--		   label="Address Coords - Latitude"-->
			<!--		   v-model.trim="form.challengeAddressCoords.latitude"/>-->

			<!--Longitude-->
			<!--<app-input input-type="textInput"-->
			<!--		   class="mt-8"-->
			<!--		   label="Address Coords - Longitude"-->
			<!--		   v-model.trim="form.challengeAddressCoords.longitude"/>-->

		</v-row>

		<!--Info Points-->
		<app-input input-type="autoComplete"
				   class="mt-4"
				   :disabled="isReadOnly"
				   label="Info Points"
				   :items="infoPointsOptions"
				   v-model="form.challengeInfoPoints"/>

		<!--Description-->
		<app-text class="mt-4" color="grey" size="normal-bold">Description</app-text>
		<vue-editor class="white mt-2"
					:class="computedTextEditorClass"
					:disabled="isReadOnly"
					:editor-toolbar="richTextEditorOptions"
					v-model="form.challengeDescription"/>
		<app-text v-if="errors.challengeDescription" class="ml-4 pa-1" color="red" size="small">
			Description is required
		</app-text>

		<!--Save Button-->
		<app-btn @click.native="save"
				 :block="true"
				 class="mt-4"
				 color="green"
				 :disabled="isReadOnly"
				 label="Save"/>

	</v-form>
</template>

<script>
import GoogleAutocomplete from "@/components/GoogleAutocomplete";
import {VueEditor} from "vue2-editor";

export default {

	name: "ChallengesForm",

	components: {GoogleAutocomplete, VueEditor},

	props: ['formData', 'isReadOnly'],

	data: () => ({
		entity: 'challenge',
		errors: {
			challengeAddressCity: false,
			challengeAddressCityErrorMessage: '',
			challengeCost: false,
			challengeCostErrorMessage: '',
			challengeDate: false,
			challengeDateErrorMessage: '',
			challengeDescription: false,
			challengeDescriptionErrorMessage: '',
			challengeEmail: false,
			challengeEmailErrorMessage: '',
			challengeGroupIndividual: false,
			challengeGroupIndividualErrorMessage: '',
			challengeImage: false,
			challengeImageErrorMessage: '',
			challengeName: false,
			challengeNameErrorMessage: '',
			challengeSex: false,
			challengeSexErrorMessage: '',
			challengeTelephone: false,
			challengeTelephoneErrorMessage: '',
			challengeTime: false,
			challengeTimeErrorMessage: '',
			challengeWebsite: false,
			challengeWebsiteErrorMessage: '',
		},
		form: {
			entityId: '',
			challengeName: '',
			challengeAddressLine1: '',
			challengeAddressLine2: '',
			challengeAddressTown: '',
			challengeAddressCity: '',
			challengeAddressCounty: '',
			challengeAddressPostcode: '',
			challengeAddressCoords: {longitude: 0.0, latitude: 0.0},
			challengeTelephone: '',
			challengeEmail: '',
			challengeWebsite: '',
			challengeDescription: '',
			challengeInfoPoints: [],
			challengeLogo: '',
			challengeImages: [],
			challengeCoverImage: '',
			challengeCoverImageSmall: '',
			challengeCoverImageMedium: '',
			challengeCoverImageLarge: '',

			challengeDate: '',
			challengeTime: '',
			challengeCost: '',
			challengeSex: '',
			challengeGroupIndividual: '',

			createdDateTime: '',
			createdUserId: '',
			createdUserEmail: '',
			createdUserName: '',

			modifiedDateTime: '',
			modifiedUserId: '',
			modifiedUserEmail: '',
			modifiedUserName: '',

			isDeleted: false,
			deletedDateTime: '',
			deletedUserId: '',
			deletedUserEmail: '',
			deletedUserName: '',
		}
	}),

	computed: {

		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

		/**
		 * Computed Text Editor Class
		 *
		 * Return a class name for the Text Editor UI, based on error and readOnly states.
		 *
		 * @returns {string} the class name to render
		 */
		computedTextEditorClass() {
			const t = this
			let computedClass

			if (t.errors.challengeDescription) computedClass = 'richText-error'

			if (!t.errors.challengeDescription && t.$props.isReadOnly) computedClass = 'richText-disabled'

			if (!t.errors.challengeDescription && !t.$props.isReadOnly) computedClass = 'richText-enabled'

			return computedClass
		},

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		getAddressData(data) {
			const t = this

			t.form.challengeAddressLine1 = data.addressData?.route
			t.form.challengeAddressTown = data.addressData?.administrative_area_level_2
			t.form.challengeAddressCity = data.addressData?.administrative_area_level_2
			t.form.challengeAddressPostcode = data.addressData?.postal_code
			t.form.challengeAddressCoords.longitude = data.addressData?.longitude
			t.form.challengeAddressCoords.latitude = data.addressData?.latitude
			t.form.challengeTelephone = data.places?.formatted_phone_number
			t.form.challengeWebsite = data.places?.website
		},

		handleDateSelection(date) {
			const t = this

			t.form.challengeDate = date
		},

		handleTimeSelection(time) {
			const t = this

			t.form.challengeTime = time
		},

		handleImageUpload(payload) {
			const t = this

			if (payload.sizeLabel === 'small') t.form.challengeCoverImageSmall = payload.url
			if (payload.sizeLabel === 'medium') t.form.challengeCoverImageMedium = payload.url
			if (payload.sizeLabel === 'large') t.form.challengeCoverImageLarge = payload.url
		},

		async save() {
			const t = this
			let response

			// Only save if the form has passed validation
			if (!t.validateForm()) return

			// Create
			if (!t.form?.entityId) {
				response = await t.MIX_redis_create(t.entity, t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update(t.entity, t.form.entityId, t.form)
			}

			if (!response.hasErrors) t.$emit('refreshPage')

		},

		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Challenge City
			if (!t.form.challengeAddressCity.trim()) {
				t.errors.challengeAddressCity = true
				t.errors.challengeAddressCityErrorMessage = 'City is required'
			}

			// Challenge Cost
			if (!t.form.challengeCost.trim()) {
				t.errors.challengeCost = true
				t.errors.challengeCostErrorMessage = 'Cost is required'
			}

			// Challenge Date
			if (!t.form.challengeDate) {
				t.errors.challengeDate = true
				t.errors.challengeDateErrorMessage = 'Date is required'
			}

			// Challenge Description
			if (!t.form.challengeDescription || t.form.challengeDescription.replace(/\s/g, '') === '<p></p>') {
				t.errors.challengeDescription = true
				t.errors.challengeDescriptionErrorMessage = 'Description is required'
			}

			// Challenge Email
			if (!t.form.challengeEmail.trim()) {
				t.errors.challengeEmail = true
				t.errors.challengeEmailErrorMessage = 'Email is required'
			}

			// Challenge Group/Individual
			if (!t.form.challengeGroupIndividual) {
				t.errors.challengeGroupIndividual = true
				t.errors.challengeGroupIndividualErrorMessage = 'Group/Individual is required'
			}

			// Cover Image
			if (!(t.form.challengeCoverImageSmall || t.form.challengeCoverImageMedium || t.form.challengeCoverImageLarge)) {
				t.errors.challengeImage = true
				t.errors.challengeImageErrorMessage = 'Cover Image is required'
			}

			// Challenge Name
			if (!t.form.challengeName.trim()) {
				t.errors.challengeName = true
				t.errors.challengeNameErrorMessage = 'Name is required'
			}

			// Challenge Sex
			if (!t.form.challengeSex) {
				t.errors.challengeSex = true
				t.errors.challengeSexErrorMessage = 'Sex is required'
			}

			// Challenge Telephone
			if (!t.form.challengeTelephone.trim()) {
				t.errors.challengeTelephone = true
				t.errors.challengeTelephoneErrorMessage = 'Telephone is required'
			}

			// Challenge Time
			if (!t.form.challengeTime) {
				t.errors.challengeTime = true
				t.errors.challengeTimeErrorMessage = 'Time is required'
			}

			// Challenge Website
			if (!t.form.challengeWebsite.trim()) {
				t.errors.challengeWebsite = true
				t.errors.challengeWebsiteErrorMessage = 'Website is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
.challForm-header-mobile {
	display: grid;
	grid-template-rows: auto auto;
}

.challForm-header-desktop {
	display: grid;
	grid-template-columns: auto 1fr;
}
</style>
