<template>
	<div class="pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon color="pink" icon="info" size="48"/>

			<!--Title-->
			<app-text class="ml-4" color="orange" size="large-bold">About Us</app-text>

		</div>

		<!--About Text-->
		<!--<app-text class="mt-8 mb-2 text-center" color="pink" size="large-bold">About Us</app-text>-->
		<app-text class="mt-8">
			Welcome to Fitap. A first-of-its-kind app that allows you to access exercise, leisure, health, and
			wellbeing services across Wales.
			<br>
			<br>
			Our app is unique and enables users to search for services in your area, saving time and money and
			delivering options you may never have come across before.
			<br>
			<br>
			We connect gym owners, personal trainers, sports clubs and health and fitness providers with consumers
			at the touch of a button.
			<br>
			<br>
			The app is co-founded by Wales rugby union internationals Alex Cuthbert and Gareth Anscombe, and we’ve
			recently proudly signed Jade Jones OBE a double Olympic Taekwondo and World Champion as one of our
			brand ambassadors.
		</app-text>

		<app-text class="mt-8 mb-2 text-center" color="pink" size="large-bold">History of Fitap</app-text>
		<app-text class="">
			Fitap was initially launched in 2019.
			Like many other businesses, Fitap was affected directly from COVID 19 and the closure of all of its
			operating facilities due to the nature of the industry we work in.
			<br>
			<br>
			We took this time to diversify into online classes and spread news about the app. We have since taken
			into consideration feedback from our soft launch, and developed a brand-new app, and increased our
			online and in-person facilities and team to enable a better user experience.
		</app-text>

		<app-text class="mt-8 mb-2 text-center" color="orange" size="large-bold">Contact Us</app-text>
		<app-text class="text-center">
			For advertising enquiries: <a href="mailto:enquiries@fitap.co.uk">enquiries@fitap.co.uk</a>
			<br>
			<br>
			For all other enquiries: <a href="mailto:Hello@fitap.co.uk">Hello@fitap.co.uk</a>
		</app-text>

	</div>
</template>

<script>
export default {
	name: "AboutUs"
}
</script>

<style scoped>

</style>
