import {collection, doc, getDocs, query, setDoc, updateDoc, where} from 'firebase/firestore'

const mixin = {

	methods: {

		/**
		 * Create User Document
		 *
		 * Create a new Firestore User document.
		 * This will also trigger a FB Function and create the required Claims.
		 *
		 * @param collectionName the name of the FB collection
		 * @param document the document to create
		 * @returns {Promise<{}>} result object
		 */
		async MIX_firestore_createUserDocument(collectionName, document) {
			const t = this
			let result = {}

			// Create a Redis Key and add the ID to the Firebase document
			const redisResponse = await t.MIX_redis_create('user', document)

			const fbDocument = {
				redisId: redisResponse.data.entityId,
				userFbId: document.userFbId,
				userEmail: document.userEmail,
				userLevel: document.userLevel,
				userName: document.userName,
				userStatus: document.userStatus,

				createdDateTime: document.createdDateTime,
				createdUserId: redisResponse.data.entityId,
				createdUserEmail: document.createdUserEmail,
				createdUserName: document.createdUserName,

				modifiedDateTime: '',
				modifiedUserEmail: '',
				modifiedUserId: '',
				modifiedUserName: '',

				isDeleted: false,
				deletedDateTime: '',
				deletedUserEmail: '',
				deletedUserId: '',
				deletedUserName: '',
			}

			await setDoc(doc(t.$firebase.db, collectionName, fbDocument.userFbId), fbDocument)
				.then(() => {
					result = {
						data: 'Successfully created document',
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					result = {
						data: null,
						hasErrors: true,
						error
					}
				})

			return result
		},

		/**
		 * Update User Document
		 *
		 * Update set fields and update the Firestore User's document.
		 * This will also trigger the FB Function and update any Claims if the userLevel or userStatus has changed.
		 *
		 * @param collectionName the name of the FB collection
		 * @param document the document to update
		 * @returns {Promise<*>} result object
		 */
		async MIX_firestore_updateUserDocument(collectionName, document) {
			const t = this
			let result

			const DOC_REF = doc(t.$firebase.db, collectionName, document.userFbId)

			await updateDoc(DOC_REF, {
				userEmail: document.userEmail,
				userLevel: document.userLevel,
				userName: document.userName,
				userStatus: document.userStatus,
				modifiedDateTime: document.modifiedDateTime,
				modifiedUserId: document.modifiedUserId,
				modifiedUserEmail: document.modifiedUserEmail,
				modifiedUserName: document.modifiedUserName,
			})
				.then(response => {
					result = {
						data: response,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					result = {
						data: {},
						hasErrors: true,
						error: error
					}
				})

			return result
		},

		async MIX_firestore_checkIfEmailIsInUse(collectionName, whereClause) {
			const t = this

			// Get a reference to the collection
			const DOC_REF = await collection(t.$firebase.db, collectionName)

			// Construct the query
			const QUERY = query(DOC_REF, where(whereClause.key, whereClause.operator, whereClause.value))

			// Get the documents
			const QUERY_SNAPSHOT = await getDocs(QUERY)

			// Return true/false if the email is in use
			return !!QUERY_SNAPSHOT.size
		}

	},

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
