import { render, staticRenderFns } from "./CategoriesConsole.vue?vue&type=template&id=f39bcc42&scoped=true&"
import script from "./CategoriesConsole.vue?vue&type=script&lang=js&"
export * from "./CategoriesConsole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f39bcc42",
  null
  
)

export default component.exports