<template>
	<div>

		{{ computedInit }}

		<!--Location-->
		<div>
			<app-text color="grey9" size="normal-bold">Location of activity?</app-text>
			<app-text class="font-weight-regular mt-2" color="grey9" size="small">
				Where would you like an activity near?
			</app-text>

			<google-autocomplete @placechanged="getAddressData" class="mt-2"
								 :formattedAddress="form.searchPreferenceFormattedAddress"
								 placeholder="E.g. Street, District, Town, City, or Postcode"
								 types="geocode"/>

			<!--Error Message-->
			<app-text v-if="errors.location" class="text-center mt-1" color="red" size="small-bold">
				{{ errors.locationErrorMessage }}
			</app-text>
		</div>

		<!--Distance-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">How far are you willing to travel (miles)?</app-text>

			<app-input input-type="select"
					   :items="computedDistanceOptions"
					   v-model="form.searchPreferenceDistance"/>

			<!--Error Message-->
			<app-text v-if="errors.distance" class="text-center mt-1" color="red" size="small-bold">
				{{ errors.distanceErrorMessage }}
			</app-text>
		</div>

		<!--Interested Activities-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">What are you interested in?</app-text>
			<app-input input-type="autoComplete"
					   :chips="true"
					   :disable-lookup="true"
					   :items="categoriesData"
					   item-text="categoryName"
					   :multiple="true"
					   v-model="form.searchPreferenceCategories"/>
		</div>

		<!--Preferred Sex-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">What sex would you prefer to train with?</app-text>

			<v-btn-toggle background-color="transparent"
						  class="d-flex justify-center mt-4"
						  multiple
						  v-model="form.searchPreferenceSex">

				<!--Male-->
				<form-btn :active="form.searchPreferenceSex.includes('Male Only')"
						  label="Male Only" value="Male Only"/>

				<!--Female-->
				<form-btn :active="form.searchPreferenceSex.includes('Female Only')"
						  class="mx-4" label="Female Only" value="Female Only"/>

				<!--Mixed-->
				<form-btn :active="form.searchPreferenceSex.includes('Mixed')"
						  label="Mixed" value="Mixed"/>

			</v-btn-toggle>

			<!--Error Message-->
			<app-text v-if="errors.sex" class="text-center mt-1" color="red" size="small-bold">
				{{ errors.sexErrorMessage }}
			</app-text>
		</div>

		<!--Preferred Way-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">What is your preferred way to train?</app-text>

			<v-btn-toggle background-color="transparent"
						  class="d-flex justify-center mt-4"
						  v-model="form.searchPreferenceGroupIndividual">

				<!--Male-->
				<form-btn :active="form.searchPreferenceGroupIndividual === 'Group'"
						  label="Group" value="Group"/>

				<!--Female-->
				<form-btn :active="form.searchPreferenceGroupIndividual === 'Individual'"
						  class="mx-4" label="Individual" value="Individual"/>

				<!--Mixed-->
				<form-btn :active="form.searchPreferenceGroupIndividual === 'Either'"
						  label="Either" value="Either"/>

			</v-btn-toggle>

			<!--Error Message-->
			<app-text v-if="errors.groupIndividual" class="text-center mt-1" color="red" size="small-bold">
				{{ errors.groupIndividualErrorMessage }}
			</app-text>
		</div>

		<!--Days-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">What days would you prefer to train?</app-text>

			<v-btn-toggle background-color="transparent"
						  class="d-flex flex-column mt-4"
						  multiple
						  v-model="form.searchPreferenceDays">

				<!--Weekdays-->
				<div style="display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 16px">

					<form-btn :active="form.searchPreferenceDays.includes('Mo')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'M' : $vuetify.breakpoint.width < 720 ? 'Mon' : 'Monday'"
							  style="width: 100%"
							  value="Mo"/>

					<form-btn :active="form.searchPreferenceDays.includes('Tu')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'T' : $vuetify.breakpoint.width < 720 ? 'Tue' : 'Tuesday'"
							  style="width: 100%"
							  value="Tu"/>

					<form-btn :active="form.searchPreferenceDays.includes('We')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'W' : $vuetify.breakpoint.width < 720 ? 'Wed' : 'Wednesday'"
							  style="width: 100%"
							  value="We"/>

					<form-btn :active="form.searchPreferenceDays.includes('Th')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'T' : $vuetify.breakpoint.width < 720 ? 'Thu' : 'Thursday'"
							  style="width: 100%"
							  value="Th"/>

					<form-btn :active="form.searchPreferenceDays.includes('Fr')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'F' : $vuetify.breakpoint.width < 720 ? 'Fri' : 'Friday'"
							  style="width: 100%"
							  value="Fr"/>

				</div>

				<!--Weekend-->
				<div class="mt-4" style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px">

					<form-btn :active="form.searchPreferenceDays.includes('Sa')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'S' : $vuetify.breakpoint.width < 720 ? 'Sat' : 'Saturday'"
							  style="width: 100%"
							  value="Sa"/>

					<form-btn :active="form.searchPreferenceDays.includes('Su')"
							  :block="true"
							  :label="$vuetify.breakpoint.width < 440 ? 'S' : $vuetify.breakpoint.width < 720 ? 'Sun' : 'Sunday'"
							  style="width: 100%"
							  value="Su"/>

				</div>

				<!--Error Message-->
				<app-text v-if="errors.days" class="text-center mt-1" color="red" size="small-bold">
					{{ errors.daysErrorMessage }}
				</app-text>

			</v-btn-toggle>
		</div>

		<!--Times-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">What times would you prefer to train?</app-text>

			<v-btn-toggle background-color="transparent"
						  class="d-flex flex-column mt-4"
						  multiple
						  v-model="form.searchPreferenceTimes">

				<!--Times-->
				<div class="seapre-timesGrid">

					<form-btn :active="form.searchPreferenceTimes.includes('6am - 9am')"
							  :block="true"
							  label="6am - 9am"
							  style="width: 100%"
							  value="6am - 9am"/>

					<form-btn :active="form.searchPreferenceTimes.includes('9am - 12pm')"
							  :block="true"
							  label="9am - 12pm"
							  style="width: 100%"
							  value="9am - 12pm"/>

					<form-btn :active="form.searchPreferenceTimes.includes('12pm - 3pm')"
							  :block="true"
							  label="12pm - 3pm"
							  style="width: 100%"
							  value="12pm - 3pm"/>

					<form-btn :active="form.searchPreferenceTimes.includes('3pm - 6pm')"
							  :block="true"
							  label="3pm - 6pm"
							  style="width: 100%"
							  value="3pm - 6pm"/>

					<form-btn :active="form.searchPreferenceTimes.includes('6pm - 9pm')"
							  :block="true"
							  label="6pm - 9pm"
							  style="width: 100%"
							  value="6pm - 9pm"/>

					<form-btn :active="form.searchPreferenceTimes.includes('9pm - 6am')"
							  :block="true"
							  label="9pm - 6am"
							  style="width: 100%"
							  value="9pm - 6am"/>

				</div>

				<!--<form-btn :active="form.searchPreferenceTimes.includes('Anytime')"-->
				<!--		  class="mt-4"-->
				<!--		  :block="true"-->
				<!--		  label="Anytime"-->
				<!--		  style="width: 100%"-->
				<!--		  value="Anytime"/>-->

				<!--Error Message-->
				<app-text v-if="errors.times" class="text-center mt-1" color="red" size="small-bold">
					{{ errors.timesErrorMessage }}
				</app-text>

			</v-btn-toggle>
		</div>

		<!--Monthly Budget-->
		<!--<expansion-panel class="mt-8">-->
		<!--	<template #header>What is your monthly budget?</template>-->
		<!--	<template #content>-->
		<!--		<app-text color="grey9" size="small">How much would you be willing to pay each month?</app-text>-->

		<!--		<div class="d-flex mt-12">-->

		<!--			<app-text>£0</app-text>-->

		<!--			<v-slider color="pink"-->
		<!--					  max="150"-->
		<!--					  min="0"-->
		<!--					  step="5"-->
		<!--					  thumb-color="pink"-->
		<!--					  thumb-label="always"-->
		<!--					  track-color="lightgrey"-->
		<!--					  track-fill-color="orange"-->
		<!--					  v-model="form.searchPreferenceMonthlyBudget"/>-->

		<!--			<app-text>£150+</app-text>-->

		<!--		</div>-->
		<!--	</template>-->
		<!--</expansion-panel>-->

		<!--Activity Budget-->
		<div class="mt-8">
			<app-text color="grey9" size="normal-bold">What is your activity budget?</app-text>

			<app-text color="grey9" size="small">
				How much would you be willing to pay per activity if it isn't included in a monthly cost (e.g. a
				membership)?
			</app-text>

			<div class="d-flex mt-12">

				<app-text>£0</app-text>

				<v-slider color="pink"
						  max="150"
						  min="0"
						  step="5"
						  thumb-color="orange"
						  thumb-label="always"
						  track-color="lightgrey"
						  track-fill-color="pink"
						  v-model="form.searchPreferenceActivityBudget"/>

				<app-text>£150+</app-text>

			</div>
		</div>

		<!--Save Button-->
		<div class="mt-8">

			<!--Save Button-->
			<app-btn v-if="!isSearch"
					 @click.native="save"
					 :block="true"
					 color="green"
					 label="Save"/>

			<!--Search Button-->
			<app-btn v-if="isSearch"
					 @click.native="search"
					 :block="true"
					 color="green"
					 label="Search"/>

		</div>

		<confirmation-animation v-if="isConfirmationVisible"/>

	</div>
</template>

<script>
import GoogleAutocomplete from "@/components/GoogleAutocomplete";

export default {

	name: "SearchPreferencesForm",

	components: {GoogleAutocomplete},

	props: ['isSearch', 'manualSearchCriteria', 'userSearchPreferenceData'],

	data: () => ({
		categoriesData: [],
		errors: {
			days: false,
			daysErrorMessage: '',
			distance: false,
			distanceErrorMessage: '',
			groupIndividual: false,
			groupIndividualErrorMessage: '',
			location: false,
			locationErrorMessage: '',
			sex: false,
			sexErrorMessage: '',
			times: false,
			timesErrorMessage: '',
		},
		form: {
			searchPreferenceActivityBudget: 150,
			searchPreferenceCategories: [],
			searchPreferenceDays: [],
			searchPreferenceDistance: '',
			searchPreferenceFormattedAddress: '',
			searchPreferenceGroupIndividual: '',
			searchPreferenceLocation: {longitude: 0.0, latitude: 0.0},
			searchPreferenceMonthlyBudget: 150,
			searchPreferenceSex: [],
			searchPreferenceTimes: [],
			userId: '',
		},
		isConfirmationVisible: false,
	}),

	computed: {

		/**
		 * Computed Distance Options
		 *
		 * Return an array of distance dropdown options, from 5 to 50 miles.
		 *
		 * @returns {array} the array of options
		 */
		computedDistanceOptions() {
			let distanceOptions = [1, 2, 3, 4]

			for (let i = 5; i < 51; i += 5) distanceOptions.push(i)

			return distanceOptions
		},

		/**
		 * Computed Init
		 *
		 * Listen out for manualSearchCriteria props, and populate the form if they are present.
		 * This is used to keep the form field populated when opening an active search form.
		 * If there are no props (new form), user this component's default form data.
		 */
		computedInit() {
			const t = this

			if (t.$props.manualSearchCriteria?.searchPreferenceDistance) t.form = t.$props.manualSearchCriteria
			if (t.$props.userSearchPreferenceData?.entityId) t.form = t.$props.userSearchPreferenceData
		}

	},

	methods: {

		/**
		 * Clear Errors and Error Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndErrorMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async getCategoriesData() {
			const t = this

			const response = await t.MIX_redis_getAll('category')

			if (!response.hasErrors) {
				response.data = response.data.filter(data => !data.isDeleted)
				t.categoriesData = response.data.sort((a, b) => {
					return a.categoryName > b.categoryName ? 1 : -1
				})
			}

		},

		getAddressData(data) {
			const t = this

			t.form.searchPreferenceFormattedAddress = data.places.formatted_address
			t.form.searchPreferenceLocation.longitude = data.addressData?.longitude
			t.form.searchPreferenceLocation.latitude = data.addressData?.latitude
		},

		async loadData() {
			const t = this

			await t.getCategoriesData()

			t.isLoading = false
		},

		async save() {
			const t = this
			let response

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			const USER_DATA = await t.MIX_redis_getCurrentUserData()

			// Create
			if (!t.form.entityId) {
				t.form.userId = USER_DATA.data.entityId
				response = await t.MIX_redis_create('searchPreference', t.form)
			}

			// Update
			else {
				response = await t.MIX_redis_update('searchPreference', t.form.entityId, t.form)
			}

			if (response.hasErrors) console.error('Error saving Search Preferences: ', response.error)

			t.showConfirmationAnimation()
			t.$emit('refreshPage')
		},

		async search() {
			const t = this

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			t.$emit('emitSearchCriteria', {searchCriteria: t.form})
		},

		/**
		 * Show Confirmation Animation
		 *
		 * Toggle the ConfirmationAnimation component on for 2 seconds, and off.
		 */
		showConfirmationAnimation() {
			const t = this

			t.isConfirmationVisible = true

			setTimeout(() => {
				t.isConfirmationVisible = false
			}, 2000)
		},

		/**
		 * Validate Form
		 *
		 * Validate required form fields to ensure the form sends correctly.
		 *
		 * @returns {boolean} whether the validation has passed, or not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndErrorMessages()

			// Location
			if (!t.form.searchPreferenceLocation.latitude || !t.form.searchPreferenceLocation.longitude) {
				t.errors.location = true
				t.errors.locationErrorMessage = 'You must enter a location to search from'
			}

			// Distance
			if (!t.form.searchPreferenceDistance) {
				t.errors.distance = true
				t.errors.distanceErrorMessage = 'You must enter a distance to search'
			}

			// Days
			if (!t.form.searchPreferenceDays.length) {
				t.errors.days = true
				t.errors.daysErrorMessage = 'You must select at least one day'
			}

			// Group Individual
			if (!t.form.searchPreferenceGroupIndividual) {
				t.errors.groupIndividual = true
				t.errors.groupIndividualErrorMessage = 'You must select a preferred way to train'
			}

			// Sex
			if (!t.form.searchPreferenceSex.length) {
				t.errors.sex = true
				t.errors.sexErrorMessage = 'You must select at least one option'
			}

			// Times
			if (!t.form.searchPreferenceTimes.length) {
				t.errors.times = true
				t.errors.timesErrorMessage = 'You must select at least one time period'
			}

			// Return a boolean for whether the validation has passed, or not
			return !Object.values(t.errors).includes(true)
		},

	},

	async created() {
		const t = this

		await t.loadData()
	}

}
</script>

<style scoped>
.seapre-timesGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
	grid-gap: 16px;
}
</style>
