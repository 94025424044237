<template>
	<div class="appGradientOutline rounded-lg pa-4">

		<!--Name-->
		<app-text color="orange" size="normal-bold">{{ cardData.organisationMembershipName }}</app-text>

		<!--Cost | Duration-->
		<div class="d-flex my-2">

			<!--Cost-->
			<app-text color="pink" size="normal-bold">£{{ (cardData.organisationMembershipCost).toFixed(2) }}</app-text>

			<!--Duration-->
			<app-text class="ml-2" color="grey9">per {{ cardData.organisationMembershipDuration }}</app-text>

		</div>

		<!--Main Points-->
		<ul>

			<!--Point 1-->
			<li v-if="cardData.organisationMembershipPoint1">
				<app-text>
					{{ cardData.organisationMembershipPoint1 }}
				</app-text>
			</li>

			<!--Point 2-->
			<li v-if="cardData.organisationMembershipPoint2">
				<app-text>
					{{ cardData.organisationMembershipPoint2 }}
				</app-text>
			</li>

			<!--Point 3-->
			<li v-if="cardData.organisationMembershipPoint3">
				<app-text>
					{{ cardData.organisationMembershipPoint3 }}
				</app-text>
			</li>

		</ul>

	</div>
</template>

<script>
export default {

	name: "OrganisationMembershipCard",

	props: ['cardData'],

}
</script>

<style scoped>

</style>
