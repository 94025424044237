<template>
	<v-dialog max-width="496"
			  v-model="dialog">

		<!--Activator-->
		<template v-slot:activator="{on, attrs}">

			<div v-on="on">

				<v-hover v-slot="{hover}">
					<app-icon class="deleteIcon-container"
							  :color="hover ? 'white' : ''"
							  icon="delete"
							  title="Delete"/>
				</v-hover>

			</div>

		</template>

		<!--Content-->
		<v-card>

			<!--Toolbar-->
			<v-toolbar class="appGradient">
				<app-text color="white" size="medium">Delete</app-text>
				<v-spacer/>
				<close-icon @click.native="dialog = false" class="pa-2"/>
			</v-toolbar>

			<!--Body-->
			<div class="pa-4">

				<!--Information-->
				<app-text>
					You are about to delete <strong>{{ selectedItem[itemName] }}</strong>
					<br>
					<br>
					<strong>Are you sure you want to do this?</strong>
				</app-text>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--No-->
					<app-btn @click.native="dialog = false"
							 color="grey"
							 icon="close"
							 label="No"/>

					<!--Yes-->
					<app-btn @click.native="handleYes"
							 color="green"
							 icon="check"
							 label="Yes"/>

				</div>

			</div>

		</v-card>

	</v-dialog>
</template>

<script>
export default {

	name: "DeleteIcon",

	props: ['itemName', 'selectedItem'],

	data: () => ({
		dialog: false,
	}),

	methods: {

		handleYes() {
			const t = this

			t.$emit('emitDeleteItem', t.$props.selectedItem)
		},

	}

}
</script>

<style scoped>
.deleteIcon-container {
	transition: 0.3s;
	padding: 8px;
}

.deleteIcon-container:hover {
	background: var(--v-red-base);
	border-radius: 50%;
	padding: 8px;
	transition: 0.3s;
}
</style>
