<template>
	<v-carousel class="card-container"
				cycle
				height="auto"
				hide-delimiters
				interval="4000"
				show-arrows>

		<v-carousel-item v-for="item in exclusiveOffersData" :key="item.entityId">

			<v-img :src="MIX_renderImageForScreenWidth(item.exclusiveOfferCoverImageSmall, item.exclusiveOfferCoverImageMedium, item.exclusiveOfferCoverImageLarge)"
				   class="rounded-lg"
				   cover
				   max-width="926"
				   max-height="463"
				   style="aspect-ratio: 2/1"/>

		</v-carousel-item>

		<app-text class="card-text" size="small" style="width: 100%">Exclusive Offers</app-text>

	</v-carousel>
</template>

<script>
export default {

	name: "ExclusiveOffersCarousel",

	props: ['exclusiveOffersData'],

	data: () => ({
		images: [
			'offer-cover-fitbit.svg',
			'offer-cover-cardiffMarathon.svg',
		],
	}),

}
</script>

<style scoped>
.card-container {
	background-color: #FFFFFF88;
	border-radius: 8px;
	border: 1px solid lightgrey;
}

.card-text {
	position: absolute;
	bottom: 0;

	background: #FFFFFFEE;
	padding: 4px;
	text-align: center;
}
</style>
